import React, { useState, useRef, useEffect} from 'react';
import JBrowseService from '../services/jbrowse.service';
import EventBus from "../common/EventBus";
import dayjs from 'dayjs';
import _ from 'underscore';

function BatchNotesModal(props) {
    // React state for modal visibility
    const {
        batchNotesModalIsOpen,
        setBatchNotesModalIsOpen,
        setNotesIsOpen,
        currentUser,
        selectedAssembly,
        setLoading,
        setNotes, 
    } = props;
    const [jsonData, setJsonData] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const fileInputRef = useRef(null);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                setBatchNotesModalIsOpen(false);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleDownloadNotesTemplate = () => {
        // Handle the download template logic here
        var obj = {
            // User: $scope.user.Email,
            // ReferenceGenome: "idReferenceGenome",
            Category: "Gene/Variant/Primer",
            Private: "Private/Public",
            Chromosome: "Chr1",
            Start: "1000",
            End: "5000",
            Note: "This would be your note."
        };
        var array = [obj];
        console.log("array: ", array);
        // Download File
        var csvContent = convertArrayOfObjectsToCSV({ data: array });
        downloadCSV({ filename: "BatchNotes_Template.csv" }, csvContent);
    }

    const parseCSV = (data) => {
        const rows = data.trim().split('\n');
        const keys = rows[0].split(',').map(key => key.trim());
    
        const jsonData = rows.slice(1).map(row => {
            let values = [];
            let currentValue = '';
            let isInsideQuotes = false;
    
            for (let i = 0; i < row.length; i++) {
                const currentChar = row[i];
    
                if (currentChar === '"') {
                    isInsideQuotes = !isInsideQuotes;
                    continue;
                }
    
                if (currentChar === ',' && !isInsideQuotes) {
                    values.push(currentValue.trim());
                    currentValue = '';
                } else {
                    currentValue += currentChar;
                }
            }
            values.push(currentValue.trim());
    
            return keys.reduce((obj, key, index) => {
                obj[key] = values[index];
                return obj;
            }, {});
        });
    
        return jsonData;
    }

    const handleUploadBatchNotes = (event) => {
        setLoading(true);
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const content = e.target.result;
                const parsedData = parseCSV(content);
                setJsonData(parsedData);
                var obj = {
                    data: parsedData,
                    user: currentUser,
                    reference: selectedAssembly,
                };
                console.log("upload: ",obj); // This logs the parsed JSON data to the console.
                // setLoading(false)
                // return;
                // Send Request 
                JBrowseService.createBatchNotes( obj ).then(
                    (response) => {
                        console.log("batch response: ", response );
                        setSuccessMessage('Your notes has been uploaded successfully!');
                        setLoading(false);
                        setTimeout( () => {
                            setSuccessMessage('');
                        },3000);
                        // Get Notes
                        let notes_obj = {
                            idCompany: currentUser.idCompany,
                            username: currentUser.email,
                            assembly: selectedAssembly,
                        };
                        console.log("notes obj: ", notes_obj );

                        // Send Request
                        JBrowseService.getNotes(notes_obj).then(
                            (response) => {
                                console.log("notes response: ", response);
                                let notes = []
                                // Loop through notes results and fix url strings
                                _.each(response.result, function (value) {
                                    var obj = value;
                                    obj.created_at = dayjs(value.created_at).format('MMMM D YYYY, h:mm:ss A');
                                    notes.push(obj);
                                });
                                // console.log("notes: ", notes );
                                setNotes(notes);
                            },
                            (error) => {
                                console.log("error: ", error);
                                if (error.code === "ERR_NETWORK") {
                                    console.warn("logout!!!");
                                    EventBus.dispatch("logout");
                                }
                            }
                        )
                    },
                    (error) => {
                        console.log("error: ", error );
                        setLoading(false);
                    }
                )

            };
            reader.readAsText(file);

        }
    }

    const clearUploadField = () => {
        console.log('clear input..',  fileInputRef );
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
            setJsonData([]); // Optionally clear the parsed data as well
        }
    }

    const goBackToNotes = () => {
        setBatchNotesModalIsOpen( false )
        setTimeout( ()=> { setNotesIsOpen( true ); },200);
    }

    return (
        <div className={`fixed z-10 inset-0 overflow-y-auto flex justify-center`} style={{ zIndex: "1000" }}>
            <div className="modal-dialog max-w-6xl w-full mx-2">
                <div className="modal-content bg-white rounded-lg shadow-lg overflow-hidden">
                    <div className="modal-header p-4 flex justify-between items-center">
                        <h5 className="text-lg font-bold">Batch Notes Upload</h5>
                        <button className="text-gray-400 hover:text-gray-600" onClick={() => { setBatchNotesModalIsOpen(false) }}>&times;</button>
                    </div>
                    <div className="modal-body p-4">
                        <div className="p-6">
                            <div className="flex -mx-2">
                                <div className="w-1/2 px-2">
                                    <div className="text-center">
                                        <p className="text-base mb-4">Download the template, the template is pre-populated with an example row. When you save the file make sure you save the file as a CSV.</p>
                                        <button className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 focus:outline-none" onClick={handleDownloadNotesTemplate}>
                                            <i className="fas fa-file-download mr-2"></i> Download Template
                                        </button>
                                    </div>
                                </div>
                                <div className="w-1/2 px-2">
                                    <div className="text-center">
                                        <label className="block mb-2">Upload Batch Notes File (CSV)</label>
                                        <input
                                            className="border rounded p-2 focus:outline-none"
                                            type="file"
                                            multiple={false}
                                            accept="text/csv"
                                            onChange={handleUploadBatchNotes}
                                            id="uploadBatchNotesFile"
                                            ref={fileInputRef}
                                        />
                                        <button className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-700 focus:outline-none mt-4" onClick={clearUploadField}>
                                            Clear Upload Field
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {successMessage && (
                                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mt-4" role="alert">
                                    <span className="block sm:inline">{successMessage}</span>
                                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={() => setSuccessMessage('')}>
                                        <svg className="fill-current h-6 w-6 text-green-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"></path></svg>
                                    </span>
                                </div>
                            )}
                        </div>

                        <div className="flex justify-between mt-4 pt-4 border-t border-gray-200">
                            <button onClick={goBackToNotes} className="bg-gray-400 hover:bg-gray-500 text-white py-2 px-4 rounded">
                                <i className="fas fa-hand-point-left mr-2"></i> Go Back
                            </button>
                            <button className="bg-gray-400 text-white px-4 py-2 rounded hover:bg-gray-500 focus:outline-none" onClick={() => setBatchNotesModalIsOpen(false)}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BatchNotesModal;

function convertArrayOfObjectsToCSV(args) {
    var result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data == null || !data.length) {
        return null;
    }

    columnDelimiter = args.columnDelimiter || ',';
    lineDelimiter = args.lineDelimiter || '\n';

    keys = Object.keys(data[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function (item) {
        ctr = 0;
        keys.forEach(function (key) {
            if (ctr > 0) result += columnDelimiter;

            result += item[key];
            ctr++;
        });
        result += lineDelimiter;
    });

    return result;
}
function downloadCSV(args, csv) {
    var data, filename, link;
    if (csv == null) return;

    filename = args.filename || 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
        csv = 'data:text/csv;charset=utf-8,' + csv;
    }
    data = encodeURI(csv);

    link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    link.click();
}