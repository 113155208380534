import React, { useState, useEffect } from "react";
import { BarChartSharp } from '@mui/icons-material';
import JBrowseService from "../services/jbrowse.service";
import { toast } from 'react-toastify';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress

const BigWigModal = (props) => {
    const { 
        setBigWigModalIsOpen,
        setLoading,
        currentUser,
        selectedCrop,
        setSelectedTracks,
        selectedTracks,
    } = props;

    const [bigWigTracks, setBigWigTracks] = useState([]);
    const [selectedTrackId, setSelectedTrackId] = useState("");
    const [isLoading, setIsLoading] = useState(true); // Add isLoading state

    const closeModal = () => {
        setBigWigModalIsOpen(false);
    };

    useEffect(() => {
        if (currentUser) {
            const obj = {
                user: currentUser.email,
                crop: selectedCrop
            };
            console.log('Requesting BigWig tracks with: ', obj);
            setIsLoading(true); // Start loading
            JBrowseService.getBigWigTracks(obj).then(
                (response) => {
                    console.log("BigWig tracks response: ", response);
                    setTimeout(() => {
                        if (response.status === 0 && response.data) {
                            setBigWigTracks(response.data);  // Set the tracks data
                        } else {
                            console.log("No BigWig tracks found.");
                        }
                        setIsLoading(false); // Loading complete
                    },1000);
                },
                (error) => {
                    console.log("Error fetching BigWig tracks: ", error);
                    setIsLoading(false); // Loading complete
                }
            );
        }
    }, [currentUser, selectedCrop]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                setBigWigModalIsOpen(false);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleTrackChange = (event) => {
        const selectedId = event.target.value;
        setSelectedTrackId(selectedId); // Update the selected track ID
        const selectedObject = bigWigTracks.find(track => track.BigWig_idBigWig === parseInt(selectedId));
        let track = selectedObject.BigWig_FileName;
        console.log("track: ", track );
        setSelectedTracks(prevTracks => prevTracks.includes(track) ? prevTracks : [...prevTracks, track]);
        console.log("selected tracks: ", selectedTracks );
        toast.success("Added a BigWig Track.");
    };

    return (
        <>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <div className="relative w-11/12 max-w-2xl p-6 bg-white rounded-lg shadow-lg md:w-3/4 lg:w-1/2">
                    <div className="flex items-center justify-between mb-4">
                        <h5 className="text-xl font-semibold flex items-center">
                            <BarChartSharp style={{ marginRight: "5px" }} /> Select BigWig Tracks
                        </h5>
                        <button type="button" className="text-gray-500 hover:text-gray-700" onClick={closeModal}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="overflow-y-auto" style={{ maxHeight: '70vh' }}>
                        {isLoading ? (
                            // Loading spinner
                            <div className="flex justify-center items-center h-full">
                                <CircularProgress />
                            </div>
                        ) : (
                            bigWigTracks.length > 0 ? (
                                <div className="mb-3">
                                    <label htmlFor="bigwig-track-select" className="block text-sm font-medium text-gray-700 mb-2">
                                        BigWig Tracks
                                    </label>
                                    <select
                                        id="bigwig-track-select"
                                        value={selectedTrackId}
                                        onChange={handleTrackChange}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        aria-label="BigWig Tracks"
                                    >
                                        <option value="">Select a track</option>
                                        {bigWigTracks.map((track, key) => (
                                            <option key={key} value={track.BigWig_idBigWig}>
                                                {track.BigWig_FileName} - {track.ExternalID}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            ) : (
                                <Alert icon={<CheckIcon fontSize="inherit" />} severity="warning">
                                    No BigWig tracks available for the selected crop.
                                </Alert>
                            )
                        )}
                    </div>
                    <div className="flex justify-end mt-4">
                        <button
                            className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-700"
                            onClick={closeModal}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BigWigModal;
