import React, { useState, useEffect } from 'react';
import JBrowseService from '../services/jbrowse.service';
import _ from 'underscore';
import dayjs from 'dayjs';

const NotesModal = (props) => {
    // console.log("props: ", props );
    const { notes,
        notesIsOpen,
        setNotesIsOpen,
        navigateToLocation,
        setLoading,
        setBatchNotesModalIsOpen,
        selectedAssembly,
        currentUser,
        setCurrentNote,
        setEditNotesModalIsOpen,
        setNotes,
        } = props;
    const [query, setQuery] = useState('');
    const [notesCount, setNotesCount] = useState(0); // Set initial state
    const [currentNotes, setCurrentNotes] = useState([]); // Example: replace with your notes data
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const filteredNotes = notes.filter(note => {
        return note.note.toLowerCase().includes(query.toLowerCase());
    }).slice().reverse();  // Create a copy and then reverse it
    
    const totalPages = Math.ceil(filteredNotes.length / itemsPerPage);
    const headers = Object.keys(filteredNotes[0]);
    const paginatedData = filteredNotes.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeModal();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
    
    const editNote = ( note) => {
        // Your implementation here
        console.log("note: ", note );
        setCurrentNote( note );
        setNotesIsOpen( false );
        setTimeout( ()=> { setEditNotesModalIsOpen(true) });
    }

    const deleteNote = (note) => {
        // Your implementation here
        console.log("note: ", note);
        const userConfirmation = window.confirm("Are you sure you want to delete this note?");
        if (userConfirmation) {
            // User clicked "OK"
            setLoading(true)
            var obj = {
                note: note,
                user: currentUser,
                reference: selectedAssembly
            };
            console.log("obj: ", obj );
            // Send Request
            JBrowseService.deleteNotes(obj).then(
                (response) => {
                    console.log("delete response: ", response);

                    // Get Notes
                    let notes_obj = {
                        idCompany: currentUser.Company_idCompany,
                        username: currentUser.email,
                        assembly: selectedAssembly,
                    };
                    // Send Request
                    JBrowseService.getNotes(notes_obj).then(
                        (response) => {
                            console.log("notes response: ", response);
                            let notes = []
                            // Loop through notes results and fix url strings
                            _.each(response.result, function (value) {
                                var obj = value;
                                obj.created_at = dayjs(value.created_at).format('MMMM D YYYY, h:mm:ss A');
                                notes.push(obj);
                            });
                            console.log("notes: ", notes);
                            setNotes(notes);
                            setLoading(false);
                        },
                        (error) => {
                            console.log("error: ", error);
                            setLoading(false);
                        }
                    )

                },
                (error) => {
                    console.log("error: ", error);
                    setLoading(false);
                }
            )
        } 
    }

    const showBatchNotes = () => {
        // Your implementation here
        setNotesIsOpen(false);
        setTimeout( ()=> { setBatchNotesModalIsOpen(true)})
    }

    const jumpToLocation = (location) => {
        let loc = removeParenthesesContent(location);
        // console.log("location: ", loc );
        setLoading(true);
        navigateToLocation(loc);
        setTimeout(() => {
            setNotesIsOpen(false);
            setLoading(false);
        }, 1000);
    }

    const closeModal = () => {
        setNotesIsOpen(false)
    }

    return (
        <div className={`fixed z-10 inset-0 overflow-y-auto flex justify-center`} style={{ zIndex: "1000" }}>
            <div className="modal-dialog max-w-6xl w-full mx-2">
                <div className="modal-content bg-white rounded-lg shadow-lg overflow-hidden">
                    <div className="modal-header p-4 flex justify-between items-center">
                        <h5 className="text-lg font-bold">Notes <small> - {notes.length}</small></h5>
                        <button className="text-gray-400 hover:text-gray-600" onClick={closeModal}>&times;</button>
                    </div>
                    <div className="modal-body p-4">

                        {notes.length === 0 ? (
                            <div className="text-center my-4">
                                <div className="alert text-blue-600 mb-4">
                                    <strong><i className="fas fa-exclamation-circle mr-2"></i> No Notes Exist</strong> take some notes.
                                </div>
                                <button className="btn bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded focus:outline-none">
                                    <i className="fas fa-upload mr-2"></i> Upload Batch Notes
                                </button>
                            </div>
                        ) : (
                            <div>
                                {/* Filter Input */}
                                <div className="mb-4">
                                    <label className="block text-sm font-bold mb-2" htmlFor="searchNotes">
                                        Search Notes
                                    </label>
                                    <div className="flex border rounded-md">
                                        <span className="flex items-center bg-gray-200 rounded-l-md p-2">
                                            <i className="fas fa-search"></i>
                                        </span>
                                        <input
                                            type="text"
                                            id="searchNotes"
                                            className="form-input flex-1 py-2 px-4 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                            placeholder="Search Notes..."
                                            value={query}
                                            onChange={(e) => setQuery(e.target.value)}
                                        />
                                        {query && (
                                            <span className="flex items-center bg-gray-200 rounded-r-md p-2 cursor-pointer" onClick={() => setQuery('')}>
                                                <i className="fas fa-trash-alt"></i>
                                            </span>
                                        )}
                                    </div>
                                </div>

                                <div className="overflow-x-auto">
                                    <table className="min-w-full leading-normal">
                                        <thead className="bg-gray-200">
                                            <tr>
                                                <th className="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                    Jump to Location
                                                </th>
                                                <th className="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                    Class
                                                </th>
                                                <th className="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                    Type
                                                </th>
                                                <th className="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                    Location
                                                </th>
                                                <th className="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                    Notes
                                                </th>
                                                <th className="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                    Date
                                                </th>
                                                <th className="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                    Edit
                                                </th>
                                                <th className="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                    Delete
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {paginatedData.map((note, index) => (
                                                <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                                                    <td className="py-2 px-4 border-b border-gray-200 text-sm">
                                                        <button className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded w-full" onClick={() => jumpToLocation(note.location)}>
                                                            <i className="fas fa-hand-point-right mr-2"></i> Jump
                                                        </button>
                                                    </td>
                                                    <td className="py-2 px-4 border-b border-gray-200 text-sm">
                                                        {note.Public === 0 ? 'Public' : 'Private'}
                                                    </td>
                                                    <td className="py-2 px-4 border-b border-gray-200 text-sm">
                                                        {note.category}
                                                    </td>
                                                    <td className="py-2 px-4 border-b border-gray-200 text-sm">
                                                        {note.location}
                                                    </td>
                                                    <td className="py-2 px-4 border-b border-gray-200 text-sm">
                                                        {note.note}
                                                    </td>
                                                    <td className="py-2 px-4 border-b border-gray-200 text-sm">
                                                        {note.created_at}
                                                    </td>
                                                    <td className="py-2 px-4 border-b border-gray-200 text-sm">
                                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded w-full" onClick={() => editNote( note )}>
                                                            <i className="fas fa-edit"></i>
                                                        </button>
                                                    </td>
                                                    <td className="py-2 px-4 border-b border-gray-200 text-sm">
                                                        <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded w-full" onClick={() => deleteNote( note )}>
                                                            <i className="fas fa-trash-alt"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </div>
                                <div className="flex justify-between mt-4">
                                    {currentPage > 1 && (
                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => setCurrentPage(currentPage - 1)}>
                                            Previous
                                        </button>
                                    )}
                                    <span>Page {currentPage} of {totalPages}</span>
                                    {currentPage < totalPages && (
                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => setCurrentPage(currentPage + 1)}>
                                            Next
                                        </button>
                                    )}
                                </div>
                            </div>
                        )}

                    </div>
                    <div className="modal-footer p-4 flex justify-between items-center">
                        <button className="bg-gray-400 hover:bg-gray-500 text-white py-2 px-4 rounded" onClick={closeModal}>
                            Close
                        </button>
                        <button className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded" onClick={showBatchNotes}>
                            <i className="fas fa-upload mr-2"></i> Upload Batch Notes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NotesModal;

function removeParenthesesContent(str) {
    return str.replace(/\s+\([^)]+\)$/, '');
}

