
import URL from '../common/GetUrl';
const url = URL();
// console.log("url: ", url );

export default function createAssembly(str) {
    const baseName = str.substring(0, str.lastIndexOf('.'));
    return {
        name: baseName,
        sequence: {
            type: "ReferenceSequenceTrack",
            trackId: `${baseName}-ReferenceSequenceTrack`,
            adapter: {
                type: "IndexedFastaAdapter",
                fastaLocation: {
                    uri: `${url}${str}`,
                    locationType: "UriLocation"
                },
                faiLocation: {
                    uri: `${url}${str}.fai`,
                    locationType: "UriLocation"
                }
            }
        }
    };
}
