import React, { useState, useEffect, useRef } from 'react';
import _ from 'underscore';

const HaploviewerModal = (props) => {
    // console.log("props: ", props);
    let { haploData,
        setHaploData,
        haploModalIsOpen,
        setHaploModalIsOpen,
        setAccessVariantIsOpen,
        setLoading,
    } = props;

    const imgSrc = require("../img/command.jpg");
    const style = { marginRight: "10px" }

    const [shouldUpdate, setShouldUpdate] = useState(false);
    const [clickedSample, setClickedSample] = useState(null);
    const [highlightedSample, setHighlightedSample] = useState(null);
    const [showTips, setShowTips] = useState(false);
    const [showLegend, setShowLegend] = useState(false);

    const modalRef = useRef(null);

    React.useEffect(() => {
        if (haploModalIsOpen) {
            modalRef.current.focus();
        }
        function handleKeyDown(event) {
            if (event.key === 'Escape' && haploModalIsOpen) {
                // Close the modal
                setHaploModalIsOpen( false );
            }
        }
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [haploModalIsOpen]);
    

    const handleClose = () => {
        setHaploModalIsOpen(false);
    };

    const handleGoBack = () => {
        setHaploModalIsOpen(false);
        setAccessVariantIsOpen(true);
    };

    const clearSamplesInput = () => {
        setHighlightedSample(null);
        const updatedCombo = haploData.combo.map(item => ({
            ...item,
            input: ''
        }));

        setHaploData(prevData => ({
            ...prevData,
            combo: updatedCombo
        }));
    }

    const removeHaploVariant = (sample) => {
        // Find the index of the sample to remove
        const indexToRemove = haploData.x.findIndex(value => value === sample);

        // If not found, just return
        if (indexToRemove === -1) return;

        // Filter out the sample from x
        const newX = haploData.x.filter((_, idx) => idx !== indexToRemove);

        // Filter out the associated data in combo
        const newCombo = haploData.combo.map(row => ({
            ...row,
            text: row.text.filter((_, idx) => idx !== indexToRemove),
            z: row.z.filter((_, idx) => idx !== indexToRemove),
        }));

        // Filter out the data from text and z arrays
        const newText = haploData.text.map(row => row.filter((_, idx) => idx !== indexToRemove));
        const newZ = haploData.z.map(row => row.filter((_, idx) => idx !== indexToRemove));

        // Update the state
        setHaploData(prevData => ({
            ...prevData,
            x: newX,
            combo: newCombo,
            text: newText,
            z: newZ
        }));

        setTimeout(() => {
            console.log("after haploData: ", haploData);
        }, 500);
    };

    const sortSamplesInput = () => {
        setHighlightedSample(null);

        var combo = haploData.combo.slice();
        // console.log("combo: ", combo);

        var inputs = [];
        _.each(combo, function (value) {
            if (value.input === '') {
                inputs.push(undefined)
            } else {
                inputs.push(value.input);
            }
        });

        console.log("inputs: ", inputs)

        var sortedInputs = sortWithIndeces(inputs);
        // console.log("sorted: ", sortedInputs);

        var sortedIndices = sortedInputs.sortIndices;

        // Sort Y Axis with correct indexes
        var ySorted = reorderByIndexes(haploData.y, sortedIndices);
        // console.log("test: ", ySorted );

        // Transpose the text array 
        var t = haploData.text[0].map((col, i) => haploData.text.map(row => row[i]));
        // console.log("t: ", t );

        var sortedTransposed = [];
        _.each(t, function (value, key) {
            // console.log("value: ", value );
            // console.log("sorted: ", sortedIndices );
            var dat = reorderByIndexes(value, sortedIndices);
            // console.log("got here: ", dat );
            sortedTransposed.push(dat);
        });
        // console.log("sortedTransposed: ", sortedTransposed );
        // Transpose back the text Array
        var newTextArray = sortedTransposed[0].map((col, i) => sortedTransposed.map(row => row[i]));

        // Transpose the z array
        var zt = haploData.z[0].map((col, i) => haploData.z.map(row => row[i]));
        // console.log("zt: ", zt );

        var sortedZTransposed = [];
        _.each(zt, function (value, key) {
            // console.log("value: ", value );
            // console.log("sorted: ", sortedIndices );
            var dat = reorderByIndexes(value, sortedIndices);
            // console.log("got here: ", dat );
            sortedZTransposed.push(dat);
        });
        // console.log("sortedZTransposed: ", sortedZTransposed );
        // Transpose back the Z Array
        var newZArray = sortedZTransposed[0].map((col, i) => sortedZTransposed.map(row => row[i]));

        // Add Sample Names to Array 
        // newZArray.push( zPop );
        // newTextArray.push( textPop );

        // Combine two arrays into an Object 
        var result = [];
        _.each(newZArray, function (value, key) {
            var s = value.toString();
            s = s.split('.').join("");
            s = s.split(",");
            // console.log("s: ", s );
            var obj = {
                z: s,
                text: newTextArray[key],
                input: inputs[key]
            };
            result.push(obj);
        });

        // console.log("result: ", result );
        // console.log("newZArray: ", newZArray );
        // console.log("new Text Array: ", newTextArray );
        const updatedDataArray = result.map(item => ({
            ...item,
            input: item.input === undefined ? '' : item.input
        }));
        // console.log("updated: ", updatedDataArray );

        // console.warn("fire render!");
        setTimeout(() => {
            setHaploData(prevData => ({
                ...prevData,
                y: ySorted,
                z: newZArray,
                combo: updatedDataArray,
                text: newTextArray
            }));
            setShouldUpdate(false);
            setLoading(false);
            return;
        }, 500);

    };

    const clickSample = (sample) => {
        setHighlightedSample(sample);
        setLoading(true);
        setClickedSample(sample); // Store the clicked sample
        setShouldUpdate(prev => !prev); // Toggle shouldUpdate
    }

    const handleInputChange = (value, comboIndex) => {
        // Create a new copy of the haploData
        let updatedHaploData = { ...haploData };

        // Update the input value
        updatedHaploData.combo[comboIndex].input = value;

        // Set the updated state
        setHaploData(updatedHaploData);
    }

    useEffect(() => {
        if (shouldUpdate) {
            // ... the same logic to compute new values for haploData ...

            // Highlight Variant Names on Sort 
            // _.each(haploData.names, function (value, key) {
            // var c = ".variant" + (key + 1);
            // if (value == clickedSample) {
            //     $(c).css('color', 'blue');
            // }
            // else {
            //     $(c).css('color', 'black');
            // }
            // });

            // Get Sorted Array and Indexes
            var index;
            _.each(haploData.x, function (value, key) {
                if (value === clickedSample) {
                    index = key;
                }
            });
            var col = [];
            _.each(haploData.text, function (value, key) {
                // console.log("key: ", key );
                col.push(value[index]);
            });
            // console.log("col: ", col );
            var sortedArray = sortWithIndeces(col);
            // console.log("sorted", sortedArray );

            // Get sorted indexes 
            var sortedIndices = sortedArray.sortIndices;
            // console.log("sorted I's : ", sortedIndices );

            // Sort Y Axis with correct indexes
            var ySorted = reorderByIndexes(haploData.y, sortedIndices);
            // console.log("test: ", ySorted );

            // Transpose the text array 
            var t = haploData.text[0].map((col, i) => haploData.text.map(row => row[i]));
            // console.log("t: ", t );

            var sortedTransposed = [];
            _.each(t, function (value, key) {
                // console.log("value: ", value );
                // console.log("sorted: ", sortedIndices );
                var dat = reorderByIndexes(value, sortedIndices);
                // console.log("got here: ", dat );
                sortedTransposed.push(dat);
            });
            // console.log("sortedTransposed: ", sortedTransposed );
            // Transpose back the text Array
            var newTextArray = sortedTransposed[0].map((col, i) => sortedTransposed.map(row => row[i]));

            // Transpose the z array
            var zt = haploData.z[0].map((col, i) => haploData.z.map(row => row[i]));
            // console.log("zt: ", zt );

            var sortedZTransposed = [];
            _.each(zt, function (value, key) {
                // console.log("value: ", value );
                // console.log("sorted: ", sortedArray.sortIndices );
                var dat = reorderByIndexes(value, sortedArray.sortIndices);
                // console.log("got here: ", dat );
                sortedZTransposed.push(dat);
            });
            // console.log("sortedZTransposed: ", sortedZTransposed );
            // Transpose back the Z Array
            var newZArray = sortedZTransposed[0].map((col, i) => sortedZTransposed.map(row => row[i]));

            // Add Sample Names to Array
            // newZArray.push( zPop );
            // newTextArray.push( textPop );

            // Combine two arrays into an Object
            var result = [];
            _.each(newZArray, function (value, key) {
                var s = value.toString();
                s = s.split('.').join("");
                s = s.split(",");
                // console.log("s: ", s );
                var obj = {
                    z: s,
                    text: newTextArray[key]
                };
                result.push(obj);
            });

            console.log("combo: ", result);
            // console.log("ySorted: ", ySorted);
            // console.log("Z Arr: ", newZArray);
            console.log("text: ", newTextArray);

            setTimeout(() => {
                setHaploData({
                    ...haploData,
                    y: ySorted,
                    z: newZArray,
                    combo: result,
                    text: newTextArray
                });
                setShouldUpdate(false);
                setLoading(false);
            }, 500);
        }
    }, [shouldUpdate]);

    return (
        haploModalIsOpen && (
            <>
                <div className="fixed inset-0 overflow-y-auto flex items-center justify-center" style={{ zIndex: 9999 }}>
                    <div ref={modalRef} className="modal-dialog max-w-screen-xl mx-auto bg-white rounded-lg shadow-lg overflow-y-auto" tabIndex="0" style={{ maxHeight: "95%", maxWidth: "95%" }}>

                        <div className="modal-content">
                            <div className="flex justify-between items-center p-4 border-b">
                                <h5 className="text-lg font-bold">Haploviewer</h5>
                                <button onClick={handleClose} className="text-gray-400 hover:text-gray-600">&times;</button>
                            </div>

                            <div className="p-4">

                                {/* Loader */}
                                <div className="flex justify-center items-center" id="haplo-loader-spinner" style={{ display: 'none' }}>
                                    {/* You can use a spinner library here */}
                                </div>


                                {/* HaploViewer Table */}
                                <div className="row" style={{ margin: '5px' }}>
                                    <div style={{ overflow: 'auto' }} id="scrollable-area">
                                        <table className="table" id="haplo-table">
                                            <tbody>
                                                {
                                                    haploData.combo && haploData.combo.map((x, index) => (
                                                        <tr key={index}>
                                                            <td className="text-center bg-gray-300 p-2 sticky top-0 z-10">
                                                                {haploData.y[index]}
                                                            </td>
                                                            <td className="text-center bg-gray-300 p-2 sticky top-0 z-10">
                                                                <input
                                                                    placeholder="..."
                                                                    type="text"
                                                                    className="text-center border rounded w-full"
                                                                    value={x.input}
                                                                    onChange={e => { handleInputChange(e.target.value, index) }}
                                                                />
                                                            </td>
                                                            {
                                                                x.text.map((y, yIndex) => (
                                                                    <td key={yIndex} className={`text-center border border-gray-300 p-2 w-12 ${y} color${x.z[yIndex]}`} title={haploData.y[index]}>
                                                                        {y}
                                                                    </td>
                                                                ))
                                                            }
                                                        </tr>
                                                    ))
                                                }

                                                {/* Remove Variant Row */}
                                                <tr className="sample-row">
                                                    <td className="bg-gray-300">
                                                        <div className="text-center sticky top-0 z-10">Remove Variant</div>
                                                    </td>
                                                    <td className="bg-gray-300">
                                                        <div className="text-center">
                                                            <button className="bg-red-500 hover:bg-red-600 text-white py-1 px-2 rounded" onClick={clearSamplesInput}>Clear</button>
                                                        </div>
                                                    </td>
                                                    {
                                                        haploData.x && haploData.x.map((value, key) => (
                                                            <td key={key} className="bg-gray-300">
                                                                <div className="text-center text-red-500 cursor-pointer">
                                                                    <i className="far fa-times-circle text-xl" onClick={() => removeHaploVariant(value)}></i>
                                                                </div>
                                                            </td>
                                                        ))
                                                    }
                                                </tr>

                                                {/* Click to Sort Row */}
                                                <tr className="sample-row">
                                                    <td className="bg-gray-300">
                                                        <div className="text-center sticky top-0 z-10">Click to Sort</div>
                                                    </td>
                                                    <td className="bg-gray-300">
                                                        <div className="text-center">
                                                            <button className="bg-gray-500 hover:bg-gray-600 text-white py-1 px-2 rounded" onClick={sortSamplesInput}>Sort</button>
                                                        </div>
                                                    </td>
                                                    {
                                                        haploData.x && haploData.x.map((value, key) => (
                                                            <td key={key} className="bg-gray-300 `variant${key + 1}`">
                                                                <div className="cursor-pointer py-2"
                                                                    id={value}
                                                                    onClick={() => clickSample(value)}
                                                                    style={{ color: value === highlightedSample ? 'blue' : 'black' }}>
                                                                    {value}
                                                                </div>
                                                            </td>
                                                        ))
                                                    }
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                {showTips && (
                                    <div className="flex flex-col items-center mt-6">
                                        <div className="flex justify-center">
                                            <img src={imgSrc} alt="keys" />
                                        </div>
                                        <p className="mt-4 mb-4 text-sm text-center">
                                            Use command(mac)/control(PC) and the '+' or '-' keys to control zoom of your browser.
                                            <br />
                                            Zoom out until the entire table fits inside your browser window.
                                        </p>
                                        <hr className="my-4 w-full" />
                                        <div className="mb-4 flex items-center justify-center">
                                            Click the red
                                            <i className="far fa-times-circle fa-2x text-red-500 ml-2 mr-2"></i>
                                            to remove a variant from the haploviewer.
                                        </div>
                                        <hr className="my-4 w-full" />
                                        <p>Click variants on the X-axis to sort.</p>
                                    </div>
                                )}

                                {showLegend && (
                                    <div className="flex flex-wrap -mx-4">
                                        <div className="w-1/2 px-4 mt-6">
                                            <label className="block text-gray-700 font-bold mb-2">Types</label>
                                            <ul className="space-y-2">
                                                {[
                                                    { color: "bg-blue-500", label: "Reference" },
                                                    { color: "bg-red-400", label: "Non-Reference" },
                                                    { color: "bg-yellow-300", label: "Heterozygous" },
                                                    { color: "bg-white", label: "Missing", symbol: "-" }
                                                ].map((item, idx) => (
                                                    <li key={idx} className="flex items-center space-x-2 p-3 bg-gray-100 rounded">
                                                        <div className={`w-6 h-6 ${item.color} flex justify-center items-center`}>
                                                            {item.symbol && <span className="ml-2">{item.symbol}</span>}
                                                        </div>
                                                        <div>{item.label}</div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                        <div className="w-1/2 px-4 mt-6">
                                            <label className="block text-gray-700 font-bold mb-2">Nucleotide Codes</label>
                                            <ul className="space-y-2">
                                                {[
                                                    { color: "bg-yellow-300", label: "A or G", symbol: "R" },
                                                    { color: "bg-yellow-300", label: "C or T", symbol: "Y" },
                                                    { color: "bg-yellow-300", label: "G or C", symbol: "S" },
                                                    { color: "bg-yellow-300", label: "A or T", symbol: "W" },
                                                    { color: "bg-yellow-300", label: "G or T", symbol: "K" },
                                                    { color: "bg-yellow-300", label: "A or C", symbol: "M" },
                                                    { color: "bg-yellow-300", label: "indel heterozygote", symbol: "h" },
                                                ].map((item, idx) => (
                                                    <li key={idx} className="flex items-center space-x-2 p-3 bg-gray-100 rounded">
                                                        <div className={`w-8 h-8 ${item.color} flex justify-center items-center rounded`}>
                                                            <span>{item.symbol}</span>
                                                        </div>

                                                        <div>{item.label}</div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                )}


                            </div>
                            <div className="flex justify-between items-center p-4 border-t">
                                <button onClick={handleGoBack} className="bg-gray-400 hover:bg-gray-500 text-white py-2 px-4 rounded">
                                    <i className="far fa-hand-point-left mr-2"></i> Go Back
                                </button>
                                {/* Legend and Tips buttons would open other modals or provide more functionality */}
                                <div>
                                    <button className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded mr-2" onClick={() => { setShowLegend(!showLegend) }}>
                                        Legend
                                    </button>
                                    <button className="bg-blue-400 hover:bg-blue-500 text-white py-2 px-4 rounded mr-2" onClick={() => { setShowTips(!showTips) }}>
                                        Tips
                                    </button>
                                    <button onClick={handleClose} className="bg-gray-600 hover:bg-gray-700 text-white py-2 px-4 rounded" >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    );
};

export default HaploviewerModal;

// Useful Functions
const transposeArray = (array) => {
    return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
};

function sortWithIndeces(toSort) {
    for (var i = 0; i < toSort.length; i++) {
        toSort[i] = [toSort[i], i];
    }
    toSort.sort(function (left, right) {
        return left[0] < right[0] ? -1 : 1;
    });
    toSort.sortIndices = [];
    for (var j = 0; j < toSort.length; j++) {
        toSort.sortIndices.push(toSort[j][1]);
        toSort[j] = toSort[j][0];
    }
    return toSort;
}
function reorderByIndexes(arr, order) {
    return order.map((index) => arr[index]);
}