import JBrowseService from '../services/jbrowse.service';
import URL from '../common/GetUrl';
const url = URL();
const axios = require('axios');

export default async function createTracks(assembly, files, paf) {
    const trackPromises = files.map(async file => {
        const extension = file.split('.').pop();
        const fileBaseName = file.substring(0, file.lastIndexOf('.'));
        const assemblyName = assembly.substring(0, assembly.lastIndexOf('.'));
        // Handle file types
        switch (extension) {
            case 'vcf':
                return {
                    type: "VariantTrack",
                    trackId: fileBaseName,
                    name: `${fileBaseName}.${extension}`,
                    assemblyNames: [assemblyName.toString()],
                    adapter: {
                        type: "VcfTabixAdapter",
                        vcfGzLocation: {
                            uri: `${url}${fileBaseName}.vcf.gz`,
                            locationType: "UriLocation"
                        },
                        index: {
                            location: {
                                uri: `${url}${fileBaseName}.vcf.gz.tbi`,
                                locationType: "UriLocation"
                            },
                            indexType: "TBI"
                        }
                    }
                };
            case 'gff':
            case 'ann2':
            case 'cov':
            case 'mq':
                // Blast Check 
                var blast_bool = containsBlast(fileBaseName);
                var notes_bool = containsNotes(fileBaseName);
                var samples_bool = containsSamples(fileBaseName);
                // console.log('check blast: ', blast_bool );
                if (blast_bool) {
                    var displayName = splitPathAndFile(fileBaseName);
                    // console.log("name: ", displayName.fileName )
                    return {
                        type: "FeatureTrack",
                        trackId: displayName.fileName + ".gff",
                        name: displayName.fileName + ".gff",
                        assemblyNames: [assemblyName.toString()],
                        adapter: {
                            type: 'Gff3TabixAdapter',
                            gffGzLocation: {
                                uri: `${url}${fileBaseName}.${extension}.gz`,
                            },
                            index: {
                                location: {
                                    uri: `${url}${fileBaseName}.${extension}.gz.tbi`,
                                },
                            },
                        },
                    };
                } else if (notes_bool) {
                    // console.warn("notes bool: ", notes_bool );
                    const lastSlashIndex = fileBaseName.lastIndexOf('/');
                    const afterLastSlash = fileBaseName.substring(lastSlashIndex + 1);
                    return {
                        type: "FeatureTrack",
                        trackId: afterLastSlash + ".gff",
                        name: afterLastSlash + ".gff",
                        assemblyNames: [assemblyName.toString()],
                        adapter: {
                            type: 'Gff3TabixAdapter',
                            gffGzLocation: {
                                uri: `${url}${fileBaseName}.${extension}.gz`,
                            },
                            index: {
                                location: {
                                    uri: `${url}${fileBaseName}.${extension}.gz.tbi`,
                                },
                            },
                        },
                    };

                } else {
                    // No Search Files
                    return {
                        type: "FeatureTrack",
                        trackId: fileBaseName,
                        name: `${fileBaseName}.${extension}`,
                        assemblyNames: [assemblyName.toString()],
                        adapter: {
                            type: 'Gff3TabixAdapter',
                            gffGzLocation: {
                                uri: `${url}${fileBaseName}.${extension}.gz`,
                            },
                            index: {
                                location: {
                                    uri: `${url}${fileBaseName}.${extension}.gz.tbi`,
                                },
                            },
                        },
                        displays: [{
                            type: 'LinearBasicDisplay',
                            displayId: `${fileBaseName}-LinearBasicDisplay`,
                            renderer: {
                                type: "SvgFeatureRenderer",
                                color1: "jexl:colorFeature( feature )",
                                // color2: "jexl:modifyFeatureColor(feature )"
                                height: 25, // Adjust this value as needed
                                noLabels: true, // Disable feature labels
                                noDescriptions: true, // Disable feature descriptions
                            },
                            defaultRendering: 'collapse', // Set the initial display mode to 'collapsed'
                        }]
                    };
                }
                break;
            case 'gff3':
                // gff3-specific return object
                // console.log("no text search for: ", fileBaseName );
                console.log("normal track.");
                return {
                    type: "FeatureTrack",
                    trackId: fileBaseName,
                    name: `${fileBaseName}.${extension}`,
                    assemblyNames: [assemblyName.toString()],
                    adapter: {
                        type: 'Gff3TabixAdapter',
                        gffGzLocation: {
                            uri: `${url}${fileBaseName}.${extension}.gz`,
                        },
                        index: {
                            location: {
                                uri: `${url}${fileBaseName}.${extension}.gz.tbi`,
                            },
                        },
                    },
                    textSearching: {
                        textSearchAdapter: {
                            type: 'TrixTextSearchAdapter',
                            textSearchAdapterId: `${fileBaseName}-index`,
                            ixFilePath: {
                                uri: `${url}${fileBaseName}.${extension}.gz.ix`,
                                locationType: 'UriLocation'
                            },
                            ixxFilePath: {
                                uri: `${url}${fileBaseName}.${extension}.gz.ixx`,
                                locationType: 'UriLocation'
                            },
                            metaFilePath: {
                                uri: `${url}${fileBaseName}.${extension}.gz_meta.json`,
                                locationType: 'UriLocation'
                            },
                            assemblyNames: [assemblyName.toString()]
                        }
                    },
                    displays: [{
                        type: 'LinearBasicDisplay',
                        displayId: `${fileBaseName}-LinearBasicDisplay`,
                        renderer: {
                            type: "SvgFeatureRenderer",
                            color1: "jexl:colorFeature( feature )",
                            // color2: "jexl:modifyFeatureColor(feature )"
                            height: 25, // Adjust this value as needed
                            noLabels: true, // Disable feature labels
                            noDescriptions: true, // Disable feature descriptions
                        },
                        defaultRendering: 'collapse', // Set the initial display mode to 'collapsed'
                    }],
                };
                break;
            case 'bam':
                // BAM-specific return object
                return {
                    type: "AlignmentsTrack",
                    trackId: fileBaseName,
                    name: `${fileBaseName}.${extension}`,
                    assemblyNames: [assemblyName],
                    adapter: {
                        type: "BamAdapter",
                        bamLocation: {
                            uri: `${url}${fileBaseName}.bam`,
                            locationType: "UriLocation"
                        },
                        index: {
                            location: {
                                uri: `${url}${fileBaseName}.bai`,
                                locationType: "UriLocation"
                            },
                            indexType: "BAI"
                        }
                    }
                }
                break;
            case 'paf':
                return {
                        type: "SyntenyTrack",
                        trackId: fileBaseName,
                        assemblyNames: [ assemblyName, paf ],
                        name: `${fileBaseName}.${extension}`,
                        adapter: {
                            type: "PAFAdapter",
                            pafLocation: {
                                uri: `${url}${fileBaseName}.paf`
                            },
                            assemblyNames: [ assemblyName, paf ]
                        }
                    }
                break;
            case 'bw':
                return {
                    type: "MultiQuantitativeTrack",
                    trackId: fileBaseName,
                    name: `${fileBaseName} Coverage`,
                    assemblyNames: [assemblyName],
                    adapter: {
                        type: "MultiWiggleAdapter",
                        subadapters: [
                            {
                                type: 'BigWigAdapter',
                                name: fileBaseName,
                                bigWigLocation: {
                                    uri: `${url}${fileBaseName}.bw`,
                                },
                                group: 'group1',
                            },
                        ],
                    },
                };
            break;
            
            default:
                // Handle default case if needed
                console.warn("something went wrong, create tracks..");
        }
      
    });

    return Promise.all(trackPromises);

}

function containsBlast(str) {
    return str.includes("Blast/");
}

function containsNotes(str) {
    return str.includes(".notes");
}

function containsSamples(str) {
    return str.includes("Samples/");
}

function splitPathAndFile(fullPath) {
    const pathArray = fullPath.split('/');
    const fileName = pathArray.pop();
    const path = pathArray.join('/');
    return { path, fileName };
}

function checkForStrings(inputString, searchString1, searchString2) {
    return inputString.includes(searchString1) || inputString.includes(searchString2);
}