import axios from "axios";

import URL from '../common/GetUrl';
var url = URL();
const API_URL = url;

const register = (username, email, password, authyId, company) => {
  return axios.post(API_URL + "api/auth/signup", {
    username,
    email,
    password,
    authyId,
    company,
  }, {
    withCredentials: true
  });
};

const login = (username, password, otp) => {
  return axios
    .post(API_URL + "api/auth/signin", {
      username,
      password,
      otp
    }, {
      withCredentials: true
    })
    .then((response) => {
      if (response.data.accessToken) {
        // console.log("user auth.service data: ", response );
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const checkAccess = (body) => {
  // console.log("from service: ", body );
  return axios
      .post(API_URL + "api/auth/checkAccess", { body }, { withCredentials: true })
      .then((response) => {
          return response.data;
      });
};

const logout = () => {
  localStorage.removeItem("user");
};

export default {
  register,
  login,
  logout,
  checkAccess,
};

