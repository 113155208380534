import React, { useState, useEffect } from 'react';
import EventBus from '../common/EventBus';
import JBrowseService  from '../services/jbrowse.service';

const DeleteModal = (props) => {
    let { deleteModalIsOpen, setDeleteModalIsOpen, selectedSession, setLoading, currentUser, setCurrentSessions } = props;
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState(""); // can be 'success' or 'error'

    useEffect(() => {
        if (deleteModalIsOpen) {
            const handleKeyDown = (event) => {
                if (event.key === 'Escape') {
                    closeModal();
                }
            };

            window.addEventListener('keydown', handleKeyDown);

            // Cleanup the event listener when the component is unmounted
            return () => {
                window.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [deleteModalIsOpen]);

    const deleteSession = () => {
        setLoading(true);
        let obj = {
            sessionId: selectedSession._id,
            userEmail: currentUser.email
        };
        console.log('obj: ', obj );
        // return;
        // Delete Session
        JBrowseService.deleteSession( obj ).then(
            (response) => {
                console.log("saved session response: ", response);
                // Get Sessions
                JBrowseService.getSavedSessions({ user: currentUser }).then(
                    (response) => {
                        console.log("saved session response: ", response);
                        setCurrentSessions(response.result)
                        setAlertMessage("Session deleted successfully!");
                        setAlertType("success");
                        setLoading( false );
                        setTimeout( ()=> {
                            closeModal();
                        },2000);
                    },
                    (error) => {
                        console.log("error: ", error);
                        setLoading(false);
                        if (error.code === "ERR_NETWORK" || error.code === "ERR_BAD_REQUEST") {
                            console.warn("logout!!!");
                            EventBus.dispatch("logout");
                        }
                    }
                )
            },
            (error) => {
                console.log("error: ", error);
                setAlertMessage("Error deleting session. Please try again.");
                setAlertType("error");
                setLoading( false );
                if (error.code === "ERR_NETWORK" || error.code === "ERR_BAD_REQUEST") {
                    console.warn("logout!!!");
                    EventBus.dispatch("logout");
                }
            }
        )
    }

    const closeModal = () => {
        setDeleteModalIsOpen(false);
        setAlertMessage("");
        setAlertType("");
    }

    return (
        <>
            {deleteModalIsOpen && (
                <div className="fixed z-10 inset-0 overflow-y-auto flex justify-center items-center">
                    <div className="bg-black bg-opacity-50 absolute inset-0"></div>
                    <div className="bg-white p-4 rounded-lg w-4/5 max-h-screen flex flex-col relative z-20">
                        <div className="flex justify-between items-center mb-4">
                            <h5 className="text-lg font-bold">Delete Confirmation</h5>
                            <button className="text-gray-400 hover:text-gray-600" onClick={closeModal}>&times;</button>
                        </div>

                        {alertMessage && (
                            <div className={`mb-4 text-center p-3 ${alertType === 'success' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                                {alertMessage}
                            </div>
                        )}

                        <div className="overflow-y-auto flex-grow">
                            <p className="text-gray-600 mb-4">
                                Are you sure you want to delete this session? This action cannot be undone.
                            </p>
                        </div>

                        <small>{selectedSession.session.name}, {selectedSession.location}</small>

                        <div className="flex justify-end mt-4">
                            <button className="bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded mr-2" onClick={deleteSession}>
                                Confirm Delete
                            </button>
                            <button className="bg-gray-600 hover:bg-gray-700 text-white py-2 px-4 rounded" onClick={closeModal}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default React.memo(DeleteModal);
