import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faSave } from '@fortawesome/free-solid-svg-icons';
import JBrowseService from '../services/jbrowse.service';
import _ from 'underscore';
import dayjs from 'dayjs';

function EditNotesModal(props) {
    const { currentNote,
            editNotesModalIsOpen,
            setEditNotesModalIsOpen,
            selectedAssembly,
            currentUser,
            setNotesIsOpen,
            setLoading,
            setNotes,
        } = props;
    const [editedNote, setEditedNote] = useState({
        category: currentNote ? currentNote.category : '',
        chromosome: currentNote ? currentNote.chromosome : '',
        start: currentNote ? currentNote.start : '',
        end: currentNote ? currentNote.end : '',
        class: currentNote ? (currentNote.Public === 0 ? 'Public' : 'Private') : '',
        note: currentNote ? currentNote.note : ''
    });
        
    const [alert, setAlert] = useState({ type: '', message: '' });

    useEffect(() => {
        setEditedNote(currentNote);
    }, [currentNote]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedNote(prevState => ({ ...prevState, [name]: value }));
    };

    const saveEdit = () => {
        setLoading(true);
        let obj = {
            old: currentNote,
            new: editedNote,
            reference: selectedAssembly,
            user: currentUser
        }
        console.log("obj: ", obj ); 

        // Send Request
        JBrowseService.editNotes( obj ).then(
            (response) => {
                console.log("edit response: ", response );

                // Get Notes
                let notes_obj = {
                    idCompany: currentUser.Company_idCompany,
                    username: currentUser.email,
                    assembly: selectedAssembly,
                };
                // Send Request
                JBrowseService.getNotes(notes_obj).then(
                    (response) => {
                        console.log("notes response: ", response);
                        let notes = []
                        // Loop through notes results and fix url strings
                        _.each(response.result, function (value) {
                            var obj = value;
                            obj.created_at = dayjs(value.created_at).format('MMMM D YYYY, h:mm:ss A');
                            notes.push(obj);
                        });
                        console.log("notes: ", notes);
                        setNotes(notes);
                    },
                    (error) => {
                        console.log("error: ", error);
                    }
                )

                setAlert({ type: 'success', message: 'Note was saved successfully.' });
                setTimeout(()=> {
                    setAlert("");
                },3000);
                setLoading(false);
            },
            (error) => {
                console.log("error: ", error );
                setAlert({ type: 'danger', message: 'Something went wrong, refresh your browser and try again.' });
                setTimeout(()=> {
                    setAlert("");
                },3000);
                setLoading(false);
            }
        )

    };

    const closeModal = () => {
        setEditNotesModalIsOpen(false);
    }

    const onGoBack = () => {
        setEditNotesModalIsOpen( false );
        setTimeout( ()=> { setNotesIsOpen(true) })
    }

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen">
                <div className="bg-white rounded-lg shadow-xl w-3/4">
                    <div className="bg-gray-50 p-4 rounded-t-lg border-b border-gray-200 flex justify-between items-center">
                        <h5 className="text-lg font-medium">Notes</h5>
                        <button className="text-gray-400 hover:text-gray-500" onClick={closeModal}>
                            &times;
                        </button>
                    </div>
                    <div className="p-4">
                        <form>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-2">Type:</label>
                                <select
                                    className="block w-full p-2 border rounded-md"
                                    name="category"
                                    value={editedNote.category}
                                    onChange={handleInputChange}
                                >
                                    <option value="" disabled>Please select</option>
                                    <option value="Gene">Gene</option>
                                    <option value="Variant">Variant</option>
                                    <option value="Primer">Primer</option>
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-2">Chromosome:</label>
                                <input
                                    type="text"
                                    className="block w-full p-2 border rounded-md"
                                    name="chromosome"
                                    value={editedNote.chromosome}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-2">Starting:</label>
                                <input
                                    type="number"
                                    className="block w-full p-2 border rounded-md"
                                    name="start"
                                    value={editedNote.start}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-2">Ending:</label>
                                <input
                                    type="number"
                                    className="block w-full p-2 border rounded-md"
                                    name="end"
                                    value={editedNote.end}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-2">Class</label>
                                <select
                                    className="block w-full p-2 border rounded-md"
                                    name="class"
                                    value={editedNote.class}
                                    onChange={handleInputChange}
                                >
                                    <option value="" disabled>Please select</option>
                                    <option value="Public">Public ( Company )</option>
                                    <option value="Private">Private ( User )</option>
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-2">Notes:</label>
                                <textarea
                                    className="block w-full p-2 border rounded-md resize-y"
                                    name="note"
                                    rows="5"
                                    placeholder="Notes..."
                                    value={editedNote.note}
                                    onChange={handleInputChange}
                                ></textarea>
                            </div>
                        </form>

                        {alert.type === 'success' && (
                            <div className="mt-4 bg-green-100 text-green-800 p-4 rounded text-center">
                                <strong><FontAwesomeIcon icon={faCheckCircle} className="mr-2" /> {alert.message}</strong>
                            </div>
                        )}

                        {alert.type === 'error' && (
                            <div className="mt-4 bg-red-100 text-red-800 p-4 rounded text-center">
                                <strong><FontAwesomeIcon icon={faExclamationCircle} className="mr-2" /> Note was not saved.</strong>
                            </div>
                        )}
                    </div>
                    <div className="bg-gray-50 p-4 rounded-b-lg flex justify-between space-x-4">
                        <button onClick={onGoBack} className="bg-gray-400 hover:bg-gray-500 text-white py-2 px-4 rounded">
                            <i className="fas fa-hand-point-left mr-2"></i> Go Back
                        </button>
                        <div className="flex space-x-4">
                            <button
                                onClick={saveEdit}
                                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                            >
                                <FontAwesomeIcon icon={faSave} className="mr-2" /> Save Note
                            </button>
                            <button className="px-4 py-2 bg-gray-300 text-black rounded hover:bg-gray-400 focus:outline-none" onClick={closeModal}>
                                Close
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default EditNotesModal;
