import React, { useState } from 'react';
import JBrowseService from '../services/jbrowse.service';
import EventBus from '../common/EventBus';
import _ from 'underscore';
import dayjs from 'dayjs';

const CreateNotesModal = (props) => {
    const { createNotesModalIsOpen,
            setCreateNotesModalIsOpen,
            location,
            setLocation,
            currentUser,
            selectedAssembly,
            setLoading,
            createTracks,
            selectedTracks,
            setCurrentTracks,
            setCurrentSession,
            createDefaultSession,
            updateTracks,
            setNotes,
         } = props;
    const [isVisible, setIsVisible] = useState(true);
    const [category, setCategory] = useState('');
    const [noteClass, setNoteClass] = useState('');
    const [noteContent, setNoteContent] = useState('');
    const [isTemporary, setIsTemporary] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const saveNote = () => {
        // Logic to save the note goes here
        setLoading( true );
        console.log("loc: ", location );
        const [chromosomePart, rangePart] = location.split(':');
        const [start, end] = rangePart.split('-');

        console.log(`Chromosome: ${chromosomePart}`);
        console.log(`Start: ${start}`);
        console.log(`End: ${end}`);

        // Generate current timestamp
        const now = dayjs()

        // Format as MySQL datetime
        const mysqlTimestamp = now.format('YYYY-MM-DD HH:mm:ss')

        let obj = {
            notes: {
                location: location,
                category: category,
                noteClass: noteClass,
                noteContent: noteContent,
                isTemporary: isTemporary,
                chromosome: chromosomePart,
                created_at: mysqlTimestamp,
                start: start,
                end: end,
            },
            user: currentUser,
            reference: selectedAssembly,
        }
        console.log("obj: ", obj );
        // return;
        // Send Request
        JBrowseService.createNote( obj ).then(
            ( response ) => {
                console.log("create notes response: ", response );

                /** Add Note Tracks */
                // console.log("tracks: ", selectedTracks )
                // var tracks = selectedTracks.concat(response.file);
                // var newTracks = createTracks(selectedAssembly, selectedTracks);
                // console.log("newTracks: ", newTracks );

                // // Clean Up, old BlastGff files will fuck this up
                // const cleanTracks = newTracks.filter(item => item !== undefined);
                // var trackArray = _.pluck(cleanTracks, 'name');
                // // console.log("tracks array: ", trackArray );
                // setCurrentTracks(newTracks);

                // const newSession = createDefaultSession(selectedAssembly, trackArray);
                // // console.log("newSession: ", newSession);
                // setCurrentSession(newSession);

                // Get Notes
                let notes_obj = {
                    idCompany: currentUser.idCompany,
                    username: currentUser.email,
                    assembly: selectedAssembly,
                };

                console.log("notes_obj: ", notes_obj );

                /** Wait for the file to update.. */
                setTimeout(() => {
                    // Send Request
                    // updateTracks(cleanTracks, newSession);
                    JBrowseService.getNotes(notes_obj).then(
                        (response) => {
                            console.log("notes response: ", response);
                            let notes = []
                            // Loop through notes results and fix url strings
                            _.each(response.result, function (value) {
                                var obj = value;
                                obj.created_at = dayjs(value.created_at).format('MMMM D YYYY, h:mm:ss A');
                                notes.push(obj);
                            });
                            console.log("notes: ", notes);
                            setNotes( notes );
                            setCategory("");
                            setNoteClass("");
                            setNoteContent("");
                            setSuccessMessage('Your note has been uploaded successfully!');
                            setLoading(false);
                            setTimeout(() => {
                                setSuccessMessage('');
                            }, 3000)
                        },
                        (error) => {
                            console.log("error: ", error);
                        }
                    )
                }, 2000);

            },
            (error) => {
                console.log("error: ", error );
                if ( error.code === "ERR_NETWORK") {
                    console.warn("logout!!!");
                    EventBus.dispatch("logout");
                }
                setLoading(false);
            }
        )


    };

    const toggleModal = () => {
        setCreateNotesModalIsOpen(!createNotesModalIsOpen);
    };

    return (
        <div className={`fixed top-0 left-0 w-full h-full flex items-center justify-center z-50`}>
            <div className="bg-white rounded-lg overflow-hidden shadow-xl w-4/5 md:w-2/3">
                <div className="border-b px-4 py-2 flex justify-between items-center">
                    <h5 className="font-semibold">Notes</h5>
                    <button className="text-gray-500 hover:text-gray-600" onClick={toggleModal}>&times;</button>
                </div>

                <div className="p-4">
                    <div>
                        <label htmlFor="location" className="block text-sm font-bold mb-2">Location</label>
                        <input
                            type="text"
                            id="location"
                            className="shadow-md appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Location"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                        />
                    </div>

                    {/* Type */}
                    <div className="mt-4">
                        <label htmlFor="type" className="block text-sm font-bold mb-2">Type</label>
                        <select
                            id="type"
                            className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow-md leading-tight focus:outline-none focus:shadow-outline"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                        >
                            <option value="" disabled>Please select</option>
                            <option value="Gene">Gene</option>
                            <option value="Variant">Variant</option>
                            <option value="Primer">Primer</option>
                        </select>
                    </div>

                    {/* Class */}
                    <div className="mt-4">
                        <label htmlFor="class" className="block text-sm font-bold mb-2">Class</label>
                        <select
                            id="class"
                            className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow-md leading-tight focus:outline-none focus:shadow-outline"
                            value={noteClass}
                            onChange={(e) => setNoteClass(e.target.value)}
                        >
                            <option value="" disabled>Please select</option>
                            <option value="Private">Private (User)</option>
                            <option value="Public">Public (Company)</option>
                        </select>
                    </div>

                    {/* Notes */}
                    <div className="mt-4">
                        <label htmlFor="createNote" className="block text-sm font-bold mb-2">Notes</label>
                        <textarea
                            id="createNote"
                            className="shadow-md appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            cols="30"
                            rows="5"
                            placeholder="Notes..."
                            style={{ resize: 'vertical' }}
                            value={noteContent}
                            onChange={(e) => setNoteContent(e.target.value)}
                        ></textarea>
                    </div>

                    {successMessage && (
                        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mt-4" role="alert">
                            <span className="block sm:inline">{successMessage}</span>
                            <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={() => setSuccessMessage('')}>
                                <svg className="fill-current h-6 w-6 text-green-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"></path></svg>
                            </span>
                        </div>
                    )}

                    <div className="mt-4 flex justify-between items-center">
                        <label className="inline-flex items-center">
                            <input
                                type="checkbox"
                                className="form-checkbox text-indigo-600"
                                checked={isTemporary}
                                onChange={() => setIsTemporary(!isTemporary)}
                            />
                            <span className="ml-2">Temporary Note</span>
                        </label>

                        <div>
                            <button className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-4 rounded mr-2" onClick={saveNote}>
                                <i className="fas fa-save mr-1"></i> Save Note
                            </button>
                            <button className="bg-gray-300 hover:bg-gray-400 text-black py-1 px-4 rounded" onClick={toggleModal}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateNotesModal;
