import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector } from "react-redux";
import { Navigate } from 'react-router-dom';
import ViewType from '@jbrowse/core/pluggableElementTypes/ViewType'
import Plugin from '@jbrowse/core/Plugin'
import PluginManager from '@jbrowse/core/PluginManager'
import { isAbstractMenuManager } from "@jbrowse/core/util"
import InfoIcon from "@mui/icons-material/Info"
import createTracks from "./createTracks";
import createDefaultSession from './createDefaultSession';
import { createViewState, loadPlugins, JBrowseLinearGenomeView } from '@jbrowse/react-linear-genome-view'
import AccessVariantsModal from "./AccessVariantsModal";
import FeatureTableModal from './FeatureTableModal';
import HaploviewerModal from './HaploviewerModal';
import JBrowseService from '../services/jbrowse.service';
import BlastModal from './BlastModal';
import FireplaceIcon from '@mui/icons-material/Fireplace';
import SequenceRetrievalModal from './SequenceRetrievalModal';
import SampleSequenceModal from './SampleSequenceModal';
import NotesModal from './NotesModal';
import CreateNotesModal from './CreateNotesModal';
import BatchNotesModal from './BatchNotesModal';
import EditNotesModal from './EditNotesModal';
import OpenAiModal from './OpenAiModal';
import AiFeatureModal from './AiFeatureModal';
import LiteratureModal from './LiteratureModal';
import EditSessionModal from './EditSessionModal';
import ConversationModal from './ConversationModal';
import AlignmentModal from './AlignmentModal';
import ToolsModal from './ToolsModal';
import { motion, AnimatePresence } from 'framer-motion';
import EventBus from "../common/EventBus";
import _ from 'underscore';
import axios from 'axios';
import dayjs from 'dayjs';
import VisibilityIcon from '@mui/icons-material/Visibility';
import NoteIcon from '@mui/icons-material/Note';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteModal from './DeleteModal';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from "@mui/icons-material/Add"
import BlurCircularIcon from '@mui/icons-material/BlurCircular';
import CommentIcon from '@mui/icons-material/Comment';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import HandymanIcon from '@mui/icons-material/Handyman';
import { getParent } from 'mobx-state-tree';
import { toast } from 'react-toastify';

// Chat Bot
import { Widget, addResponseMessage, toggleWidget } from 'react-chat-widget';
import '../ChatBot.css';

import URL from '../common/GetUrl';
var url = URL();
const API_URL = url;

export const SavedSession = () => {
    const [selectedCrop, setSelectedCrop] = useState("");
    const [selectedAssembly, setSelectedAssembly] = useState("");
    const [selectedTracks, setSelectedTracks] = useState([]);
    const [availableAssemblies, setAvailableAssemblies] = useState([]);
    const [availableTracks, setAvailableTracks] = useState([]);
    const [projects, setProjects] = useState([]);
    const [crops, setCrops] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [currentAssembly, setCurrentAssembly] = useState(null);
    const [currentTracks, setCurrentTracks] = useState(null);
    const [currentSession, setCurrentSession] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null); // Selected project
    const [files, setFiles] = useState([]); // Files associated with selected project
    const [selectedFiles, setSelectedFiles] = useState([]); // User selected files
    const [bamError, setBamError] = useState(false); // Error state
    const [isWizardVisible, setIsWizardVisible] = useState(true);
    const [chromosomes, setChromosomes] = useState([]);
    const [currentChromosome, setCurrentChromosome] = useState("");
    const [selectedVariants, setSelectedVariants] = useState([]);
    const [accessVariantIsOpen, setAccessVariantIsOpen] = useState(false);
    const [blastModalIsOpen, setBlastModalIsOpen] = useState(false);
    const [blastData, setBlastData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [aiLoading, setAiLoading] = useState(false);
    const [blastLoading, setBlastLoading] = useState(false);
    const [sampleNames, setSampleNames] = useState([]);
    const [notesIsOpen, setNotesIsOpen] = useState(false);
    const [notes, setNotes] = useState([]);
    const [vcfModalIsOpen, setVcfModalIsOpen] = useState(false);
    const [selectedVcfSamples, setSelectedVcfSamples] = useState([]);
    const [currentImage, setCurrentImage] = useState("");
    let [state, setState] = useState(null);
    let [windowWidth, setWindowWidth] = useState(window.innerWidth * 0.98);
    let [location, setLocation] = useState("");
    let [sequence, setSequence] = useState("");
    let [gffs, setGffs] = useState([]);
    let [currentNote, setCurrentNote] = useState({});
    const [blastParagraph, setBlastParagraph] = useState("");
    const [featureModalIsOpen, setFeatureModalIsOpen] = useState(false);
    const [haploModalIsOpen, setHaploModalIsOpen] = useState(false);
    const [sequenceModalIsOpen, setSequenceModalIsOpen] = useState(false);
    const [samplesSequenceIsOpen, setSampleSequenceIsOpen] = useState(false);
    const [createNotesModalIsOpen, setCreateNotesModalIsOpen] = useState(false);
    const [batchNotesModalIsOpen, setBatchNotesModalIsOpen] = useState(false)
    const [openAiModalIsOpen, setOpenAiModalIsOpen] = useState(false);
    const [editNotesModalIsOpen, setEditNotesModalIsOpen] = useState(false);
    const [aiFeatureModalIsOpen, setAiFeatureModalIsOpen] = useState(false);
    const [aiResponse, setAiResponse] = useState("");
    const [haploData, setHaploData] = useState({
        textArray: [],
        x: [],
        y: [],
        z: []
    });
    const selectedTracksRef = useRef();
    const [conversation, setConversation] = useState([]);
    const [literatureModalIsOpen, setLiteratureModalIsOpen] = useState(false);
    const [literatureSearch, setLiteratureSearch] = useState([]);
    const [literatureSearchError, setLiteratureSearchError] = useState(false);
    const [showLiteratureSearch, setShowLiteratureSearch] = useState(false);
    const [toggleLiteratureSearch, setToggleLiteratureSearch] = useState(false);
    const [isWidgetOpen, setIsWidgetOpen] = useState(false);

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [currentSessions, setCurrentSessions] = useState([]);
    const [selectedSession, setSelectedSession] = useState({});
    // Filiter Sessions
    const [searchTerm, setSearchTerm] = useState(''); // For filtering
    const [currentPage, setCurrentPage] = useState(1); // Pagination
    const itemsPerPage = 10; // Number of items per page
    const filteredSessions = currentSessions.filter(session =>
        session.session.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredSessions.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(currentSessions.length / itemsPerPage);
    const [literaturePrompt, setLiteraturePrompt] = useState("");
    const [showEditModal, setShowEditModal ] = useState(false);
    const [errorEditMessage, setErrorEditMessage] = useState(false);
    const [successEditMessage, setSuccessEditMessage] = useState(false);
    const [isConversionModalOpen, setIsConversationModalOpen ] = useState(false);
    const [htmlBlob, setHtmlBlob ] = useState("");
    const [showAlignmentModal, setShowAlignmentModal ] = useState( false );
    const [sampleCodeError, setSampleCodeError  ] = useState(false);
    const [bamFileError, setBamFileError ] = useState( false );
    const [ showToolsModal, setShowToolsModal ] = useState( false );

    // Chat Bot Logo
    const logo = require('../img/logo_old.png');

    const { user: currentUser } = useSelector((state) => state.auth);
    // console.log("current user: ", currentUser );

    class HighlightRegionPlugin extends Plugin {
        name = "HighlightRegionPlugin"

        install(pluginManager) {

            pluginManager.addToExtensionPoint(
                "Core-extendPluggableElement",
                pluggableElement => {
                    if (pluggableElement.name === "LinearGenomeView") {
                        const { stateModel } = pluggableElement
                        const newStateModel = stateModel.extend(self => {
                            const superRubberBandMenuItems = self.rubberBandMenuItems
                            return {
                                views: {
                                    rubberBandMenuItems() {
                                        return [
                                            ...superRubberBandMenuItems(),
                                            {
                                                label: "Access Variants",
                                                icon: SearchIcon,
                                                onClick: () => {
                                                    const { leftOffset, rightOffset } = self
                                                    const selectedRegions = self.getSelectedRegions(
                                                        leftOffset,
                                                        rightOffset
                                                    )
                                                    // console log the list of potentially multiple
                                                    // regions that were selected
                                                    setLoading(true);
                                                    console.log("tracks: ", selectedTracksRef.current);
                                                    const vcfFiles = selectedTracksRef.current.filter(file => file.endsWith('.vcf'));
                                                    console.log("vcf files: ", vcfFiles);

                                                    // const variantTracks = _.filter( tracks, obj => obj.type === 'VariantTrack');
                                                    // console.log("variant tracks: ", variantTracks);

                                                    var location = selectedRegions[0].refName + ":" + selectedRegions[0].start + "-" + selectedRegions[0].end;
                                                    // console.log("location: ", location );
                                                    setLocation(location);

                                                    var obj = {
                                                        starting: selectedRegions[0].start,
                                                        ending: selectedRegions[0].end,
                                                        chromosome: selectedRegions[0].refName,
                                                        vcfs: vcfFiles,
                                                        user: currentUser.email,
                                                        company: currentUser.DirectoryName
                                                    };

                                                    console.log('obj: ', obj);

                                                    // Send the obj to the backend using axios
                                                    JBrowseService.getVariants(obj).then(
                                                        (response) => {
                                                            console.log("varaints response: ", response);
                                                            // If no Variants were found...
                                                            if (isBlank(response.result)) {
                                                                console.log("show warning");
                                                                // alert("no variants found.");
                                                                toast.info("no variants found.");
                                                                setLoading(false);
                                                                return;
                                                            }

                                                            var variants = response.result.split(',');

                                                            // Get Chromosomes
                                                            var obj = {
                                                                company: currentUser.DirectoryName,
                                                                assembly: currentAssembly.name+".fa",
                                                            };
                                                            // console.log("obj: ", obj );
                                                            // Send Request
                                                            JBrowseService.getChromosomes( obj ).then( 
                                                                ( response )=> {
                                                                    // console.log("chromosome response: ", response );
                                                                    var arr = parsePythonList(response.result);
                                                                    // console.log("arr: ", arr );
                                                                    setChromosomes( arr );
                                                                    setLoading( false );
                                                                    // console.log("variants: ", variants );
                                                                    setSelectedVariants(variants);
                                                                    setAccessVariantIsOpen(true)
                                                                    setLoading(false);
                                                                },
                                                                ( error ) => {
                                                                    console.log("error: ", error );
                                                                    setLoading( false );
                                                                }
                                                            )

                                                        },
                                                        (error) => {
                                                            console.log("error: ", error);
                                                            if (error.code === "ERR_NETWORK") {
                                                                console.warn("logout!!!");
                                                                EventBus.dispatch("logout");
                                                            }
                                                        }
                                                    )

                                                }
                                            },
                                            {
                                                label: "Blast Region",
                                                icon: BlurCircularIcon,
                                                onClick: () => {
                                                    const { leftOffset, rightOffset } = self
                                                    const selectedRegions = self.getSelectedRegions(
                                                        leftOffset,
                                                        rightOffset
                                                    )
                                                    // console log the list of potentially multiple
                                                    // regions that were selected
                                                    // console.log("region: ", selectedRegions);
                                                    setLoading(true);
                                                    setBlastLoading(true);
                                                    setBlastParagraph("");
                                                    var obj = {
                                                        starting: selectedRegions[0].start,
                                                        ending: selectedRegions[0].end,
                                                        chromosome: selectedRegions[0].refName,
                                                        genome: currentAssembly.name + ".fa",
                                                        crop: selectedCrop,
                                                        user: currentUser.username,
                                                        company: currentUser.DirectoryName
                                                    };
                                                    console.log("obj: ", obj);

                                                    async function handleBlastRequest() {
                                                        try {
                                                            const response = await JBrowseService.blastRegion(obj);
                                                            console.log("blast response: ", response);

                                                            // console.log("tracks before: ", selectedTracksRef.current );
                                                            const filteredTracks = selectedTracksRef.current.filter(item => !item.includes("BlastJob"));

                                                            /** Add Blasts Tracks */
                                                            var tracks = [...new Set([...filteredTracks, ...response.files])];
                                                            setSelectedTracks(tracks);
                                                            // Since createTracks is async, we await its result
                                                            var newTracks = await createTracks(currentAssembly.name + ".fa", tracks);

                                                            // console.log("tracks after: ", tracks );

                                                            // Clean Up, old BlastGff files will mess this up
                                                            const cleanTracks = newTracks.filter(item => item !== undefined);
                                                            var trackArray = _.pluck(cleanTracks, 'name');
                                                            setCurrentTracks(newTracks);

                                                            const newSession = createDefaultSession(currentAssembly.name + ".fa", trackArray);
                                                            setCurrentSession(newSession);

                                                            // console.log("clean tracks: ", cleanTracks );
                                                            // Introducing a slight delay to ensure all updates are processed
                                                            setTimeout(() => {
                                                                updateTracks(cleanTracks, newSession);
                                                                setBlastData(response.result);
                                                                setBlastModalIsOpen(true);
                                                                setLoading(false);
                                                            }, 250);
                                                        } catch (error) {
                                                            console.log('blast error: ', error);
                                                            setLoading(false);
                                                            if (error.code === "ERR_NETWORK") {
                                                                console.warn("logout!!!");
                                                                EventBus.dispatch("logout");
                                                            }
                                                        }
                                                    }

                                                    // To invoke the function:
                                                    handleBlastRequest();


                                                }
                                            },
                                            {
                                                label: "Create Note",
                                                icon: CommentIcon,
                                                onClick: () => {
                                                    const { leftOffset, rightOffset } = self
                                                    const selectedRegions = self.getSelectedRegions(
                                                        leftOffset,
                                                        rightOffset
                                                    )
                                                    // console log the list of potentially multiple
                                                    // regions that were selected
                                                    console.log("region: ", selectedRegions);
                                                    var location = selectedRegions[0].refName + ":" + selectedRegions[0].start + "-" + selectedRegions[0].end;
                                                    // console.log("location: ", location );
                                                    setLocation(location);
                                                    setCreateNotesModalIsOpen(true);
                                                }
                                            }
                                        ]
                                    }
                                }
                            }
                        })

                        pluggableElement.stateModel = newStateModel
                    }
                    return pluggableElement
                }
            )
        }

        configure() { }
    }

    class VariantFeaturePlugin extends Plugin {
        name = "VariantFeaturePlugin";

        install(pluginManager) {
            pluginManager.addToExtensionPoint(
                "Core-extendPluggableElement",
                pluggableElement => {
                    // console.log("pluggable el: ", pluggableElement.name );
                    if (pluggableElement.name === "LinearVariantDisplay") {
                        const { stateModel } = pluggableElement;
                        const newStateModel = stateModel.extend(self => {
                            const superContextMenuItems = self.contextMenuItems;
                            return {
                                views: {
                                    contextMenuItems() {
                                        const feature = self.contextMenuFeature;
                                        if (!feature) {
                                            return superContextMenuItems();
                                        }
                                        const newMenuItems = [
                                            ...superContextMenuItems(),
                                            {
                                                label: "Haplotype Score",
                                                icon: SportsScoreIcon,
                                                onClick: () => {
                                                    // Get Track Name
                                                    let file_long = self.$treenode._initialSnapshot.configuration;
                                                    let file = file_long.replace(/-LinearVariantDisplay$/, '');
                                                    let track = _.filter(currentTracks, x => x.trackId === file);
                                                    // console.log('track: ', track[0].trackId );
                                                    let loc = feature.data.refName + ":" + feature.data.start + "-" + feature.data.end;
                                                    // console.log("location: ", loc);
                                                    const vcfFiles = selectedTracksRef.current.filter(file => file.endsWith('.vcf'));
                                                    const gffFiles = selectedTracksRef.current.filter(file => file.endsWith('.gff'));
                                                    // Format GFF's for Bams
                                                    const cleanGffs = removeFilesWithSuffix( gffFiles, "notes.gff")
                                                    const removeSuffixGffs = removeFileExtensions( cleanGffs );
                                                    const bamGffs = extractNameBeforeUnderscore( removeSuffixGffs );

                                                    let obj = {
                                                        vcf: vcfFiles[0],
                                                        loc: loc,
                                                        assembly: currentAssembly.name+".fa",
                                                        gffs: removeFileExtensions( cleanGffs ),
                                                        company: currentUser.DirectoryName,
                                                        user: currentUser.email,
                                                    };
                                                    console.log("obj: ", obj);
                                                    // return;
                                                  
                                                    // Send request
                                                    JBrowseService.getHaplotypeScore(obj).then(
                                                        (response) => {
                                                            console.log("haploScore response: ", response);
                                                        },
                                                        (error) => {
                                                            console.log("error: ", error);
                                                        }
                                                    )
                                                }
                                            },
                                            {
                                                label: "Create Note",
                                                icon: CommentIcon,
                                                onClick: () => {
                                                    let loc = feature.data.refName + ":" + feature.data.start + "-" + feature.data.end;
                                                    console.log("location: ", loc);
                                                    setLocation(loc);
                                                    setCreateNotesModalIsOpen(true);
                                                }
                                            },
                                            {
                                                label: "AI Analysis",
                                                icon: PersonSearchIcon,
                                                onClick: () => {
                                                    setLoading(true);
                                                    console.log("feature: ", feature);
                                                    let obj = {
                                                        user: currentUser.email,
                                                        feature: JSON.stringify(Object.assign({}, feature.data, feature.variant))
                                                    };
                                                    // console.log("obj: ", JSON.stringify(obj.feature) );
                                                    // return;
                                                    // Send Request
                                                    JBrowseService.aiFeaturePrompt(obj).then(
                                                        (response) => {
                                                            console.log('ai response: ', response);
                                                            setAiResponse(response.result);
                                                            setAiFeatureModalIsOpen(true);
                                                            setLoading(false)
                                                            setShowLiteratureSearch(true);
                                                            setToggleLiteratureSearch(false);
                                                        },
                                                        (error) => {
                                                            console.log("ai error: ", error);
                                                            setLoading(false)
                                                        }
                                                    )
                                                }
                                            }
                                        ];

                                        return newMenuItems;
                                    }
                                }
                            };
                        });

                        pluggableElement.stateModel = newStateModel;
                    }
                    return pluggableElement;
                }
            );
        }

        configure() { }
    }

    class GffFeaturePlugin extends Plugin {
        name = "GffFeaturePlugin";

        install(pluginManager) {
            pluginManager.addToExtensionPoint(
                "Core-extendPluggableElement",
                pluggableElement => {
                    // console.log("pluggable el: ", pluggableElement.name );
                    if (pluggableElement.name === "LinearBasicDisplay") {
                        const { stateModel } = pluggableElement;
                        const newStateModel = stateModel.extend(self => {
                            const superContextMenuItems = self.contextMenuItems;
                            return {
                                views: {
                                    contextMenuItems() {
                                        const feature = self.contextMenuFeature;

                                        const parentView = getParent(self); // Getting the parent view

                                        if (!feature) {
                                            return superContextMenuItems();
                                        }
                                        const newMenuItems = [
                                            ...superContextMenuItems(),
                                            // {
                                            //     label: "View Alignment Track",
                                            //     icon: VisibilityIcon,
                                            //     onClick: () => {
                                            //         console.log("got here: ", feature );

                                            //     }
                                            // },
                                            {
                                                label: "Alignment Viewer",
                                                icon: VisibilityIcon,
                                                onClick: () => {
                                                    console.log("Feature: ", feature);
                                                    setLoading(true)
                                                    // console.log("Track: ", track );
                                                    console.log("selectedAssembly: ", selectedAssembly )
                                                    // Get Track Name
                                                    let file_long = self.$treenode._initialSnapshot.configuration;
                                                    let track = file_long.replace(/-LinearBasicDisplay$/, '');
                                                    let fixed_track = track.replace(/_\d+$/, '');
                                                    // console.log("track: ", track );
                                                  
                                                    let Location = feature.data.refName+":"+feature.data.start+"-"+feature.data.end;

                                                    const vcfFiles = selectedTracks.filter(file => file.endsWith('.vcf'));
                                                    var obj = {
                                                        user: currentUser.email,
                                                        company: currentUser.DirectoryName,
                                                        assembly: currentAssembly.name,
                                                        vcf: vcfFiles[0],
                                                        location: Location,
                                                        externalID: fixed_track,
                                                    };
                                                    console.log("obj: ", obj );
                                                    // Send Request
                                                    JBrowseService.alignmentViewer( obj ).then(
                                                        (response) => {
                                                            console.log("av response: ", response );
                                                            switch (response.status) {
                                                                case 1:
                                                                    console.log('SampleCode Error.');
                                                                    setSampleCodeError( true );
                                                                    setLoading( false )
                                                                    setTimeout( () => {
                                                                        setSampleCodeError( false );
                                                                    },5000);
                                                                    break;
                                                                case 2:
                                                                    console.log('Bam file Error.')
                                                                    setBamFileError( true );
                                                                    setLoading( false )
                                                                    setTimeout( () => {
                                                                        setBamFileError( false );
                                                                    },5000);
                                                                    break
                                                                case 0:
                                                                    console.log('Success.');
                                                                    setHtmlBlob( response.result );
                                                                    setShowAlignmentModal( true );
                                                                    setLoading( false )
                                                                    break;
                                                            }
                                                        }, 
                                                        ( error ) => {
                                                            console.log("error: ", error );
                                                            setLoading(false)
                                                        }
                                                    )

                                                }
                                            },
                                            {
                                                label: "Create Note",
                                                icon: CommentIcon,
                                                onClick: () => {
                                                    let loc = feature.data.refName + ":" + feature.data.start + "-" + feature.data.end;
                                                    console.log("location: ", loc);
                                                    setLocation(loc);
                                                    setCreateNotesModalIsOpen(true);
                                                }
                                            },
                                            {
                                                label: "AI Analysis",
                                                icon: PersonSearchIcon,
                                                onClick: () => {
                                                    setLoading(true);
                                                    console.log("feature: ", feature);
                                                    // console.log("feature: ", feature );
                                                    // let goTerms = feature.data.subfeatures[0].ontology_term;
                                                    // console.log("goTerms: ", goTerms );
                                                    // let Location = feature.data.refName+":"+feature.data.start+"-"+feature.data.end;
                                                    // const gffFiles = selectedTracks.filter(file => file.endsWith('.gff'));
                                                    // const gff3Files = selectedTracks.filter(file => file.endsWith('.gff3'));
                                                    // const gffs = gff3Files.concat( gffFiles );

                                                    // var obj = {
                                                    //     location: Location,
                                                    //     crop: selectedCrop,
                                                    //     assembly: selectedAssembly,
                                                    //     goTerms: goTerms || [],
                                                    //     gff: gffs[0],
                                                    //     user: currentUser.email,
                                                    //     company: currentUser.DirectoryName
                                                    // }
                                                    // console.log('obj: ', obj );
                                                    // console.log("send prompt..");
                                                    // // Send Request
                                                    // JBrowseService.gffClickPrompt( obj ).then(
                                                    //     (response) => {
                                                    //         console.log('openAi response: ', response );
                                                    //         setLoading(false);
                                            
                                                    //         toggleWidget();
                                                    //         let prompt = "Describe the primary biological function of a gene using the provided list of Gene Ontology (GO) terms for a specific crop: "+selectedCrop+" and assembly:"+selectedAssembly+". Focus on detailing the specific interactions and roles of the gene based on these GO terms. The description should be concise and strictly adhere to the functions indicated by the GO terms without implying the gene's importance or broader biological significance. Use only the most relevant GO terms and discard terms that are generic, ambiguous, or not relevant to the primary biological function. Your analysis should leverage the depth of information in the Go Terms to provide advanced interpretations suitable for an audience well-versed in bioinformatics. The description should be no longer than one short paragraph. The GO terms are: "+response.goTerms
                                                    //         let gff_prmopt = "Given these lines from a gff file from crop: Tomato, and Assembley: S_lycopersicum_chromosomes.3.00 go through the 'Attributes' tab and find interesting facts about this region. Your Response should refraim from using generic bioinformatic terms and instead tailor your insights to an advanced scientific audience with a solid understanding of bioinformatics, eschewing basic explanations in favor of more profound, nuanced interpretations that demonstrate a deep comprehension of the subject matter. gff lines: { "+response.gffs+" }"
                                                    //         let convo = [
                                                    //             {
                                                    //                 role: "system",
                                                    //                 content: "You're role is a bioinformatics specalist and you task is to give detail about the agricultural data provided, be precise and concise."
                                                    //             },
                                                    //             {
                                                    //                 role: 'user',
                                                    //                 content: prompt+"\n"+gff_prmopt
                                                    //             },
                                                    //             {
                                                    //                 role: "assistant",
                                                    //                 content: response.result
                                                    //             }
                                                    //         ];
                                                    //         setConversation( convo );
                                                    //         addResponseMessage(response.result)
                                                    //         setLoading(false);
                                                    //         setAccessVariantIsOpen( false );
                                                    //         setShowLiteratureSearch( true );
                                                    //         setToggleLiteratureSearch( false );
                                                    //     },
                                                    //     (error) => {
                                                    //         console.log("error: ", error );
                                                    //         console.log("message: ", error.response.data );
                                                    //         addResponseMessage("openai error, try again later.");
                                                    //         setTimeout(()=> {
                                                    //             addResponseMessage("");
                                                    //         },3000);
                                                    //         setLoading(false);
                                                    //     }
                                                    // )
                                                

                                                    const gff3Files = selectedTracks.filter(file => file.endsWith('.gff3'));

                                                    let obj = {
                                                        user: currentUser.email,
                                                        company: currentUser.DirectoryName,
                                                        gff: gff3Files[0],
                                                        crop: selectedCrop,
                                                        chromosome: feature.data.refName,
                                                        start: feature.data.start,
                                                        stop: feature.data.end,
                                                    };
                                                    console.log('obj: ', obj );
                                                    // return;

                                                    // Chat Bot  -- Select the button after the component and widget have mounted
                                                    let response = `Welcome to the Verinomics GFF GPT!`
                                                    addResponseMessage(response);
                                                    // Send Request
                                                    JBrowseService.aiFeaturePrompt(obj).then(
                                                        (response) => {
                                                            console.log('ai response: ', response);
                                                            // Open ChatBot
                                                            toggleWidget();
                                                            setIsWidgetOpen(true);

                                                            // Set Conversation
                                                            let convo = [
                                                                {
                                                                    role: "system",
                                                                    content: "Be precise and concise."
                                                                },
                                                                {
                                                                    role: 'user',
                                                                    content: response.result.prompt
                                                                }
                                                            ];
                                                            setConversation(convo);

                                                            // // Send open.ai Response
                                                            // const aiMessageContent = response.result.conversation_response[0].openai_response;
                                                            const aiMessageContent = response.result.openAi;
                                                            addResponseMessage(aiMessageContent);

                                                            // Update Conversation 
                                                            let newMessage = {
                                                                role: 'assistant',
                                                                content: aiMessageContent
                                                            }
                                                            setConversation(currentConversation => [...currentConversation, newMessage]);
                                                            console.log("converstaion: ", conversation);

                                                            setLoading(false)

                                                        },
                                                        (error) => {
                                                            console.log("ai error: ", error);
                                                            setLoading(false)
                                                        }
                                                    )
                                                }

                                            }
                                        ];

                                        return newMenuItems;
                                    }
                                }
                            };
                        });

                        pluggableElement.stateModel = newStateModel;
                    }
                    return pluggableElement;
                }
            );
        }

        configure() { }
    }

    class ColorPlugin {
        install() { }
        configure(pluginManager) {
            pluginManager.jexl.addFunction('colorFeature', ( feature ) => {
                // console.log("feature: ", feature );
                // console.log("feature: ",JSON.stringify(feature, null, 2));
                let type = feature.get('type').trim();
                // console.log("type: ", type);
                switch (type) {
                    case 'NonReference':
                        return 'blue';
                    case 'Reference':
                        return 'green';
                    case 'Heterozygous':
                        return '#ffd966';
                    case 'Missing':
                        return 'grey';
                    default:
                        return '#ff6b6b'; // Default color for types not listed ( coral )
                }
            });
        }
    }

    const navigateToLocation = useCallback((location) => {
        if (!state) return;

        const s = createViewState({
            assembly: currentAssembly,
            tracks: currentTracks,
            plugins: [HighlightRegionPlugin, VariantFeaturePlugin, GffFeaturePlugin, ColorPlugin],
            configuration: {
                logoPath: {
                    uri: "img/logo.svg"
                },
                theme: {
                    palette: {
                        primary: {
                            main: "#4a90e2", // A crisp, engaging blue for primary actions and emphasis
                        },
                        secondary: {
                            main: "#0f4d92", // A light purple for secondary actions and highlights
                        },
                        tertiary: {
                            main: "#7b8dff", // A soft, approachable violet for tertiary accents and features
                        },
                        quaternary: {
                            main: "#ff6b6b", // A bright, energetic yellow for attention-grabbing elements and alerts
                        }
                    }
                },
                hierarchical: {
                    sort: {
                        trackNames: true,
                        categories: true
                      },
                      defaultCollapsed: {
                        categoryNames: ["FeatureTrack","VariantTrack" ], // only collapse some categories on initial startup
                        topLevelCategories: true, // collapse all top level categories on initial startup
                        subCategories: true // collapse all subcategories on initial startup
                      }
                }
            },
            location: location,
            defaultSession: currentSession
        })
        // console.log("state: ", s);

        // Set State
        setState(s);
    }, [state, currentAssembly, currentTracks, currentSession]); // Add other dependencies if needed

    const updateTracks = useCallback((newTracks, newSession) => {
        // if (!state) {
        //     console.warn("State is not initialized yet.");
        //     return;
        // }
        // Init
        // setState(null);
        console.warn("update tracks!");
        console.log("updating new tracks: ", newTracks);
        // console.log("chromosome: ", chromosome + ":1..100,000");

        const s = createViewState({
            assembly: currentAssembly,
            tracks: newTracks, // Use the new tracks passed to the function
            plugins: [HighlightRegionPlugin, VariantFeaturePlugin, GffFeaturePlugin, ColorPlugin],
            configuration: {
                logoPath: {
                    uri: "img/logo.svg"
                },
                theme: {
                    palette: {
                        primary: {
                            main: "#4a90e2", // A crisp, engaging blue for primary actions and emphasis
                        },
                        secondary: {
                            main: "#0f4d92", // A light purple for secondary actions and highlights
                        },
                        tertiary: {
                            main: "#7b8dff", // A soft, approachable violet for tertiary accents and features
                        },
                        quaternary: {
                            main: "#ff6b6b", // A bright, energetic yellow for attention-grabbing elements and alerts
                        }
                    }
                },
                hierarchical: {
                    sort: {
                        trackNames: true,
                        categories: true
                      },
                      defaultCollapsed: {
                        categoryNames: ["FeatureTrack","VariantTrack" ], // only collapse some categories on initial startup
                        topLevelCategories: true, // collapse all top level categories on initial startup
                        subCategories: true // collapse all subcategories on initial startup
                      }
                }
            },
            location: location,
            defaultSession: newSession
        });

        console.log("Updated state: ", s);

        // Set State
        setState(s);
    }, [currentAssembly]); // Added useCallback for more controlled updates

    useEffect(() => {
        if (currentTracks && currentAssembly && currentSession) {
            console.log("currentTracks: ", currentTracks);
            // console.log("currentAssembly: ", currentAssembly);
            // console.log("currentSession: ", currentSession);
            // console.log("location: ", location);
            // console.log("session: ", JSON.stringify(session) );
            const run = async () => {
                // const plugins = await loadPlugins([
                //     {
                //         name: "GDC",
                //         url: "https://unpkg.com/jbrowse-plugin-gdc/dist/jbrowse-plugin-gdc.umd.production.min.js"
                //     },
                //     {
                //         name: "Quantseq",
                //         url: "https://unpkg.com/jbrowse-plugin-quantseq/dist/jbrowse-plugin-seq.umd.production.min.js"
                //     }
                // ])

                const s = createViewState({
                    assembly: currentAssembly,
                    tracks: currentTracks,
                    // plugins: pluginManager,
                    plugins: [HighlightRegionPlugin, VariantFeaturePlugin, GffFeaturePlugin, ColorPlugin, ],
                    configuration: {
                        logoPath: {
                            uri: "img/logo.svg"
                        },
                        theme: {
                            palette: {
                                primary: {
                                    main: "#4a90e2", // A crisp, engaging blue for primary actions and emphasis
                                },
                                secondary: {
                                    main: "#0f4d92", // A light purple for secondary actions and highlights
                                },
                                tertiary: {
                                    main: "#7b8dff", // A soft, approachable violet for tertiary accents and features
                                },
                                quaternary: {
                                    main: "#ff6b6b", // A bright, energetic yellow for attention-grabbing elements and alerts
                                }
                            }
                        },
                        hierarchical: {
                            sort: {
                                trackNames: true,
                                categories: true
                            },
                            defaultCollapsed: {
                                categoryNames: ["VCF"], // only collapse some categories on initial startup
                                topLevelCategories: true, // collapse all top level categories on initial startup
                                subCategories: true // collapse all subcategories on initial startup
                            }
                        }
                    },
                    location: location,
                    defaultSession: currentSession
                })
                console.log("state: ", s);

                // Set State
                setState(s);
            }

            run();

            setTimeout(() => {
                setLoading(false);
                // Set After build success..
                setSelectedAssembly(currentAssembly.name + ".fa");
            }, 1000);

        }
    }, [currentTracks, currentAssembly, currentSession]);

    useEffect(() => {
        if (selectedTracks) {
            selectedTracksRef.current = selectedTracks;
            // console.log("got here w/ sel tracks: ", selectedTracks );

            // console.log('selected tracks: ', selectedTracks );
            const gffFiles = selectedTracks.filter(file => file.endsWith('.gff'));
            const gff3Files = selectedTracks.filter(file => file.endsWith('.gff3'));
            const gffs = gffFiles.concat(gff3Files);
            var formatted = _.map(gffs, value => {
                return {
                    fileName: value
                };
            });
            // console.log("f gffs: ", formatted );
            setGffs(formatted);
        }
    }, [selectedTracks])

    useEffect(() => {
        if (notes.length > 0) {
            // console.log("selectedAssem: ", currentAssembly.name+".fa" );
            const file = process.env.PUBLIC_URL + "/JBrowseNotes/Genomes/" + currentAssembly.name + ".fa/" + currentUser.DirectoryName + "/" + currentUser.DirectoryName + ".notes.gff";
            console.log("file: ", file);

            const checkAndAddNotesFile = async (file) => {
                try {
                    await axios.get(API_URL + file, {
                        maxContentLength: 100,
                        headers: {
                            'Cache-Control': 'no-cache', // Optionally add this line to set cache-control headers
                            'Pragma': 'no-cache' // For older HTTP/1.0 proxies and servers
                        }
                    });
                    // console.log("check selected tracks: ", selectedTracks );
                    // Remove the leading slash from `file` if it exists
                    const normalizedFile = file.startsWith('/') ? file.substring(1) : file;
                    console.log("Notes File exists: ", normalizedFile);

                    if (!selectedTracks.includes(normalizedFile)) {
                        const tracks = selectedTracks.concat(normalizedFile);
                        // console.log("selected tracks, from notes: ", tracks);
                        setSelectedTracks(tracks);

                        const myTracks = await createTracks(currentAssembly.name + ".fa", tracks);
                        const cleanTracks = myTracks.filter(item => item !== undefined);
                        const trackArray = _.pluck(cleanTracks, 'name');
                        setCurrentTracks(cleanTracks);

                        const newSession = createDefaultSession(currentAssembly.name + ".fa", trackArray);
                        setCurrentSession(newSession);

                        setTimeout(() => {
                            updateTracks(cleanTracks, newSession);
                            setLoading(false);
                        }, 100);
                    } else {
                        console.log("do nothing!");
                    }
                } catch (error) {
                    console.log("notes error: ", error);
                    if (error.response && error.response.status === 404) {
                        console.log("Notes file does not exist.");
                    } else {
                        console.log("An error occurred while fetching notes file.");
                    }
                }
            };

            checkAndAddNotesFile(file);
        }
    }, [notes]);

    useEffect(() => {
        // Get Session
        if (currentUser) {
            setLoading(true);
            // Get Sessions
            JBrowseService.getSavedSessions({ user: currentUser }).then(
                (response) => {
                    console.log("saved session response: ", response);
                    setCurrentSessions(response.result.reverse());
                    setLoading(false);
                },
                (error) => {
                    console.log("error: ", error);
                    setLoading(false);
                    if (error.code === "ERR_NETWORK" || error.code === "ERR_BAD_REQUEST") {
                        console.warn("logout!!!");
                        EventBus.dispatch("logout");
                    }
                }
            )
        }
    }, [currentUser]);

    if (!currentUser) {
        return <Navigate to="/" />;
    }

    const removeTrack = (track, listName) => {
        if (listName === 'selectedTracks') {
            setSelectedTracks(prevTracks => prevTracks.filter(t => t !== track));
        } else if (listName === 'selectedFiles') {
            setSelectedFiles(prevFiles => prevFiles.filter(f => f !== track));
        }
    };

    const closeModal = () => {
        setIsOpen(false);
        setSelectedProject(null);
    }

    const openModal = () => {
        setIsOpen(true);
        JBrowseService.getProjects({ id: 1 }).then(
            (response) => {
                // console.log("response: ", response);
                setProjects(response);
            },
            (error) => {
                console.log("error: ", error);
            }
        )
    }

    const toggleWizard = () => {
        setIsWizardVisible(!isWizardVisible);
    };

    const toggleVariants = () => {
        // console.log("got here: ", accessVariantIsOpen );
        // setLoading(true);
        setAccessVariantIsOpen( true );
    }

    const toggleNotes = () => {
        setNotesIsOpen(true);
    }

    const toggleBlast = () => {
        setBlastModalIsOpen(true);
    }

    const toggleSessionsList = () => {
        setState(null);
        setNotes([]);
        setCurrentAssembly("");
        setCurrentTracks("");
        setCurrentSession("");
    }

    const rebuildSession = (session) => {
        console.log("rebuild: ", session);
        setLoading(true);
        let config = session;
        console.log("config: ", config);
        setCurrentAssembly(config.assembly);
        setCurrentTracks(config.tracks);
        setCurrentSession(config.session);
        setLocation(config.location);
        setSelectedCrop(config.crop);
        // setSelectedAssembly( config.assembly.name+".fa" );

        let tracks = _.pluck(config.tracks, 'name');
        const searchString = "notes.gff";
        const replacementString = "JBrowseNotes/Genomes/" + config.assembly.name + ".fa/" + currentUser.DirectoryName + "/" + currentUser.DirectoryName + ".notes.gff";
        for (let i = 0; i < tracks.length; i++) {
            if (tracks[i].endsWith(searchString)) {
                tracks[i] = replacementString;
            }
        }
        console.log("rebuild tracks: ", tracks);
        selectedTracksRef.current = tracks;
        setSelectedTracks(tracks);

        // Get Notes
        let notes_obj = {
            idCompany: currentUser.idCompany,
            username: currentUser.email,
            assembly: config.assembly.name + ".fa",
        };
        console.log("notes obj: ", notes_obj);

        // Send Request
        JBrowseService.getNotes(notes_obj).then(
            (response) => {
                console.log("notes response: ", response);
                let notes = []
                // Loop through notes results and fix url strings
                _.each(response.result, function (value) {
                    var obj = value;
                    obj.created_at = dayjs(value.created_at).format('MMMM D YYYY, h:mm:ss A');
                    notes.push(obj);
                });
                // console.log("notes: ", notes );
                setNotes(notes);
            },
            (error) => {
                console.log("error: ", error);
                if (error.code === "ERR_NETWORK") {
                    console.warn("logout!!!");
                    EventBus.dispatch("logout");
                }
            }
        )

        var chromosome_obj = {
            company: currentUser.DirectoryName,
            assembly: config.assembly.name+".fa",
        };
        // console.log("chromosome obj: ", chromosome_obj );
        // Send Request
        JBrowseService.getChromosomes( chromosome_obj ).then( 
            ( response )=> {
                console.log("chromosome response: ", response );
                var arr = parsePythonList(response.result);
                // console.log("arr: ", arr );
                setChromosomes( arr );
                setLoading( false );
            },
            ( error ) => {
                console.log("error: ", error);
                if (error.code === "ERR_NETWORK") {
                    console.warn("logout!!!");
                    EventBus.dispatch("logout");
                }
            }
        )

        // Send Request
        let obj = { user: currentUser.email, company: currentUser.DirectoryName };
        JBrowseService.destroyBlastDirectory(obj).then(
            (response) => {
                console.log("destroy response: ", response);
            },
            (error) => {
                console.log("error: ", error);
                if (error.code === "ERR_NETWORK") {
                    console.warn("logout!!!");
                    EventBus.dispatch("logout");
                }
            }
        )

    }

    const handleCloseAlert = () => {
        setBlastLoading(false); // Change this to your actual state update logic
    };

    const handleSessionEdit = (session) => {
        console.log("session: ", session );
        setLoading( true );
        var obj = {
            company: currentUser.DirectoryName,
            assembly: session.assembly.name+".fa",
        };
        // console.log("obj: ", obj );
        // Send Request
        JBrowseService.getChromosomes( obj ).then( 
            ( response )=> {
                // console.log("chromosome response: ", response );
                var arr = parsePythonList(response.result);
                // console.log("arr: ", arr );
                setChromosomes( arr );
                setShowEditModal(true);
                setLoading( false );
            },
            ( error ) => {
                console.log("error: ", error );
                setLoading( false );
            }
        )

    }

    const handleNewUserMessage = async (newMessage) => {
        setLoading(true);
        console.log(`New message incoming! ${newMessage}`);
        let userMessage = {
            role: 'user',
            content: newMessage
        };

        // Update Conversation and send to backend in one step
        setConversation(currentConversation => {
            const updatedConversation = [...currentConversation, userMessage];

            let obj = {
                user: currentUser.email,
                conversation: updatedConversation
            };
            console.log("obj: ", obj);
            // Call the backend service
            JBrowseService.aiConversation(obj).then(
                (response) => {
                    console.log('ai response: ', response);
                    // Process the response...
                    // Assuming you are handling the response here
                    const aiMessageContent = response.result.conversation_response[0].openai_response;
                    addResponseMessage(aiMessageContent);

                    // Update Conversation with assistant's response
                    setConversation(currentConv => [...currentConv, {
                        role: 'assistant',
                        content: aiMessageContent
                    }]);
                    setShowLiteratureSearch(true);
                    setLoading(false);

                },
                (error) => {
                    console.error("ai error: ", error);
                    setLoading(false);
                }
            );

            // Return the updated conversation to update the state
            return updatedConversation;
        });
    };

    const handleLiteratureSearch = () => {
        setToggleLiteratureSearch(true);
        setLiteratureModalIsOpen(true);
    };

    const testing = () => {
        JBrowseService.testing().then(
            (response) => {
                console.log("test response: ", response );

            },
            (error) => {
                console.log("error: ", error )
            }
        )
    }

    return (
        <div>
            {loading && (
                <div className='loading'></div>
            )}

            {sampleCodeError && (
                <div className="bg-white border-l-4 border-yellow-600 text-yellow-800 p-4 rounded-lg shadow-md flex items-center space-x-3">
                    <InfoIcon style={{ marginRight: "5px" }} />
                    <div>
                        <strong className="block">SampleCode Error</strong>
                        We didn't find that sample in our database. try a sample track (.gff)
                    </div>
                    <button onClick={handleCloseAlert} className="ml-auto">
                        {/* You can replace the 'X' with an actual icon */}
                        <span role="img" aria-label="close" className="text-xl cursor-pointer">
                            <CloseIcon />
                        </span>
                    </button>
                </div>
            )}

            {bamFileError && (
                <div className="bg-white border-l-4 border-yellow-600 text-yellow-800 p-4 rounded-lg shadow-md flex items-center space-x-3">
                    <InfoIcon style={{ marginRight: "5px" }} />
                    <div>
                        <strong className="block">Bam File Error</strong>
                        We didn't find that bam file on our server.
                    </div>
                    <button onClick={handleCloseAlert} className="ml-auto">
                        {/* You can replace the 'X' with an actual icon */}
                        <span role="img" aria-label="close" className="text-xl cursor-pointer">
                            <CloseIcon />
                        </span>
                    </button>
                </div>
            )}

            {/* <button className="py-2 px-4 font-semibold rounded-lg shadow-md text-white bg-gray-600 hover:bg-gray-800 ml-1" onClick={testing}>
                Testing
            </button> */}

            {/* Buttons */}
            <div className="container-fluid">
                {/* Toggle View */}
                {state && (
                    <button
                        onClick={toggleSessionsList}
                        className="py-2 px-4 font-semibold rounded-lg shadow-md text-white bg-gray-600 hover:bg-gray-800 ml-1"
                    >
                        <SaveAsIcon style={{ marginRight: '8px' }} />
                        Saved Sessions
                    </button>
                )}

                {notes.length > 0 && (
                    <button
                        onClick={toggleNotes}
                        className="py-2 px-4 font-semibold rounded-lg shadow-md text-white bg-gray-600 hover:bg-gray-800 ml-1"
                    >
                        <NoteIcon style={{ marginRight: '8px' }} />
                        Notes
                    </button>
                )}

                {state && (
                    <button
                        onClick={toggleVariants}
                        className="py-2 px-4 font-semibold rounded-lg shadow-md text-white bg-gray-600 hover:bg-gray-800 ml-1"
                    >
                        <ListAltIcon style={{ marginRight: '8px' }} />
                        Access Variants
                    </button>
                )}

                { state && (
                    <button
                        onClick={toggleBlast}
                        className="py-2 px-4 font-semibold rounded-lg shadow-md text-white bg-gray-600 hover:bg-gray-800 ml-1"
                    >
                        <SearchIcon style={{ marginRight: '8px' }} />
                        Blast
                    </button>
                )}

                {currentSession && (
                    <>
                        <button
                            onClick={() => { setShowToolsModal(true) }}
                            className="py-2 px-4 font-semibold rounded-lg shadow-md text-white bg-gray-600 hover:bg-gray-800 ml-1"
                        >
                            <HandymanIcon style={{ marginRight: '8px' }} />
                            Tools
                        </button>
                    </>
                )}

                {/* <p className="text-white">{toggleLiteratureSearch ? 'True' : 'False'}</p> */}
            </div>

            {blastLoading && (
                <div className="bg-white border-l-4 border-green-600 text-green-800 p-4 rounded-lg shadow-md flex items-center space-x-3">
                    {/* <SyncIcon style={{ marginRight: "5px" }} /> */}
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    <div>
                        <strong className="block">Processing Blast</strong>
                        We're currently analyzing the selected region and generating its corresponding blast track. Hang tight, it won't take long!
                    </div>
                    <button onClick={handleCloseAlert} className="ml-auto">
                        {/* You can replace the 'X' with an actual icon */}
                        <span role="img" aria-label="close" className="text-xl cursor-pointer">
                            <CloseIcon />
                        </span>
                    </button>
                </div>
            )}

            {literatureSearchError && (
                <div className="bg-white border-l-4 border-yellow-600 text-yellow-800 p-4 rounded-lg shadow-md flex items-center space-x-3">
                    {/* <SyncIcon style={{marginRight: "5px"}}/> */}
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    <div>
                        <strong className="block">Literature Search</strong>
                        We didn't find any articles with that query, try again.
                    </div>
                    <button onClick={handleCloseAlert} className="ml-auto">
                        {/* You can replace the 'X' with an actual icon */}
                        <span role="img" aria-label="close" className="text-xl cursor-pointer">
                            <CloseIcon />
                        </span>
                    </button>
                </div>
            )}

            {!state && (
                <>
                    {currentSessions.length > 0 ? (
                        <div className="overflow-x-auto">

                            <input
                                className="border-2 border-gray-300 p-2 rounded mb-4"
                                type="text"
                                placeholder="Filter by Session Name..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <span className='text-white'> - {currentItems.length} Sessions</span>
                            <table className="min-w-full bg-white">
                                <thead className="bg-gray-800 text-white">
                                    <tr>
                                        <th className="w-1/3 text-left py-3 px-4 uppercase font-semibold text-sm">Session Name</th>
                                        <th className="w-1/3 text-left py-3 px-4 uppercase font-semibold text-sm">Location</th>
                                        <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="text-gray-700">
                                    {currentItems.map((session, index) => (
                                        <tr key={index}>
                                            <td className="w-1/3 text-left py-3 px-4">{session.session.name}</td>
                                            <td className="w-1/3 text-left py-3 px-4">{session.location}</td>
                                            <td className="text-left py-3 px-4">
                                                <button
                                                    className="inline-flex items-center justify-center bg-blue-600 hover:bg-blue-900 text-white font-semibold py-2 px-3 rounded-lg shadow-md transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
                                                    onClick={() => {
                                                        // Your function to rebuild the session
                                                        rebuildSession(session);
                                                    }}
                                                >
                                                    <BuildCircleIcon className="h-5 w-5 mr-2" />
                                                    Build
                                                </button>
                                                <button
                                                    className="inline-flex items-center justify-center bg-blue-500 hover:bg-blue-800 text-white font-semibold py-2 px-3 rounded-lg shadow-md transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400 ml-1"
                                                    onClick={() => {
                                                        // Your function to delete the session
                                                        setSelectedSession(session);
                                                        handleSessionEdit( session );
                                                    }}
                                                >
                                                    <ModeEditIcon className="h-5 w-5 mr-2" />
                                                    Edit
                                                </button>
                                                <button
                                                    className="inline-flex items-center justify-center bg-blue-400 hover:bg-blue-700 text-white font-semibold py-2 px-3 rounded-lg shadow-md transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400 ml-1"
                                                    onClick={() => {
                                                        // Your function to delete the session
                                                        setSelectedSession(session);
                                                        setDeleteModalIsOpen(true);
                                                    }}
                                                >
                                                    <DeleteIcon className="h-5 w-5 mr-2" />
                                                    Delete
                                                </button>
                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="flex justify-between mt-4">
                                <button
                                    className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
                                    onClick={() => setCurrentPage(prevPage => Math.max(prevPage - 1, 1))} // Go to the previous page
                                >
                                    Previous
                                </button>
                                <span className='text-white'>Page {currentPage} of {totalPages}</span>
                                <button
                                    className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
                                    onClick={() => setCurrentPage(prevPage => Math.min(prevPage + 1, Math.ceil(filteredSessions.length / itemsPerPage)))} // Go to the next page
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="text-center mt-6 text-white">
                            <p>You currently have no sessions saved.</p>
                            <p>Save a session to see it appear here!</p>
                        </div>
                    )}
                </>
            )}

            {/* JBrowse 2 */}
            <AnimatePresence>
                {state && (
                    <>
                        <motion.div
                            initial={{ opacity: 0, x: '100%' }} // Start from the right
                            animate={{ opacity: 1, x: '0%' }}   // Move to its original position
                            exit={{ opacity: 0, x: '100%' }}    // Exit to the right
                            transition={{ duration: 0.5 }}
                            className="bg-gray-300"
                        >
                            <div style={{ width: windowWidth + "px" }}>
                                <JBrowseLinearGenomeView viewState={state} />
                            </div>
                        </motion.div>
                        <Widget
                            handleNewUserMessage={handleNewUserMessage}
                            profileAvatar={logo}
                            title="Verinomics GFF GPT"
                            subtitle="powered by openai"
                            resizable={false}
                            emojis={false}
                            showBadge={false}
                            showCloseButton={true}
                        />
                    </>
                )}
            </AnimatePresence >

            {accessVariantIsOpen && (
                <AccessVariantsModal
                    selectedVariants={selectedVariants}
                    setSelectedVariants={setSelectedVariants}
                    selectedAssembly={selectedAssembly}
                    selectedTracks={selectedTracks}
                    location={location}
                    currentUser={currentUser}
                    sequence={sequence}
                    setSequence={setSequence}
                    setSequenceModalIsOpen={setSequenceModalIsOpen}
                    featureModalIsOpen={featureModalIsOpen}
                    setFeatureModalIsOpen={setFeatureModalIsOpen}
                    accessVariantIsOpen={accessVariantIsOpen}
                    setAccessVariantIsOpen={setAccessVariantIsOpen}
                    haploData={haploData}
                    setHaploData={setHaploData}
                    setHaploModalIsOpen={setHaploModalIsOpen}
                    setLoading={setLoading}
                    sampleNames={sampleNames}
                    setSampleNames={setSampleNames}
                    setSampleSequenceIsOpen={setSampleSequenceIsOpen}
                    setOpenAiModalIsOpen={setOpenAiModalIsOpen}
                    selectedCrop={selectedCrop}
                    setConversation={setConversation}
                    setShowLiteratureSearch={setShowLiteratureSearch}
                    setToggleLiteratureSearch={setToggleLiteratureSearch}
                    chromosomes={chromosomes}
                    currentAssembly={currentAssembly}
                    setIsConversationModalOpen={setIsConversationModalOpen}
                />
            )}

            {featureModalIsOpen && (
                <FeatureTableModal
                    gffs={gffs}
                    featureModalIsOpen={featureModalIsOpen}
                    setFeatureModalIsOpen={setFeatureModalIsOpen}
                    setAccessVariantIsOpen={setAccessVariantIsOpen}
                    navigateToLocation={navigateToLocation}
                    location={location}
                    setLoading={setLoading}
                    currentUser={currentUser}
                    selectedCrop={selectedCrop}
                    aiLoading={aiLoading}
                    setAiLoading={setAiLoading}
                />
            )}

            {haploModalIsOpen && (
                <HaploviewerModal
                    haploData={haploData}
                    setHaploData={setHaploData}
                    haploModalIsOpen={haploModalIsOpen}
                    setHaploModalIsOpen={setHaploModalIsOpen}
                    setAccessVariantIsOpen={setAccessVariantIsOpen}
                    setLoading={setLoading}
                />
            )}

            {blastModalIsOpen && (
                <BlastModal
                    blastData={blastData}
                    setBlastData={setBlastData}
                    blastModalIsOpen={blastModalIsOpen}
                    setBlastModalIsOpen={setBlastModalIsOpen}
                    navigateToLocation={navigateToLocation}
                    setLoading={setLoading}
                    blastLoading={blastLoading}
                    setBlastLoading={setBlastLoading}
                    blastParagraph={blastParagraph}
                    setBlastParagraph={setBlastParagraph}
                    currentUser={currentUser}
                    aiLoading={aiLoading}
                    setAiLoading={setAiLoading}
                    chromosomes={chromosomes}
                    location={location}
                    currentAssembly={currentAssembly.name + ".fa"}
                    selectedCrop={selectedCrop}
                    selectedTracksRef={selectedTracksRef}
                    setSelectedTracks={setSelectedTracks}
                    setCurrentTracks={setCurrentTracks}
                    setCurrentSession={setCurrentSession}
                    updateTracks={updateTracks}
                />
            )}

            {sequenceModalIsOpen && (
                <SequenceRetrievalModal
                    sequence={sequence}
                    setSequenceModalIsOpen={setSequenceModalIsOpen}
                    setAccessVariantIsOpen={setAccessVariantIsOpen}
                    location={location}
                />
            )}

            {samplesSequenceIsOpen && (
                <SampleSequenceModal
                    sampleNames={sampleNames}
                    samplesSequenceIsOpen={samplesSequenceIsOpen}
                    setSampleSequenceIsOpen={setSampleSequenceIsOpen}
                    setLoading={setLoading}
                    selectedAssembly={selectedAssembly}
                    selectedTracks={selectedTracks}
                    location={location}
                    setAccessVariantIsOpen={setAccessVariantIsOpen}
                    currentUser={currentUser}
                />
            )}

            {notesIsOpen && (
                <NotesModal
                    notes={notes}
                    notesIsOpen={notesIsOpen}
                    setNotesIsOpen={setNotesIsOpen}
                    navigateToLocation={navigateToLocation}
                    setLoading={setLoading}
                    setBatchNotesModalIsOpen={setBatchNotesModalIsOpen}
                    selectedAssembly={selectedAssembly}
                    currentUser={currentUser}
                    setCurrentNote={setCurrentNote}
                    setEditNotesModalIsOpen={setEditNotesModalIsOpen}
                    setNotes={setNotes}
                />
            )}

            {createNotesModalIsOpen && (
                <CreateNotesModal
                    createNotesModalIsOpen={createNotesModalIsOpen}
                    setCreateNotesModalIsOpen={setCreateNotesModalIsOpen}
                    location={location}
                    setLocation={setLocation}
                    currentUser={currentUser}
                    selectedAssembly={selectedAssembly}
                    setLoading={setLoading}
                    createTracks={createTracks}
                    selectedTracks={selectedTracks}
                    setCurrentTracks={setCurrentTracks}
                    setCurrentSession={setCurrentSession}
                    createDefaultSession={createDefaultSession}
                    updateTracks={updateTracks}
                    setNotes={setNotes}
                />
            )}

            {batchNotesModalIsOpen && (
                <BatchNotesModal
                    batchNotesModalIsOpen={batchNotesModalIsOpen}
                    setBatchNotesModalIsOpen={setBatchNotesModalIsOpen}
                    currentUser={currentUser}
                    selectedAssembly={selectedAssembly}
                    setLoading={setLoading}
                    setNotesIsOpen={setNotesIsOpen}
                />
            )}

            {editNotesModalIsOpen && (
                <EditNotesModal
                    currentNote={currentNote}
                    editNotesModalIsOpen={editNotesModalIsOpen}
                    setEditNotesModalIsOpen={setEditNotesModalIsOpen}
                    selectedAssembly={selectedAssembly}
                    currentUser={currentUser}
                    setNotesIsOpen={setNotesIsOpen}
                    setLoading={setLoading}
                    setNotes={setNotes}
                />
            )}

            {deleteModalIsOpen && (
                <DeleteModal
                    deleteModalIsOpen={deleteModalIsOpen}
                    setDeleteModalIsOpen={setDeleteModalIsOpen}
                    selectedSession={selectedSession}
                    setLoading={setLoading}
                    currentUser={currentUser}
                    setCurrentSessions={setCurrentSessions}
                />
            )}

            {openAiModalIsOpen && (
                <OpenAiModal
                    setOpenAiModalIsOpen={setOpenAiModalIsOpen}
                    openAiModalIsOpen={openAiModalIsOpen}
                    gffs={gffs}
                    setAccessVariantIsOpen={setAccessVariantIsOpen}
                    navigateToLocation={navigateToLocation}
                    location={location}
                    setLoading={setLoading}
                    currentUser={currentUser}
                    selectedCrop={selectedCrop}
                    aiLoading={aiLoading}
                    setAiLoading={setAiLoading}
                    selectedAssembly={selectedAssembly}
                    selectedTracks={selectedTracks}
                />
            )}

            {aiFeatureModalIsOpen && (
                <AiFeatureModal
                    aiFeatureModalIsOpen={aiFeatureModalIsOpen}
                    setAiFeatureModalIsOpen={setAiFeatureModalIsOpen}
                    aiResponse={aiResponse}
                    location={location}
                    setLoading={setLoading}
                    currentUser={currentUser}
                />
            )}

            {literatureModalIsOpen && (
                <LiteratureModal
                    literatureSearch={literatureSearch}
                    setLiteratureSearch={setLiteratureSearch}
                    literatureModalIsOpen={literatureModalIsOpen}
                    setLiteratureModalIsOpen={setLiteratureModalIsOpen}
                    setLoading={setLoading}
                    currentUser={currentUser}
                    literaturePrompt={literaturePrompt}
                    setLiteraturePrompt={setLiteraturePrompt}
                    selectedCrop={selectedCrop}
                    conversation={conversation}
                    isWidgetOpen={isWidgetOpen}
                    setLiteratureSearchError={setLiteratureSearchError}
                    setShowToolsModal={setShowToolsModal}
                />
            )}

            { showEditModal && (
                <EditSessionModal 
                    setLoading={setLoading}
                    currentUser={currentUser}
                    selectedCrop={selectedCrop}
                    setShowEditModal={setShowEditModal}
                    selectedSession={selectedSession}
                    setSelectedSession={setSelectedSession}
                    setCurrentSessions={setCurrentSessions}
                    setSuccessEditMessage={setSuccessEditMessage}
                    successEditMessage={successEditMessage}
                    setErrorEditMessage={setErrorEditMessage}
                    errorEditMessage={errorEditMessage}
                    chromosomes={chromosomes}
                />
            )}

            { isConversionModalOpen && (
                <ConversationModal 
                    location={location}
                    setLoading={setLoading}
                    currentUser={currentUser}
                    setIsConversationModalOpen={setIsConversationModalOpen}
                />
            )}

            { showAlignmentModal && (
                <AlignmentModal 
                    htmlBlob={htmlBlob}
                    show={showAlignmentModal}
                    setShow={ setShowAlignmentModal }
                />
            )}

            { showToolsModal && (
                <ToolsModal 
                    setLiteratureModalIsOpen={setLiteratureModalIsOpen}
                    setShowToolsModal={setShowToolsModal}
                    showToolsModal={showToolsModal}
                    currentAssembly={currentAssembly}
                    currentUser={currentUser}
                    chromosomes={chromosomes}
                    selectedCrop={selectedCrop}
                />
            )}

        </div>
    )
}

function isBlank(str) {
    return (!str || /^\s*$/.test(str));
}

function findLastAssistantContent(array) {
    for (let i = array.length - 1; i >= 0; i--) {
        if (array[i].role === "assistant") {
            return array[i].content;
        }
    }
    return null; // Return null or any other value if not found
}

function parsePythonList(str) {
    // Remove the leading and trailing square brackets and split by comma
    var arr = str.slice(1, -1).split(',');

    // Trim leading and trailing spaces and single quotes from each array element
    arr = arr.map(item => item.trim().slice(1, -1));

    return arr;
}

function removeFileExtensions(files) {
    return files.map(file => {
        // Remove the extension from each file name
        return file.replace(/\.[^/.]+$/, "");
    });
}

function removeFilesWithSuffix(files, suffix) {
    // Use filter to create a new array excluding files that end with the given suffix
    return files.filter(file => !file.endsWith(suffix));
}

function extractNameBeforeUnderscore(strings) {
    return strings.map(str => {
        // Split the string by the underscore and return the first part
        const [name,] = str.split('_');
        return name;
    });
}

function addSuffixBeforeId(strings) {
    return strings.map(str => {
        // Split the string into [name, id] by the underscore
        const parts = str.split('_');
        // Check if the split operation gave us exactly 2 parts
        if (parts.length === 2) {
            // Reconstruct the string with '-1' added before the ID
            return `${parts[0]}-1_${parts[1]}`;
        }
        // Return the original string if it doesn't match the expected format
        return str;
    });
}