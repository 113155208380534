import React, { useState } from 'react';
import { Modal, Button, Backdrop, CircularProgress, IconButton } from '@mui/material';
import { Close as CloseIcon, Fullscreen as FullscreenIcon } from '@mui/icons-material';
import styles from '../alignmentViewer.module.css'; // Import CSS module
import "../alignmentViewer.css"

const AlignmentModal = (props) => {
  let { htmlBlob, show, setShow } = props;
  const [loading, setLoading] = useState(false);

  return (
    <div>
      {loading && (
        <Backdrop open={loading} style={{ zIndex: 9999 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <Modal
        open={show}
        onClose={() => setShow(false)}
        aria-labelledby="alignment-viewer-title"
        aria-describedby="alignment-viewer-description"
      >
        <div className="alignmentModalContainer" style={{ 
          top: '50%', 
          left: '50%', 
          transform: 'translate(-50%, -50%)', 
          position: 'absolute', 
          width: '80%', 
          maxHeight: '80%', 
          backgroundColor: 'white', 
          boxShadow: 24, 
          padding: '16px', 
          overflowY: 'auto'
        }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "40px" }}>
            <h2 id="alignment-viewer-title">Alignment Viewer</h2>
            <IconButton onClick={() => setShow(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <div id="alignment-viewer-description">
            <div className={styles.body}>
              <div className={styles.wrapper}>
                <div className={styles.fixed}>
                  Legend: <span className={styles.sample}><span style={{color: 'red'}}>&#8711;</span> <small>Variant</small></span>
                </div>
                <div id="alignment" style={{ lineHeight: 1.1 }} dangerouslySetInnerHTML={{ __html: htmlBlob }}></div>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
            <Button variant="outlined" onClick={() => { /* Implement fullscreen functionality here */ }} startIcon={<FullscreenIcon />}>
              Fullscreen
            </Button>
            <Button variant="contained" color="secondary" onClick={() => setShow(false)} style={{ marginLeft: '8px' }}>
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AlignmentModal;
