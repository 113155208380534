import React, { useState, useEffect } from "react";

import UserService from "../services/user.service";
import EventBus from "../common/EventBus";

import WizardTool from "./WizardTool";
import Senteny from "./Senteny";
import Circular from "./Circular";
import { TestSession } from "./TestSession";
import { SavedSession } from "./SavedSession";

const JBrowse = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    UserService.getUserBoard().then(
      (response) => {
        console.log("response: ", response.data );
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  return (
    <>
        <WizardTool />
        {/* <SavedSession />  */}
        {/* <Senteny /> */}
        {/* <Circular /> */}
        {/* <TestSession /> */}
    </>
  );
};

export default JBrowse;
