import React from 'react';

const SequenceRetrievalModal = ( props ) => {
    // console.log("props: ", props );
    const { sequence,
            location,
            setSequenceModalIsOpen,
            setAccessVariantIsOpen, } = props;
    const charsPerLine = 60;

    const onClose = () => {
        // Implement modal close logic here
        console.log("Closing modal");
        setSequenceModalIsOpen( false );
    };

    const onBack = () => {
        // Implement back navigation logic here
        console.log("Going back");
        setSequenceModalIsOpen(false);
        setTimeout( ()=> {
            setAccessVariantIsOpen( true);
        },100);
    };

    const onDownload = ( seq ) => {
        var loc = location;
        var filename = loc+".fa";
        downloadFile( seq, filename );
    }

    const onCopy = (text) => {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        alert("Sequence copied to clipboard!");
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="w-2/3 bg-white rounded">
                <div className="flex justify-between items-center p-4 border-b">
                    <h5 className="text-lg font-semibold">Reference Sequence</h5>
                    <button onClick={onClose} className="text-gray-700 hover:text-gray-500">
                        &times;
                    </button>
                </div>
                <div className="p-4">

                    <div className="sequence-viewer">
                        {sequence.map((seqObj, seqIndex) => (
                            <div key={seqIndex} className="sequence-entry">
                                <h3 className="sequence-title">
                                    {seqObj.loc}
                                    <span><small> - {(seqObj.sequence.length * 2 / 1024).toFixed(2)} KB</small></span>
                                </h3>
                                <div className='space-x-2'>
                                    <button
                                        onClick={() => onCopy(seqObj.sequence)}
                                        className="copy-button bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600 transition duration-300"
                                    >
                                        Copy to Clipboard
                                    </button>
                                    <button
                                        onClick={() => onDownload(seqObj.sequence)}
                                        className="copy-button bg-gray-500 text-white px-2 py-1 rounded hover:bg-blue-600 transition duration-300"
                                    >
                                        Download Sequence
                                    </button>
                                </div>
                                <div className="sequence-content" style={{ maxHeight: "500px", overflow: "auto" }}>
                                    {seqObj.sequence.match(new RegExp(`.{1,${charsPerLine}}`, 'g')).map((line, lineIndex) => (
                                        <div key={lineIndex} className="sequence-line">
                                            <span className="sequence-number">{lineIndex * charsPerLine + 1}</span>
                                            <span className="sequence-text">{line}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* <ul className="divide-y divide-gray-200">
                        { sequence.length > 0 && sequence.map((item, index) => (
                            <li key={index} className="py-4 text-center">
                                <p className='truncate'>{item.loc}</p>
                                <button
                                    className="px-4 py-2 m-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                                    onClick={() => onDownload(item.loc, item.sequence)}
                                >
                                    <i className="fas fa-file-download mr-2"></i> Download Sequence
                                </button>
                                <button
                                    className="px-4 py-2 m-2 bg-blue-300 text-white rounded hover:bg-blue-400"
                                    onClick={() => onCopy(item.loc, item.sequence)}
                                >
                                    <i className="far fa-copy mr-2"></i> Copy Sequence
                                </button>
                            </li>
                        ))}
                    </ul> */}
                </div>
                <div className="flex justify-between items-center p-4 border-t">
                    <button onClick={onBack} className="px-4 py-2 bg-gray-300 hover:bg-gray-400 text-black rounded">
                        <i className="far fa-hand-point-left mr-2"></i> Go Back
                    </button>
                    <button onClick={onClose} className="px-4 py-2 bg-gray-500 hover:bg-gray-600 text-white rounded">
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SequenceRetrievalModal;

function downloadFile( data, filename ) {
    if (!data) {
        console.error('No data');
        return;
    }
    
    if (!filename) {
        filename = 'download.json';
    }
    
    if (typeof data === 'object') {
        data = JSON.stringify(data, undefined, 2);
    }
    
    var blob = new Blob([data], {type: 'text/json'});
    
    // FOR IE:
    
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
    }
    else{
        var e = document.createEvent('MouseEvents'),
            a = document.createElement('a');
    
        a.download = filename;
        a.href = window.URL.createObjectURL(blob);
        a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
        e.initEvent('click', true, false, window,
            0, 0, 0, 0, 0, false, false, false, false, 0, null);
        a.dispatchEvent(e);
    }
}