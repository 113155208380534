import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { TextField, MenuItem, Alert } from '@mui/material';
import HandymanIcon from '@mui/icons-material/Handyman';
import CloseIcon from '@mui/icons-material/Close';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

import JBrowseService from '../services/jbrowse.service';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    maxWidth: '90vw', // Ensures the modal doesn't get too wide
    maxHeight: '80vh', // Ensures the modal doesn't get too tall
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto', // Makes the content scrollable if it exceeds maxHeight
};

export default function ToolsModal(props) {
    let {
        setLiteratureModalIsOpen,
        setShowToolsModal,
        showToolsModal,
        currentAssembly,
        currentUser,
        chromosomes,
        selectedCrop,
    } = props;
    const handleOpen = () => setShowToolsModal(true);
    const handleClose = () => setShowToolsModal(false);

    const [assembly, setAssembly] = useState('');
    const [chromosome, setChromosome] = useState(chromosomes[0] || 'select');
    const [start, setStart] = useState('');
    const [stop, setStop] = useState('');
    const [panGenomes, setPanGenomes] = useState([]);
    const [positionsError, setPositionsError] = useState(false);
    const [ positionsSuccess, setPositionSuccess ] = useState(false);
    const [subject, setSubject] = useState('');
    const [query, setQuery] = useState('');

    const [availableSubjects, setAvialableSubjects] = useState([]);
    const [availableQueries, setAvialableQueries] = useState([]);
    const [positionsResponse, setPositionsResponse] = useState({});

    const handleSubject = (e) => {
        console.log('subject: ', e.target.value);
        setSubject(e.target.value);

        console.log("panGenomes: ", panGenomes);

        // Filter out the selected subject from availableQueries
        const updatedQueries = availableQueries.filter(query => query !== e.target.value);
        setAvialableQueries(updatedQueries);
    }

    const handleLiteratureSearch = () => {
        console.log("type: ", typeof setLiteratureModalIsOpen);
        setShowToolsModal(false);
        setLiteratureModalIsOpen(true);
    }

    const convertPositions = (event) => {
        event.preventDefault();
        let obj = {
            subject: subject,
            query: query,
            position: `${chromosome}:${start}-${stop}`,
            user: currentUser.email,
            company: currentUser.DirectoryName,
        };
        console.log("obj: ", obj);
        // return;
        // Send Request
        JBrowseService.convertPositions(obj).then(
            (response) => {
                console.log("convert response: ", response);
                // setIsConversationModalOpen( true );
                setPositionsResponse(response.result);
                
                setPositionSuccess(true);
                setTimeout(() => {
                    setPositionSuccess(false);
                }, 3000);
            },
            (error) => {
                console.log("error: ", error);
                setPositionsError(true);
                setTimeout(() => {
                    setPositionsError(false);
                }, 5000);
            }
        )
    }

    const copyToClipboard = () => {
        const csv = Papa.unparse(Object.entries(positionsResponse).map(([key, value]) => ({ key, value })));
        navigator.clipboard.writeText(csv)
            .then(() => {
                console.log("Copied to clipboard");
            })
            .catch((error) => {
                console.error("Copy failed", error);
            });
    };

    const saveToCSV = () => {
        const csv = Papa.unparse(Object.entries(positionsResponse).map(([key, value]) => ({ key, value })));
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'positionsResponse.csv');
    };

    useEffect(() => {
        console.log("crop here: ", selectedCrop);
        if (selectedCrop) {
            console.log("fire: ");
            let obj = {
                user: currentUser.email,
                crop: selectedCrop,
                idCompany: currentUser.idCompany
            };
            console.log("obj: ", obj);
            JBrowseService.getPanGenomes(obj).then(
                (response) => {
                    console.log("pan genome response: ", response);
                    setPanGenomes(response.result);
                    setAvialableSubjects(response.result);
                    setAvialableQueries(response.result);
                },
                (error) => {
                    console.log("pan error: ", error);
                }
            )
        }
    }, [selectedCrop]);

    return (
        <div>

            <Modal
                open={showToolsModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="fixed z-10 inset-0 overflow-y-auto flex justify-center items-center"
            >
                <Box sx={style}>
                    <CloseIcon className="float-right" onClick={handleClose} style={{ cursor: "pointer" }} />
                    <Typography className="mb-2" id="modal-modal-title" variant="h6" component="h2">
                        <HandymanIcon style={{ marginRight: "10px" }} />
                        Tools
                    </Typography>
                    {/* List of buttons */}
                    {/* We need a feature Title and a Description for eatch button */}
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography>Convert Positions</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Our genome browser now includes an assembly conversion tool. This feature allows you to seamlessly translate genomic positions between different genome assemblies. By converting coordinates, you can easily compare data from various sources and versions, ensuring compatibility and accuracy in your genomic analyses.
                            </Typography>
                            <div className="p-4 bg-white border rounded">
                                {/* Content for Download File */}
                                <div className="p-4">
                                    <Box component="form" noValidate sx={{ mt: 1 }}>
                                        <Typography variant="h6">Convert Positions Form</Typography>
                                        {/* Display the current assembly selection */}
                                        {currentAssembly && (
                                            <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
                                                <i>Current Assembly:</i> {currentAssembly.name}.fa
                                            </Typography>
                                        )}
                                        <TextField
                                            select
                                            label="Subject"
                                            value={subject || ''}
                                            onChange={handleSubject}
                                            fullWidth
                                            margin="normal"
                                        >
                                            {panGenomes.map((ref, index) => (
                                                <MenuItem key={index} value={ref.FileName}>
                                                    {ref.FileName}
                                                </MenuItem>
                                            ))}
                                        </TextField>

                                        <TextField
                                            select
                                            label="Query"
                                            value={query || ''}
                                            onChange={(e) => setQuery(e.target.value)}
                                            fullWidth
                                            margin="normal"
                                        >
                                            {panGenomes.map((ref, index) => (
                                                <MenuItem key={index} value={ref.FileName}>
                                                    {ref.FileName}
                                                </MenuItem>
                                            ))}
                                        </TextField>

                                        <TextField
                                            select
                                            label="Chromosome"
                                            value={chromosome}
                                            onChange={(e) => setChromosome(e.target.value)}
                                            fullWidth
                                            margin="normal"
                                        >
                                            {chromosomes.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>

                                        <TextField
                                            label="Start"
                                            type="number"
                                            value={start}
                                            onChange={(e) => {
                                                const value = parseInt(e.target.value, 10); // Convert the input value to an integer
                                                if (!isNaN(value) && value >= 0) {
                                                    setStart(value); // Update the state only if the value is a non-negative number
                                                } else if (e.target.value === '') {
                                                    setStart(''); // Allow clearing the input
                                                }
                                            }}
                                            fullWidth
                                            margin="normal"
                                        />

                                        <TextField
                                            label="End"
                                            type="number"
                                            value={stop}
                                            onChange={(e) => {
                                                const value = parseInt(e.target.value, 10); // Convert the input value to an integer
                                                if (!isNaN(value) && value >= 0) {
                                                    setStop(value); // Update the state only if the value is a non-negative number
                                                } else if (e.target.value === '') {
                                                    setStop(''); // Allow clearing the input
                                                }
                                            }}
                                            fullWidth
                                            margin="normal"
                                        />

                                        <Button onClick={convertPositions} type="button" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                                            Convert Positions
                                        </Button>
                                    </Box>

                                    {positionsError && (
                                        <Alert severity="warning">Convert Positions Error.</Alert>
                                    )}

                                    {positionsSuccess && (
                                        <Alert severity="success">Convert Positions Success.</Alert>
                                    )}

                                </div>
                                {/* Response */}
                                {Object.keys(positionsResponse).length > 0 && (
                                    <div className="p-4">
                                        <Typography className='m-1' variant="h5" component="h2">
                                            Conversation Response:
                                        </Typography>
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableBody>
                                                    {Object.entries(positionsResponse).map(([key, value]) => (
                                                        <TableRow key={key}>
                                                            <TableCell>{key}</TableCell>
                                                            <TableCell>{value}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Button variant="contained" color="primary" onClick={copyToClipboard} style={{ marginRight: '10px' }}>
                                            Copy to Clipboard
                                        </Button>
                                        <Button variant="contained" color="secondary" onClick={saveToCSV}>
                                            Save as CSV
                                        </Button>
                                    </div>
                                 )} 



                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography>Literature Search</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Our new search feature connects you directly to Google Scholar. You can now easily find academic articles, papers, and publications on any topic right from within our platform. Simply enter your search terms to access a wealth of scholarly resources.
                            </Typography>
                            <Button className='mt-2' variant='contained' onClick={handleLiteratureSearch}>Search Google Scholar</Button>

                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Modal>
        </div>
    );
}
