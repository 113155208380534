import React, { useState, useEffect } from 'react';
import InsightsIcon from '@mui/icons-material/Insights';
import _ from 'underscore';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import Alert from '@mui/material/Alert';
import {Table, TableBody, TableCell, TableHead, TableRow, Button} from '@mui/material';

import createTracks from "./createTracks";
import createDefaultSession from './createDefaultSession';
import EventBus from "../common/EventBus";


// Socket.io
import socketIOClient from 'socket.io-client';
import socketUrl from '../common/GetSocket';
import { Redirect } from "react-router-dom";
import JBrowseService from '../services/jbrowse.service';
const ENDPOINT = socketUrl();
// console.log("endpoint: ", ENDPOINT );

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const BlastModal = (props) => {
    // console.log('props: ', props );
    let { blastData,
        setBlastData,
        blastModalIsOpen,
        setBlastModalIsOpen,
        navigateToLocation,
        setLoading,
        blastParagraph,
        setBlastParagraph,
        currentUser,
        blastLoading,
        setBlastLoading,
        chromosomes,
        location,
        currentAssembly,
        selectedCrop,
        selectedTracksRef,
        setSelectedTracks,
        setCurrentTracks,
        setCurrentSession,
        updateTracks,
    } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [isParagraphVisible, setIsParagraphVisible] = useState(false);

    const [value, setValue] = useState(0);
    const [sequence, setSequence ] = useState("");
    const [sequenceType, setSequenceType] = useState('dna');
    const [shortRead, setShortRead] = useState('normal');
    const [chromosome, setChromosome] = useState( location.split(":")[0] );
    const [start,setStart ] = useState(0);
    const [stop, setStop ] = useState(0);
    const [blastWarning, setBlastWarning ] = useState( false );

    // Safely calculate total pages, considering the case when blastData is empty
    const totalPages = blastData.length > 0 ? Math.ceil(blastData.length / itemsPerPage) : 0;

    // Conditionally initialize headers based on blastData being empty or not
    const headers = blastData.length > 0 ? Object.keys(blastData[0]) : [];
    headers.unshift("Jump");

    // Safely slice the data for pagination, considering the case when blastData is empty
    const paginatedData = blastData.length > 0
        ? blastData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
        : [];

    useEffect(() => {
        if (blastModalIsOpen) {
            const handleKeyDown = (event) => {
                if (event.key === 'Escape') {
                    closeModal();
                }
            };

            window.addEventListener('keydown', handleKeyDown);

            // Cleanup the event listener when the component is unmounted
            return () => {
                window.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [blastModalIsOpen]);

    useEffect(() => {
        const socket = socketIOClient(ENDPOINT);

        socket.on("ai_response", response => {
            console.log("socket.io results: ", response);
            if (currentUser.email === response.email) {
                console.warn("success!");
                /** AI Response */
                setBlastParagraph(response.result);
                setBlastLoading(false);
            }
        });
        // Clean up the connection when component unmounts
        return () => {
            socket.disconnect();
        };
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const validateOrBuildQuery = (data) => {
        const queryPattern = /^[\w:]+\:\d+-\d+$/;
        const { query, chromosome, start_subject, end_subject } = data;
    
        // Check if the query string matches the expected pattern
        if (queryPattern.test(query)) {
            return query; // The query is valid, return it as is
        }
    
        // Build the query string if it is not valid
        const newQuery = `${chromosome}:${start_subject}-${end_subject}`;
        return newQuery;
    };
    
    const jumpToLocation = (locationData) => {
        // Use the validateOrBuildQuery function to get the correct query
        const validatedQuery = validateOrBuildQuery(locationData);
        console.log('location: ', validatedQuery);
    
        // Assuming the rest of the function logic would follow if needed
        // Uncomment these if you need them in production
        setLoading(true);
        navigateToLocation(validatedQuery);
        setTimeout(() => {
            setBlastModalIsOpen(false);
            setLoading(false);
        }, 1000);
    };

    const blastRegion = ( event ) => {
        event.preventDefault();
        setLoading( true );
        // Check sequence..
        if( sequence === "") {
            // Blast Position
            console.log("position..");
            var obj = {
                starting: start,
                ending: stop,
                chromosome: chromosome,
                genome: currentAssembly,
                crop: selectedCrop,
                user: currentUser.username,
                company: currentUser.DirectoryName
            };
            console.log("obj: ", obj);
            setBlastLoading(true);
            setBlastParagraph("");
            // Send Request
            async function handleBlastRequest() {
                try {
                    const response = await JBrowseService.blastRegion(obj);
                    console.log("blast response: ", response);

                    // console.log("tracks before: ", selectedTracksRef.current );
                    const filteredTracks = selectedTracksRef.current.filter(item => !item.includes("BlastJob"));

                    /** Add Blasts Tracks */
                    var tracks = [...new Set([...filteredTracks, ...response.files])];
                    setSelectedTracks(tracks);
                    // Since createTracks is async, we await its result
                    var newTracks = await createTracks(currentAssembly, tracks);
                    console.log("tracks after: ", tracks );

                    // Clean Up, old BlastGff files will mess this up
                    const cleanTracks = newTracks.filter(item => item !== undefined);
                    var trackArray = _.pluck(cleanTracks, 'name');
                    setCurrentTracks(newTracks);

                    const newSession = createDefaultSession(currentAssembly, trackArray);
                    setCurrentSession(newSession);

                    console.log("clean tracks: ", cleanTracks );
                    // Introducing a slight delay to ensure all updates are processed
                    setTimeout(() => {
                        updateTracks(cleanTracks, newSession);
                        setBlastData(response.result);
                        setLoading(false);
                        setBlastLoading(false);
                    }, 500);
                } catch (error) {
                    console.log('blast error: ', error);
                    setLoading(false);
                    if (error.code === "ERR_NETWORK") {
                        console.warn("logout!!!");
                        EventBus.dispatch("logout");
                    }
                }
            }

            // To invoke the function:
            handleBlastRequest();
        } else {
            // Blast Sequence
            console.log("sequence..");
            var obj = {
                type: sequenceType === 'protein',
                short: shortRead === 'short',
                sequence: sequence,
                genome: currentAssembly,
                user: currentUser.email,
                company: currentUser.DirectoryName,
            };
            console.log("obj: ", obj );
            setBlastLoading(true);
            setBlastParagraph("");
            // setLoading(false);
            // return;
            // Send Request
            async function handleBlastSequenceRequest() {
                try {
                    const response = await JBrowseService.blastSequence(obj);
                    console.log("blast sequence response: ", response);

                    if( response.result.length === 0 ) {
                        console.warn("no blast results..");
                        // No Blast Results 
                        setLoading(false);
                        setBlastLoading(false);
                        setBlastWarning( true );
                        setTimeout( () => {
                            setBlastWarning( false );
                        },3000);
                    } else {
                        console.warn("found blast results.")
                        // console.log("tracks before: ", selectedTracksRef.current );
                        const filteredTracks = selectedTracksRef.current.filter(item => !item.includes("BlastJob"));
    
                        /** Add Blasts Tracks */
                        var tracks = [...new Set([...filteredTracks, ...response.files])];
                        setSelectedTracks(tracks);
                        // Since createTracks is async, we await its result
                        var newTracks = await createTracks(currentAssembly, tracks);
                        // console.log("tracks after: ", tracks );
    
                        // Clean Up, old BlastGff files will mess this up
                        const cleanTracks = newTracks.filter(item => item !== undefined);
                        var trackArray = _.pluck(cleanTracks, 'name');
                        setCurrentTracks(newTracks);
    
                        const newSession = createDefaultSession(currentAssembly, trackArray);
                        setCurrentSession(newSession);
    
                        // console.log("clean tracks: ", cleanTracks );
                        // Introducing a slight delay to ensure all updates are processed
                        setTimeout(() => {
                            updateTracks(cleanTracks, newSession);
                            setBlastData(response.result);
                            setLoading(false);
                            setBlastLoading(false);
                        }, 250);
                    }

                } catch (error) {
                    console.log('blast error: ', error);
                    setLoading(false);
                    setBlastLoading(false);
                    if (error.code === "ERR_NETWORK") {
                        console.warn("logout!!!");
                        EventBus.dispatch("logout");
                    }
                }
            }

            // To invoke the function:
            handleBlastSequenceRequest();

            setLoading( false );
        }
    }

    const clearBlastResults = (event ) => {
        event.preventDefault();
        setLoading( true );
        setBlastParagraph("");
        setBlastData([]);
        setTimeout(() => {
            setLoading( false );
        },50);
    }

    const closeModal = () => {
        setBlastModalIsOpen(false);
        setCurrentPage(1); // Reset page to 1 upon closing
    }

    return (
        <>
            <div className="fixed z-10 inset-0 overflow-y-auto flex justify-center items-center" style={{ zIndex: "1000" }}>
                <div className="bg-black bg-opacity-10 absolute inset-0"></div>
                {/* <div className="bg-white p-4 rounded-lg w-4/5 my-8 relative z-20" style={{ minHeight: '200px', overflowY: 'auto' }}> */}
                <div className="bg-white p-4 rounded-lg w-4/5 max-h-screen mt-12 mb-12 flex flex-col relative z-20">
                    <div className="flex justify-between items-center mb-4">
                        <h5 className="text-lg font-bold">
                            Blast Feature
                            { blastData.length > 0 && (
                                <small className="mr-2">- {blastData.length.toLocaleString()} hits, 10 per page</small>
                            )}
                        </h5>

                        <button className="text-gray-400 hover:text-gray-600" onClick={closeModal}>&times;</button>

                    </div>

                    {blastData.length > 0 ? (
                        <>
                            <div className="overflow-y-auto flex-grow">

                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            {headers.map((header) => (
                                                <TableCell key={header}>
                                                    {header}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {paginatedData.map((item, index) => (
                                            <TableRow key={index}>
                                                {headers.map((header) => (
                                                    header === 'Jump' ? (
                                                        <TableCell key="jump-button">
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => jumpToLocation( item )}
                                                                fullWidth
                                                            >
                                                                <i className="fas fa-hand-point-right mr-2"></i>
                                                                Jump
                                                            </Button>
                                                        </TableCell>
                                                    ) : (
                                                        <TableCell key={header}>
                                                            {item[header]}
                                                        </TableCell>
                                                    )
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>

                                {/* {isParagraphVisible && (
                                    <div className="mt-4 mb-4">
                                        {blastParagraph === '' ? (
                                            <div className="bg-yellow-400 border-l-4 border-yellow-700 text-yellow-900 p-4 rounded-lg shadow-md">
                                                <strong className="block">Waiting for Server Response</strong>
                                                We're currently waiting for a response from the AI server. This should take less than a minute, please hang tight!
                                            </div>
                                        ) : (
                                            <p style={{ whiteSpace: 'pre-line' }}>
                                                {blastParagraph}
                                            </p>
                                        )}
                                    </div>
                                )} */}
                            </div>

                            <div className="flex justify-between mt-1">
                                {currentPage > 1 && (
                                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => setCurrentPage(currentPage - 1)}>
                                        Previous
                                    </button>
                                )}
                                <span>Page {currentPage} of {totalPages}</span>
                                {currentPage < totalPages && (
                                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => setCurrentPage(currentPage + 1)}>
                                        Next
                                    </button>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                        <Tab label="Sequence Input" {...a11yProps(0)} style={{marginRight: "5px"}}/>
                                        <Tab label="Location Input" {...a11yProps(1)} />
                                    </Tabs>
                                </Box>
                                <TabPanel value={value} index={0}>
                                    <FormControl fullWidth>
                                        <TextField
                                            label="Sequence"
                                            multiline
                                            rows={4}
                                            placeholder="Enter sequence here"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            value={ sequence }
                                            onChange={(e) => setSequence(e.target.value)}
                                            />
                                        <FormControlLabel
                                            control={<Switch checked={sequenceType === 'dna'} onChange={() => setSequenceType(sequenceType === 'dna' ? 'protein' : 'dna')} />}
                                            label={sequenceType.toUpperCase()}
                                        />
                                        <FormControlLabel
                                            control={<Switch checked={shortRead === 'short'} onChange={() => setShortRead(shortRead === 'short' ? 'normal' : 'short')} />}
                                            label={shortRead.toUpperCase()}
                                        />
                                    </FormControl>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel id="chromosome-select-label">Chromosome</InputLabel>
                                        <Select
                                            labelId="chromosome-select-label"
                                            id="chromosome-select"
                                            value={chromosome}
                                            label="Chromosome"
                                            onChange={(e) => setChromosome(e.target.value)}
                                        >
                                            {chromosomes.map((chromosome) => (
                                                <MenuItem key={chromosome} value={chromosome}>
                                                    {chromosome}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        label="Start Location"
                                        type="number"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        value={start}
                                        onChange={(e) => {setStart(e.target.value)}}
                                    />
                                    <TextField
                                        label="Stop Location"
                                        type="number"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        value={stop}
                                        onChange={(e) => {setStop(e.target.value)}}
                                    />
                                </TabPanel>
                            </Box>
                        </>
                    )}

                    { blastWarning && (
                        <Alert severity="warning">
                            There were no Blast hits for that sequence. Try Again
                        </Alert>
                    )}


                    <div className="flex justify-between mt-4 w-full">
                        <div className="flex">
                            { blastData.length > 0 && (
                                <button className="bg-gray-500 hover:bg-gray-700 text-white py-2 px-4 rounded mr-3" onClick={clearBlastResults}>
                                    <DeleteIcon style={{ marginRight: "5px"}}/>
                                    Clear Blast Results
                                </button>
                            )}
                            {/* {blastData.length > 0 && (
                                <button className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded" onClick={() => setIsParagraphVisible(!isParagraphVisible)}>
                                    {blastLoading ? (
                                        <div className="flex items-center">
                                            <svg className="animate-spin h-6 w-6 text-white mr-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            <span>Waiting for AI Server...</span>
                                        </div>
                                    ) : (
                                        <>
                                            <InsightsIcon className='mr-2' />
                                            {isParagraphVisible ? 'Hide' : 'Show'} AI Analysis
                                        </>
                                    )}
                                </button>
                            )} */}
                        </div>
                        <div className="flex">
                            { blastData.length === 0 && (
                                <button className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded mr-3" onClick={blastRegion}>
                                    <BlurOnIcon style={{ marginRight: "5px"}}/>
                                    Blast
                                </button>
                            )}
                            <button className="bg-gray-600 hover:bg-gray-700 text-white py-2 px-4 rounded" onClick={closeModal}>
                                Close
                            </button>
                        </div>
                    </div>


                </div>
            </div>
        </>
    );
};

export default React.memo(BlastModal);
