import React, { useState, useEffect } from 'react';

function VcfModal(props) {
    // console.log('props: ', props );
    const { vcfModalIsOpen, 
            setVcfModalIsOpen, 
            sampleNames, 
            selectedVcfSamples, 
            setSelectedVcfSamples, 
            selectedTracks, 
            setSelectedTracks } = props;
    const [sampleFilter, setSampleFilter] = useState("");

    const errorIndicator = "An error occurred_3"; // The specific error message to check for
    const [availableVcfSamples, setAvailableVcfSamples] = useState([]);

    // const [availableVcfSamples, setAvailableVcfSamples] = useState(
    //     sampleNames.includes(errorIndicator) ? [] : sampleNames
    // );

    const removeGffExtension = (sample) => sample.replace('.gff', '');

    useEffect(() => {
        // Initialize availableVcfSamples based on sampleNames and errorIndicator
        const initialAvailableSamples = sampleNames.includes(errorIndicator) ? [] : sampleNames;

        // Filter out selectedVcfSamples from initialAvailableSamples
        const filteredAvailableSamples = initialAvailableSamples.filter(
            sample => !selectedVcfSamples.map(removeGffExtension).includes(sample)
        );

        setAvailableVcfSamples(filteredAvailableSamples);
    }, [sampleNames, selectedVcfSamples]);

    // console.log("available samples: ", availableVcfSamples);
    // console.log("selected: ", selectedVcfSamples);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeModal();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    // State to hold dynamically added VCF samples
    const [dynamicVcfSamples, setDynamicVcfSamples] = useState([]);

    // Add all dynamic samples to both the dynamicVcfSamples state and the selectedTracks
    const selectVcfAll = () => {
        const newSamples = availableVcfSamples.filter(sample => !selectedTracks.includes(sample));  // Assuming sampleNames holds all available samples

        // Appending '.gff' to each sample in availableVcfSamples
        const updatedSamples = availableVcfSamples.map(sample => `${sample}.gff`);
        console.log("Updated VCF Samples (with .gff): ", updatedSamples);
        
        setDynamicVcfSamples(newSamples);
        setSelectedTracks([...selectedTracks, ...updatedSamples]);  // Merge with existing tracks
        setSelectedVcfSamples( availableVcfSamples );
    };

    // Remove all dynamic samples from both the dynamicVcfSamples state and the selectedTracks
    const removeVcfAll = () => {
        console.log("Before removal, selected tracks: ", selectedTracks);
        
        // Removing only the selected VCF samples with the `.gff` extension
        setSelectedTracks(prevSelectedTracks => 
            prevSelectedTracks.filter(track => {
                // Check if the track is in selectedVcfSamples (without '.gff')
                const trackWithoutExtension = track.replace('.gff', ''); 
                return !selectedVcfSamples.includes(trackWithoutExtension);
            })
        );
    
        // Reset dynamic samples and selected VCF samples
        setDynamicVcfSamples([]);
        setSelectedVcfSamples([]);
    
        // Log the updated selectedTracks after a slight delay to capture the state update
        setTimeout(() => {
            console.log("After removal, selected tracks: ", selectedTracks);
        }, 2000);
    };

    const addSample = (sample) => {
        // Append ".gff" to the sample for consistency
        const sampleWithExtension = `${sample}.gff`;
    
        // Check and add to selectedVcfSamples if not already included
        setSelectedVcfSamples(prevSamples => {
            if (!prevSamples.includes(sampleWithExtension)) {
                return [...prevSamples, sampleWithExtension];
            } else {
                return prevSamples; // Return the current state if the sample is already included
            }
        });
    
        // Check and add to selectedTracks if not already included
        setSelectedTracks(prevSelectedTracks => {
            if (!prevSelectedTracks.includes(sampleWithExtension)) {
                return [...prevSelectedTracks, sampleWithExtension];
            } else {
                return prevSelectedTracks; // Return the current state if the track is already included
            }
        });
    
        // Remove the sample from availableVcfSamples
        setAvailableVcfSamples(prevAvailableSamples => {
            return prevAvailableSamples.filter(item => item !== sample);
        });
    };
    
    const removeSample = (sample) => {
        console.log("remove sample: ", sample);
        // Append ".gff" to the sample for consistency
        const sampleWithExtension = `${sample}.gff`;
    
        // Filter out the sample from selectedVcfSamples
        setSelectedVcfSamples(prevSamples => prevSamples.filter(s => s !== sample ));
    
        // Correctly filter out the sample from selectedTracks
        setSelectedTracks(prevSelectedTracks => prevSelectedTracks.filter(track => track !== sampleWithExtension));
        
        // Add the sample back to availableVcfSamples
        setAvailableVcfSamples(prevAvailableSamples => [...prevAvailableSamples, sample]);
    };
    
    const closeModal = () => {
        setVcfModalIsOpen(false);
    };

    return (
        vcfModalIsOpen && (
            <div className="fixed z-10 inset-0 overflow-y-auto flex justify-center" style={{ zIndex: "1000" }}>
                <div className="modal-dialog max-w-6xl w-full mx-2">
                    <div className="modal-content bg-white rounded-lg shadow-lg overflow-hidden">
                        <div className="modal-header p-4 flex justify-between items-center">
                            <h5 className="text-lg font-bold"> Sample Tracks </h5>
                            <button className="text-gray-400 hover:text-gray-600" onClick={closeModal}>&times;</button>
                        </div>
                        <div className="modal-body p-4">
                            <div>
                                <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                                    Select VCF Sample Tracks
                                </h3>

                                {
                                    availableVcfSamples.includes("An error occurred_3") ?
                                        <h3 className="text-center text-gray-600 mb-4">No VCF Tracks Available</h3> :
                                        // This block will render if availableVcfSamples contains valid data
                                        <div className="flex space-x-4">
                                            <div className="w-1/2">
                                                <label className="block text-gray-700 font-semibold mb-2">Available Samples</label>
                                                <button onClick={selectVcfAll} className="text-blue-500 hover:underline mb-2">Select All</button>
                                                <ul className="bg-gray-100 rounded p-2 max-h-60 overflow-y-auto">
                                                    {availableVcfSamples.filter(sample => !selectedVcfSamples.includes(sample) && sample !== "An error occurred_3").map(sample => (
                                                        <li key={sample} className="cursor-pointer p-2 hover:bg-gray-200 flex items-center" onClick={() => addSample(sample)}>
                                                            <i className="fas fa-plus-circle text-blue-500 mr-2"></i>
                                                            <span>{sample}</span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div className="w-1/2">
                                                <label className="block text-gray-700 font-semibold mb-2">Selected Samples</label>
                                                <button onClick={removeVcfAll} className="text-red-500 hover:underline mb-2">Remove All</button>
                                                <ul className="bg-gray-100 rounded p-2 max-h-60 overflow-y-auto">
                                                    {selectedVcfSamples.map(sample => (
                                                        <li key={sample} className="cursor-pointer p-2 hover:bg-gray-200 flex items-center" onClick={() => removeSample(sample)}>
                                                            <i className="fas fa-minus-circle text-red-500 mr-2"></i>
                                                            <span>{sample}</span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                }

                            </div>
                        </div>

                        <div className="modal-footer p-4 flex justify-end items-center">
                            <button className="bg-gray-400 hover:bg-gray-500 text-white py-2 px-4 rounded" onClick={closeModal}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
}

export default VcfModal;
