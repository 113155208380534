import React, { useState, useEffect } from "react";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

const ConversationModal = (props) => {
    const { 
        location,
        setLoading,
        currentUser,
        setIsConversationModalOpen,
    } = props


    const closeModal = () => {
        setIsConversationModalOpen(false);
    };

    return (
        <>
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50">
                <div className="relative z-50 w-11/12 max-w-2xl p-6 bg-white rounded-lg shadow-lg md:w-3/4 lg:w-1/2">
                    <div className="flex items-center justify-between mb-4">
                        <h5 className="text-xl font-semibold">
                            <PersonSearchIcon style={{ marginRight: "5px" }} /> Convert Positions Response
                        </h5>
                    </div>
                    <div className="overflow-y-auto" style={{ maxHeight: '70vh' }}> {/* Adjust max-height as needed */}
                    </div>
                    <div className="flex justify-end mt-4">
                        <button className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-700" onClick={closeModal}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConversationModal;




