import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store";
import "./index.css";
import App from "./App";
// import * as serviceWorker from "./serviceWorker";

import { HashRouter } from "react-router-dom";

const container = document.getElementById("root");
const root = createRoot(container);

// index.js
// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', function() {
//     navigator.serviceWorker.register('/service-worker.js', { scope: '/' })
//     .then(registration => {
//       console.log('Service Worker registered with scope:', registration.scope);

//       registration.onupdatefound = () => {
//         const installingWorker = registration.installing;
//         installingWorker.onstatechange = () => {
//           console.log('Service Worker state:', installingWorker.state);
//           if (installingWorker.state === 'redundant') {
//             console.error('Service Worker became redundant:', installingWorker);
//           }
//         };
//       };

//       registration.onerror = error => {
//         console.error('Service Worker registration failed:', error);
//       };
//     })
//     .catch(error => {
//       console.error('Service Worker registration failed:', error);
//     });  
//   });
// }

// // Function to get the email from the token stored in localStorage
// function getUserEmail() {
//   const user = localStorage.getItem('user');
//   if (user) {
//     let json = JSON.parse(user);
//     return json.email;
//   }
//   return null;  // Return null or a default value if user is not found in localStorage
// }

// // Listen for messages from the service worker
// navigator.serviceWorker.addEventListener('message', event => {
//   if (event.data.action === 'getEmail' && event.ports[0]) {
//     const email = getUserEmail();
//     event.ports[0].postMessage({ email });
//   }
// });

// let user = getUserEmail();
// console.log("got here w/ user: ", user );

root.render(
  <Provider store={store}>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider>
);

// If you want your app to work offline and load faster, you can chađinge
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// serviceWorker.unregister();
// serviceWorker.register();

