import React, { useState } from 'react';
import _ from 'underscore';
import JBrowseService from '../services/jbrowse.service';
// import SequenceViewer from './SequenceViewer';

const SampleSequenceModal = (props) => {
    console.log("sample seq props: ", props);

    let { sampleNames,
        sampleSequenceModalIsOpen,
        setSampleSequenceIsOpen,
        setLoading,
        selectedAssembly,
        selectedTracks,
        location,
        setAccessVariantIsOpen,
        currentUser } = props;
    const [sampleFilter, setSampleFilter] = useState('');
    const [selectedSamples, setSelectedSamples] = useState([]);
    const [ showSequences, setShowSequences ] = useState( false );
    const [ sequenceList, setSequenceList ] = useState([]);
    const charsPerLine = 60;

    // if (!sampleSequenceModalIsOpen) {
    //     return null;
    // }

    const filteredSamples = sampleNames
        .filter(sample => !selectedSamples.includes(sample)) // <-- Add this line to filter out already selected samples
        .filter(sample => sample.toLowerCase().includes(sampleFilter.toLowerCase()));

    const handleGetSequences = () => {
        setLoading( true );
        // console.log("selected: ", selectedSamples);
        const vcfFiles = selectedTracks.filter(file => file.endsWith('.vcf'));

        var obj = {
            loc: location,
            samples: selectedSamples.join(','),
            reference: selectedAssembly,
            vcf: vcfFiles[0],
            user: currentUser.email,
            company: currentUser.DirectoryName
        }
        console.log('obj: ', obj );
        // Send Request
        JBrowseService.sampleSpecificSequence( obj ).then(
            (response)=> {
                console.log("sample response: ", response );
                var arr = response.result.split('\n');
                // console.log("arr: ", arr );

                var dat = [];
                _.each( arr, function(value) {
                    dat.push( value );
                });

                // console.log("samples seqs: ", dat.slice(0,-1) );
                // var split = splitArray( dat.slice(0,-1) );
                // // console.log("split: ", split );
                const inputArray = dat.slice(0,-1);
                const samples = [];

                // Loop over input array two items at a time (i.e., loc and sequence)
                for (let i = 0; i < inputArray.length; i += 2) {
                    const loc = inputArray[i].replace('>', ''); // remove '>' character from the location string
                    const sequence = inputArray[i + 1];
                    var obj = { loc: loc, sequence: sequence }
                    samples.push( obj );
                }
            
                // console.log("samples: ", samples );
                setSequenceList( samples );
                setShowSequences( true );
                setLoading(false);
            },
            (error) => {
                console.log("error: ", error );
                setLoading(false);
            }
        )
    }

    const selectSample = (sample) => {
        setSelectedSamples([...selectedSamples, sample]);
    };

    const removeSample = (sample) => {
        setSelectedSamples(selectedSamples.filter(s => s !== sample));
    };

    const selectAll = () => {
        setSelectedSamples(filteredSamples);
    };

    const removeAll = () => {
        setSelectedSamples([]);
    };

    const closeModal = () => {
        setSampleSequenceIsOpen(false);
    }

    const onGoBack = () => {
        setSampleSequenceIsOpen(false);
        setTimeout( () => {
            setAccessVariantIsOpen( true );
        },100);
    }

    const copyToClipboard = (text) => {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        alert("Sequence copied to clipboard!");
    };

    const downloadSequence = ( seq ) => {
        var loc = location;
        var filename = loc+".fa";
        downloadFile( seq, filename );
    }

    return (
        <>
            <div className={`fixed z-50 inset-0 overflow-y-auto flex items-center justify-center`}>
                {/* Overlay */}
                <div className="bg-black bg-opacity-50 absolute inset-0"></div>

                {/* Modal */}
                <div className="bg-white p-6 rounded-lg max-w-xl w-full max-h-screen overflow-auto relative z-20 flex flex-col">
                    <div className="flex justify-between items-center mb-4 border-b pb-2">
                        <h5 className="text-xl font-bold">Get Sample Sequence</h5>
                        <button className="text-gray-400 hover:text-gray-600 text-2xl" onClick={closeModal}>
                            &times;
                        </button>
                    </div>

                    { !showSequences && (
                        <div className="p-4">
                            <div className="mb-4">
                                <label htmlFor="filter" className="block">Search Samples</label>
                                <input
                                    id="filter"
                                    type="text"
                                    className="mt-1 p-2 w-full border rounded"
                                    placeholder="Search..."
                                    value={sampleFilter}
                                    onChange={(e) => setSampleFilter(e.target.value)}
                                />
                            </div>
                            <div className="flex justify-between space-x-8 flex-grow">
                                {/* Available Samples */}
                                <div className="flex flex-col w-1/2">
                                    <label className="block text-gray-700 font-medium mb-2">Available Samples</label>
                                    <ul className="flex-grow max-h-72 overflow-auto border rounded-lg shadow-sm divide-y divide-gray-200 transition-colors">
                                        <li
                                            className="cursor-pointer p-3 bg-indigo-500 text-white rounded-lg hover:bg-indigo-600 transition-colors"
                                            onClick={selectAll}
                                        >
                                            Select All
                                        </li>
                                        {filteredSamples.map((sample, index) => (
                                            <li
                                                key={index}
                                                className="cursor-pointer p-3 hover:bg-gray-100 transition-colors"
                                                onClick={() => selectSample(sample)}
                                            >
                                                {sample}
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                {/* Selected Samples */}
                                <div className="flex flex-col w-1/2">
                                    <label className="block text-gray-700 font-medium mb-2">Selected Samples</label>
                                    <ul className="flex-grow max-h-72 overflow-auto border rounded-lg shadow-sm divide-y divide-gray-200 transition-colors">
                                        <li
                                            className="cursor-pointer p-3 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
                                            onClick={removeAll}
                                        >
                                            Remove All
                                        </li>
                                        {selectedSamples.map((sample, index) => (
                                            <li
                                                key={index}
                                                className="cursor-pointer p-3 hover:bg-gray-100 transition-colors"
                                                onClick={() => removeSample(sample)}
                                            >
                                                {sample}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>


                        </div>
                    )}


                    { showSequences && (
                        // <SequenceViewer
                        //     sequences={sequenceList}
                        // />
                        <div className="sequence-viewer">
                            {sequenceList.map((seqObj, seqIndex) => (
                                <div key={seqIndex} className="sequence-entry">
                                    <h3 className="sequence-title">{seqObj.loc}
                                    <span><small> - {(seqObj.sequence.length * 2 / 1024).toFixed(2)} KB</small></span>
                                    </h3>
                                    <div className='space-x-2'>
                                        <button
                                            onClick={() => copyToClipboard(seqObj.sequence)}
                                            className="copy-button bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600 transition duration-300"
                                        >
                                            Copy to Clipboard
                                        </button>
                                        <button
                                            onClick={() => downloadSequence(seqObj.sequence)}
                                            className="copy-button bg-gray-500 text-white px-2 py-1 rounded hover:bg-blue-600 transition duration-300"
                                        >
                                            Download Sequence
                                        </button>
                                    </div>
                                    <div className="sequence-content" style={{maxHeight:"500px", overflow:"auto"}}>
                                        {seqObj.sequence.match(new RegExp(`.{1,${charsPerLine}}`, 'g')).map((line, lineIndex) => (
                                            <div key={lineIndex} className="sequence-line">
                                                <span className="sequence-number">{lineIndex * charsPerLine + 1}</span>
                                                <span className="sequence-text">{line}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    <div className="flex justify-between mt-4 space-x-4">
                        <button onClick={onGoBack} className="bg-gray-400 hover:bg-gray-500 text-white py-2 px-4 rounded">
                            <i className="fas fa-hand-point-left mr-2"></i> Go Back
                        </button>
                        <div className="flex space-x-4">
                            <button
                                className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded shadow transition duration-300 ease-in-out"
                                onClick={handleGetSequences}
                            >
                                Get Sequences
                            </button>
                            <button
                                className="bg-gray-600 hover:bg-gray-700 text-white py-2 px-4 rounded shadow transition duration-300 ease-in-out"
                                onClick={closeModal}
                            >
                                Close
                            </button>
                        </div>
                    </div>


                </div>
            </div>
        </>
    );
};

export default React.memo(SampleSequenceModal);


function downloadFile( data, filename ) {
    if (!data) {
        console.error('No data');
        return;
    }
    
    if (!filename) {
        filename = 'download.json';
    }
    
    if (typeof data === 'object') {
        data = JSON.stringify(data, undefined, 2);
    }
    
    var blob = new Blob([data], {type: 'text/json'});
    
    // FOR IE:
    
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
    }
    else{
        var e = document.createEvent('MouseEvents'),
            a = document.createElement('a');
    
        a.download = filename;
        a.href = window.URL.createObjectURL(blob);
        a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
        e.initEvent('click', true, false, window,
            0, 0, 0, 0, 0, false, false, false, false, 0, null);
        a.dispatchEvent(e);
    }
}