import React, { useEffect, useState } from 'react';
import ViewType from '@jbrowse/core/pluggableElementTypes/ViewType'
import Plugin from '@jbrowse/core/Plugin'
import PluginManager from '@jbrowse/core/PluginManager'
import { isAbstractMenuManager } from "@jbrowse/core/util"
import InfoIcon from "@mui/icons-material/Info"

// in your code
import { createViewState, loadPlugins, JBrowseLinearGenomeView } from '@jbrowse/react-linear-genome-view'
// import { createViewState, JBrowseLinearGenomeView } from "../../src"

// I call this small class a 'locally defined' plugin
class HighlightRegionPlugin extends Plugin {
    name = "HighlightRegionPlugin"

    install(pluginManager) {
        pluginManager.addToExtensionPoint(
            "Core-extendPluggableElement",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            pluggableElement => {
                if (pluggableElement.name === "LinearGenomeView") {
                    const { stateModel } = pluggableElement
                    const newStateModel = stateModel.extend(self => {
                        const superRubberBandMenuItems = self.rubberBandMenuItems
                        return {
                            views: {
                                rubberBandMenuItems() {
                                    return [
                                        ...superRubberBandMenuItems(),
                                        // {
                                        //   label: "Console log selected region",
                                        //   onClick: () => {
                                        //     const { leftOffset, rightOffset } = self
                                        //     const selectedRegions = self.getSelectedRegions(
                                        //       leftOffset,
                                        //       rightOffset
                                        //     )
                                        //     // console log the list of potentially multiple
                                        //     // regions that were selected
                                        //     console.log(selectedRegions)
                                        //   }
                                        // }
                                    ]
                                }
                            }
                        }
                    })

                    pluggableElement.stateModel = newStateModel
                }
                return pluggableElement
            }
        )
    }

    configure() { }
}

class MyPlugin extends Plugin {
    name = 'MyPlugin'

    install(pluginManager) {
        // install MyView here
        console.log("got here: ".pluginManager)
    }

    configure(pluginManager) {
        if (isAbstractMenuManager(pluginManager.rootModel)) {
            pluginManager.rootModel.appendToMenu('Add', {
                label: 'Open My View',
                icon: InfoIcon,
                onClick: session => {
                    session.addView('MyView', {})
                },
            })
        }
    }
}

export const TestSession = () => {
    // const [ tracks, setTracks ] = useState(null);
    // const [ assembly, setAssembly ] = useState(null);
    // const [ state, setState ] = useState(null);

    const assembly = {
        name: "S_lycopersicum_chromosomes.3.00",
        sequence: {
            type: "ReferenceSequenceTrack",
            trackId: "S_lycopersicum_chromosomes.3.00-ReferenceSequenceTrack",
            adapter: {
                type: "IndexedFastaAdapter",
                fastaLocation: {
                    uri: "http://localhost:3001/files/S_lycopersicum_chromosomes.3.00.fa",
                    locationType: "UriLocation"
                },
                faiLocation: {
                    uri: "http://localhost:3001/files/S_lycopersicum_chromosomes.3.00.fa.fai",
                    locationType: "UriLocation"
                }
            }
        }
    }

    const tracks = [
        {
            type: "VariantTrack",
            trackId: "Tomato.GenomicInventory.vcf",
            name: "Tomato Genomic Inventory",
            adapter: {
                type: "VcfTabixAdapter",
                vcfGzLocation: {
                    uri: "Tomato.GenomicInventory.vcf.gz",
                    locationType: "UriLocation"
                },
                index: {
                    location: {
                        uri: "Tomato.GenomicInventory.vcf.gz.tbi",
                        locationType: "UriLocation"
                    },
                    indexType: "TBI"
                }
            },
            assemblyNames: ["S_lycopersicum_chromosomes.4.00"]
        },
        {
            type: "FeatureTrack",
            trackId: "ITAG3.2_gene_models",
            name: "ITAG3.2_gene_models.gff3",
            assemblyNames: ["S_lycopersicum_chromosomes.4.00"],
            adapter: {
                type: 'Gff3TabixAdapter',
                gffGzLocation: {
                    uri: 'http://localhost:3001/files/ITAG3.2_gene_models.gff3.gz',
                },
                index: {
                    location: {
                        uri: 'http://localhost:3001/files/ITAG3.2_gene_models.gff3.gz.tbi',
                    },
                },
            },
        }    
    ]

    const state = createViewState({
        assembly,
        plugins: [HighlightRegionPlugin, MyPlugin],
        tracks,
        configuration: {
            theme: {
                palette: {
                    primary: {
                        main: '#0099ff',
                    },
                    secondary: {
                        main: '#0f4d92',
                    },
                    tertiary: {
                        main: '#e6e600',
                    },
                    quaternary: {
                        main: '#d50000',
                    },
                    bases: {
                        A: { main: '#98FB98' },
                        C: { main: '#87CEEB' },
                        G: { main: '#DAA520' },
                        T: { main: '#DC143C' },
                    },
                },
            },
        },
        location: "SL3.0ch00:1..100,000",
        defaultSession: {
            name: 'Test Session',
        },
    })

    // state.session.view.showTrack("Darmor_v81_assembly")

    return (
        <div>
            {state && (
                <JBrowseLinearGenomeView viewState={state} />
            )}
        </div>
    )
}
