import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { login } from "../actions/auth";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const Login = (props) => {
  let navigate = useNavigate();

  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState(""); // Add state for OTP
  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector(state => state.auth);
  const { message } = useSelector(state => state.message);

  const dispatch = useDispatch();

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangeOtp = (e) => { // Add handler for OTP input
    const otp = e.target.value;
    setOtp(otp);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(login(username, password, otp)) // Pass OTP to login action
        .then(() => {
          setTimeout( ()=> {
            navigate("/jbrowse");
          },500);
          // window.location.reload();
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  if (isLoggedIn) {
    return <Navigate to="/jbrowse" />;
  }

  return (
    <div className="w-full max-w-md mx-auto">
      <div className="bg-white p-6 rounded shadow-lg">
        <img
          src="img/avatar_2x.png"
          alt="profile-img"
          className="mx-auto w-24 h-24 rounded-full"
        />

        <Form onSubmit={handleLogin} ref={form}>

          <div className="mb-4">
            <label htmlFor="username" className="block text-sm font-medium text-gray-700">Email</label>
            <Input
              type="text"
              className="mt-1 p-2 w-full border rounded-md"
              name="username"
              value={username}
              onChange={onChangeUsername}
              validations={[required]}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
            <Input
              type="password"
              className="mt-1 p-2 w-full border rounded-md"
              name="password"
              value={password}
              onChange={onChangePassword}
              validations={[required]}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="otp" className="block text-sm font-medium text-gray-700">Authy Code</label>
            <Input
              type="text"
              className="mt-1 p-2 w-full border rounded-md"
              name="otp"
              value={otp}
              onChange={onChangeOtp}
              validations={[required]}
            />
          </div>

          <div className="mb-4">
            <button className="w-full p-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:border-blue-700 focus:ring focus:ring-blue-200 flex justify-center items-center" disabled={loading}>
              {loading ? (
                <>
                  <svg className="animate-spin h-6 w-6 text-white mr-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Logging in...
                </>
              ) : (
                <span>Login</span>
              )}
            </button>
          </div>


          {message && (
            <div className="mb-4">
              <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-500">
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>

  );
};

export default Login;
