import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const NotFound = () => {
  return (
    <section className="bg-light text-dark">
      <div className="py-8 px-4 mx-auto container">
        <div className="mx-auto text-center" style={{ maxWidth: '600px' }}>
          <h1 className="mb-4 display-1 font-weight-bold text-primary">404</h1>
          <p className="mb-4 h3 font-weight-bold">Something's missing.</p>
          <p className="mb-4 lead">Sorry, we can't find that page. You'll find lots to explore on the home page.</p>
          <Link to="/" className="btn btn-primary btn-lg my-4">
            Back to Homepage
          </Link>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
