import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import UserService from "../services/user.service";

const Home = () => {
  const [content, setContent] = useState("");

  const imgSrc = require("../img/jbrowse.png");
  const style = { marginRight: "10px", height: "40px" }

  const { user: currentUser } = useSelector((state) => state.auth);
  // console.log("current user: ", currentUser );

  useEffect(() => {
    UserService.getPublicContent().then(
      (response) => {
        console.log("response: ", response.data );
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
      }
    );
  }, []);

  return (
    <div className="container mx-auto px-4">
      <div className="flex flex-col bg-gray-200 justify-center items-center p-10">
        <h1 className="flex items-center text-4xl font-bold mb-4">
          <img src={imgSrc} alt="logo" className="h-12 mr-4" />
          JBrowse 2+
        </h1>

        <p className="text-xl mb-4">
          The next-generation genome browser
        </p>
        <p className="mb-4 text-center">
          Developers at Verinomics have enhanced JBrowse2, transitioning it into a superior web-based genome browser. This next-level application now boasts a suite of custom tools, sophisticated AI analysis, and interactive chatbot features, making it the most advanced and user-friendly online genome browser available for modern web browsers.
        </p>
        {currentUser ? (
          <>
            <Link to={"/jbrowse"} className="py-2 px-4 font-semibold rounded-lg shadow-md text-white bg-gray-600 hover:bg-gray-800 mb-4">
              JBrowse Wizard
            </Link>
            <Link to={"/sessions"} className="py-2 px-4 font-semibold rounded-lg shadow-md text-white bg-gray-600 hover:bg-gray-800 mb-4">
              Saved Sessions
            </Link>
            <Link to={"/profile"} className="py-2 px-4 font-semibold rounded-lg shadow-md text-white bg-gray-600 hover:bg-gray-800 mb-4">
              Profile
            </Link>
          </>
        ) : (
          <Link to={"/login"} className="py-2 px-4 font-semibold rounded-lg shadow-md text-white bg-gray-600 hover:bg-gray-800 mb-4">
            Login
          </Link>
        )}
        
        <h3 className="p-3">{content}</h3>
      </div>
    </div>
  );
};

export default Home;
