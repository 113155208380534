import axios from "axios";
import authHeader from "./auth-header";

import URL from '../common/GetUrl';
var url = URL();
const API_URL = url;

const getPublicContent = () => {
  return axios.get(API_URL + "api/test/all", { withCredentials: true });
};

const getUserBoard = () => {
  return axios.get(API_URL + "api/test/user", { headers: authHeader(), withCredentials: true  });
};

const getModeratorBoard = () => {
  return axios.get(API_URL + "api/test/mod", { headers: authHeader(), withCredentials: true  });
};

const getAdminBoard = () => {
  return axios.get(API_URL + "api/test/admin", { headers: authHeader(), withCredentials: true  });
};

export default {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
};