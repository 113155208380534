import URL from '../common/GetUrl';
const url = URL();
const axios = require('axios');

async function checkFileExists(url) {
    try {
        await axios.head(url);
        return true;
    } catch (error) {
        console.log(`Error status: ${error.response.status}`);
        return false;
    }
}

export default function createTracks(assembly, files) {
    return files.map(file => {
        const extension = file.split('.').pop();
        const fileBaseName = file.substring(0, file.lastIndexOf('.'));
        const assemblyName = assembly.substring(0, assembly.lastIndexOf('.'));

        if (extension === 'vcf') {
            return {
                type: "VariantTrack",
                trackId: fileBaseName,
                name: fileBaseName,
                assemblyNames: [ assemblyName.toString() ],
                adapter: {
                    type: "VcfTabixAdapter",
                    vcfGzLocation: {
                        uri: `${url}${fileBaseName}.vcf.gz`,
                        locationType: "UriLocation"
                    },
                    index: {
                        location: {
                            uri: `${url}${fileBaseName}.vcf.gz.tbi`,
                            locationType: "UriLocation"
                        },
                        indexType: "TBI"
                    }
                }
            };
        } else if (extension === 'gff' || extension === 'gff3' || extension === 'ann2' || extension === 'cov' || extension === 'mq' ) {
            
                var check = checkFileExists( `${fileBaseName}.${extension}.gz.ix` );
                console.log("check: ", check );

                if( check ) {
                    // Found Search Files
                    console.warn("found file!", `${fileBaseName}.${extension}.gz.ix` )
                    return {
                        type: "FeatureTrack",
                        trackId: fileBaseName,
                        name: `${fileBaseName}.${extension}`,
                        assemblyNames: [ assemblyName.toString() ],
                        adapter: {
                            type: 'Gff3TabixAdapter',
                            gffGzLocation: {
                                uri: `${url}${fileBaseName}.${extension}.gz`,
                            },
                            index: {
                                location: {
                                    uri: `${url}${fileBaseName}.${extension}.gz.tbi`,
                                },
                            },
                        },
                        textSearching: {
                            textSearchAdapter: {
                                type: 'TrixTextSearchAdapter',
                                textSearchAdapterId: `${fileBaseName}-index`,
                                ixFilePath: {
                                    uri: `${url}${fileBaseName}.${extension}.gz.ix`,
                                    locationType: 'UriLocation'
                                },
                                ixxFilePath: {
                                    uri: `${url}${fileBaseName}.${extension}.gz.ixx`,
                                    locationType: 'UriLocation'
                                },
                                metaFilePath: {
                                    uri: `${url}${fileBaseName}.${extension}.gz_meta.json`,
                                    locationType: 'UriLocation'
                                },
                                // assemblyNames: [
                                //     assemblyName
                                // ]
                            }
                        }
                    };
                } else {
                    console.log("no text search for: ", fileBaseName );
                    // No Search Files
                    return {
                        type: "FeatureTrack",
                        trackId: fileBaseName,
                        name: `${fileBaseName}.${extension}`,
                        assemblyNames: [ assemblyName.toString() ],
                        adapter: {
                            type: 'Gff3TabixAdapter',
                            gffGzLocation: {
                                uri: `${url}${fileBaseName}.${extension}.gz`,
                            },
                            index: {
                                location: {
                                    uri: `${url}${fileBaseName}.${extension}.gz.tbi`,
                                },
                            },
                        },
                    };
                }
            
            
        } else if (extension === 'bam') {
            return {
                type: "AlignmentsTrack",
                trackId: fileBaseName,
                name: fileBaseName,
                assemblyNames: [assemblyName],
                adapter: {
                    type: "BamAdapter",
                    bamLocation: {
                        uri: `${url}${fileBaseName}.bam`,
                        locationType: "UriLocation"
                    },
                    index: {
                        location: {
                            uri: `${url}${fileBaseName}.bai`,
                            locationType: "UriLocation"
                        },
                        indexType: "BAI"
                    }
                }
            }
        } else if (extension === 'fa' || extension === 'fasta') {
            return {
                type: "SyntenyTrack",
                trackId: `${fileBaseName}`,
                assemblyNames: [ assemblyName, fileBaseName ],
                name: "dotplot",
                adapter: {
                    type: "PAFAdapter",
                    pafLocation: {
                        uri: `http://localhost:3001/${fileBaseName}.paf`
                    },
                    assemblyNames: [ assemblyName, fileBaseName ]
                }
            }
        }
    });
}