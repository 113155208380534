import React, { useState, useEffect } from "react";
import JBrowseService from "../services/jbrowse.service";
import InsightsIcon from '@mui/icons-material/Insights';

// Socket.io
import socketIOClient from 'socket.io-client';
import socketUrl from '../common/GetSocket';
const ENDPOINT = socketUrl();
// console.log("endpoint: ", ENDPOINT );

const FeatureTableModal = (props) => {
    const { gffs,
        featureModalIsOpen,
        setFeatureModalIsOpen,
        setAccessVariantIsOpen,
        navigateToLocation,
        location,
        setLoading,
        currentUser,
        selectedCrop,
        aiLoading,
        setAiLoading,
    } = props
    const [featureForm, setFeatureForm] = useState({
        gff: "",
        Term: "",
        Gene: ""
    });
    const [opacity, setOpacity] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [featureTableData, setFeatureTableData] = useState([]);
    const [resultIsOpen, setResultIsOpen] = useState(false);
    const [isParagraphVisible, setIsParagraphVisible] = useState(false);
    const [featureParagraph, setFeatureParagraph] = useState("");
    const [ gffWarning, setGffWarning ] = useState( false );

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;  // or however many you want
    const totalPages = Math.ceil(featureTableData.length / itemsPerPage);
    const displayedItems = featureTableData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handleChange = (e) => {
        setFeatureForm(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    };

    const onBuildFeatureTable = () => {
        setLoading(true);
        setAiLoading(true);
        // Send Request
        featureForm.positon = location;
        featureForm.crop = selectedCrop;
        featureForm.user = currentUser.email;
        featureForm.company = currentUser.DirectoryName;
        console.log("form: ", featureForm);
        // return;

        if( featureForm.gff === "") {
            setLoading(false);
            setAiLoading(false);
            setGffWarning( true );
            setTimeout( () => {
                setGffWarning( false );
            }, 3000);
        } else {
            // Send Requset
            JBrowseService.buildFeatureTable(featureForm).then(
                (response) => {
                    console.log("build response: ", response);
                    setLoading(false)
                    if (response.status === 0) {
                        setSuccessMessage(response.message);
                        setTimeout(() => { setSuccessMessage('') }, 3000);
                        setFeatureTableData(response.result);
                        setResultIsOpen(true);
    
                    } else if (response.status === 2) {
                        // Error
                        setErrorMessage(response.message);
                        setTimeout(() => {
                            setErrorMessage('');
                        }, 3000);
                    } else {
                        // Error
                        setErrorMessage(response.message);
                        setTimeout(() => {
                            setErrorMessage('');
                        }, 3000);
                    }
                },
                (error) => {
                    setLoading(false)
                    console.log("error: ", error);
                    setErrorMessage(error.message);
                    setTimeout(() => {
                        setErrorMessage('');
                    }, 3000);
                }
            )
        }
    }

    const onGoBackFeature = () => {
        setFeatureModalIsOpen(false);
        setTimeout(() => {
            setAccessVariantIsOpen(true)
        }, 100);
    }

    const closeModal = () => {
        setFeatureModalIsOpen(false);
    };

    const onGoBackResultsFeature = () => {
        setResultIsOpen(false);
        setFeatureTableData([]);
    }

    const jumpToLocation = (location) => {
        console.log("location: ", location);
        setLoading(true);
        navigateToLocation(location);
        setTimeout(() => {
            setFeatureModalIsOpen(false);
            setLoading(false);
        }, 1000);
    }

    useEffect(() => {
        if (featureModalIsOpen) {
            setOpacity(100);
        } else {
            setOpacity(0);
        }
    }, [featureModalIsOpen]);

    useEffect(() => {
        const socket = socketIOClient( ENDPOINT );

        socket.on("featureTable", response => {
            console.log("socket.io results: ", response);
            if (currentUser.email === response.user) {
                console.warn("success!");
                setAiLoading(false);
                setFeatureParagraph(response.openAi);
            }
        });

        // Clean up the connection when component unmounts
        return () => {
            socket.disconnect();
        };
    }, []);

    return (
        <>
            {!resultIsOpen && (
                <div
                    className={`fixed top-0 left-0 w-full h-full z-10 overflow-y-auto flex items-center justify-center transition-opacity duration-500 ease-in-out ${opacity === 100 ? 'opacity-100' : 'opacity-0'}`}
                    style={{ zIndex: "1000" }}
                >
                    <div className="bg-black bg-opacity-40 absolute inset-0"></div>
                    <div className="bg-white rounded-lg shadow-lg w-4/5 max-h-[90vh] overflow-y-auto z-20 relative">
                        <div className="flex justify-between items-center p-4">
                            <h5 className="text-lg font-bold">Feature Table</h5>
                            <button className="text-gray-400 hover:text-gray-600" onClick={closeModal}>&times;</button>
                        </div>
                        {/* Error/Warning messages */}
                        <div className="row">
                            <div className="col-md-12 m-2">
                                {successMessage && (
                                    <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                                        <strong className="font-bold">Success!</strong>
                                        <span className="block sm:inline"> Successful Retrieval</span>
                                        <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                            <svg className="fill-current h-6 w-6 text-green-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"></path></svg>
                                        </span>
                                    </div>
                                )}

                                {errorMessage && (
                                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                        <strong className="font-bold">Error!</strong>
                                        <span className="block sm:inline"> {errorMessage}</span>
                                        <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                            <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"></path></svg>
                                        </span>
                                    </div>
                                )}

                                { gffWarning && (
                                    <div className="bg-orange-100 border border-orange-400 text-orange-700 px-4 py-3 rounded relative" role="alert">
                                        <strong className="font-bold">Warning!</strong>
                                        <span className="block sm:inline"> GFF Warning, Select a GFF and try again.</span>
                                        <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                            <svg className="fill-current h-6 w-6 text-orange-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"></path></svg>
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="p-4">
                            <form>
                                <div className="mb-4">
                                    <label className="block mb-2">Select a GFF</label>
                                    <select value={featureForm.gff} onChange={handleChange} name="gff" className="border p-2 w-full rounded">
                                        <option value="" disabled>Choose your option</option>
                                        {gffs.map((x) => {
                                            const fileName = x.fileName.split('/').pop();
                                            return (
                                                <option key={x.fileName} value={x.fileName}>{fileName}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="mb-4">
                                    <label className="block mb-2" htmlFor="Term">Search Term</label>
                                    <input type="text" className="border p-2 w-full rounded" id="Term" name="Term" value={featureForm.Term} onChange={handleChange} />
                                    <small>* Optional</small>
                                </div>
                                <div className="mb-4">
                                    <label className="block mb-2" htmlFor="Gene">Search Gene</label>
                                    <input type="text" className="border p-2 w-full rounded" id="Gene" name="Gene" value={featureForm.Gene} onChange={handleChange} />
                                    <small>* Optional</small>
                                </div>
                            </form>

                            {/* {alertType === 'error' && (
                                <div className="alert bg-red-500 text-center text-white mb-4 p-2 rounded">
                                    <strong className="mr-2"><i className="fas fa-exclamation-triangle"></i></strong> Server Error: There was a memory issue on our end, contact a Verinomics engineer.
                                </div>
                            )}
                            {alertType === 'building' && (
                                <div className="alert bg-blue-500 text-center text-white mb-4 p-2 rounded">
                                    <strong className="mr-2"><i className="fas fa-exclamation-circle"></i></strong> Building Table: This may take a couple minutes, please be patient.
                                </div>
                            )}
                            {alertType === 'gffError' && (
                                <div className="alert bg-gray-500 text-center text-white mb-4 p-2 rounded">
                                    <strong className="mr-2"><i className="fas fa-exclamation-triangle"></i></strong> User Error: You need to select a gff file, try again.
                                </div>
                            )} */}
                            <div className="flex justify-between mt-4">
                                <button onClick={onGoBackFeature} className="bg-gray-400 hover:bg-gray-500 text-white py-2 px-4 rounded">
                                    <i className="fas fa-hand-point-left mr-2"></i> Go Back
                                </button>
                                <div>
                                    <button onClick={onBuildFeatureTable} className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded mr-2">
                                        <i className="fas fa-sync mr-2"></i> Build
                                    </button>
                                    <button className="bg-gray-600 hover:bg-gray-700 text-white py-2 px-4 rounded" onClick={closeModal}>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {resultIsOpen && (
                <>
                    <div className={`fixed z-10 inset-0 overflow-y-auto flex justify-center`} style={{ zIndex: "1000" }}>
                        <div className="bg-black bg-opacity-50 absolute inset-0"></div>
                        <div className="bg-white p-4 rounded-lg w-4/5 max-h-screen mt-12 mb-12 flex flex-col relative z-20">
                            <div className="flex justify-between items-center mb-4">
                                <h5 className="text-lg font-bold">
                                    Feature Table
                                    <small className="mr-2"> - {featureTableData.length.toLocaleString()} hits, 10 per page</small>
                                </h5>

                                <div className="flex items-center">
                                    <button className="bg-blue-500 hover:bg-blue-700 mb-1 mr-3 text-white font-bold py-2 px-3 rounded w-full" onClick={() => setIsParagraphVisible(!isParagraphVisible)}>
                                        {aiLoading ? (
                                            <div className="flex items-center">
                                                <svg className="animate-spin h-6 w-6 text-white mr-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                                <span>Waiting for AI Server...</span>
                                            </div>
                                        ) : (
                                            <>
                                                <InsightsIcon className='mr-2' />
                                                {isParagraphVisible ? 'Hide' : 'Show'} AI Analysis
                                            </>
                                        )}

                                    </button>
                                    <button className="text-gray-400 hover:text-gray-600" onClick={closeModal}>&times;</button>
                                </div>
                            </div>
                            <div className="overflow-y-auto flex-grow">

                                {isParagraphVisible && (
                                    <div className="mt-4 mb-4">
                                        {featureParagraph === '' ? (
                                            <div className="bg-yellow-400 border-l-4 border-yellow-700 text-yellow-900 p-4 rounded-lg shadow-md">
                                                <strong className="block">Waiting for Server Response</strong>
                                                We're currently waiting for a response from the AI server. This should take less than a minute, please hang tight!
                                            </div>
                                        ) : (
                                            <p className="mt-4 mb-4" style={{ whiteSpace: 'pre-line' }}>
                                            {featureParagraph}
                                            </p>
                                        )}
                                    </div>
                                )}

                                <div className="overflow-y-auto" style={{ maxHeight: "600px" }}>
                                    <table className="min-w-full table-auto">
                                        <thead className="justify-between">
                                            <tr className="bg-gray-800">
                                                <th className="py-2 px-4 text-gray-200">Jump</th>
                                                <th className="py-2 px-4 text-gray-200">Contig</th>
                                                <th className="py-2 px-4 text-gray-200">Start</th>
                                                <th className="py-2 px-4 text-gray-200">End</th>
                                                <th className="py-2 px-4 text-gray-200">ID</th>
                                                <th className="py-2 px-4 text-gray-200">Note</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {displayedItems.map((item, index) => (
                                                <tr key={index} className="bg-gray-100">
                                                    <td className="py-2 px-4">
                                                        <button onClick={() => { jumpToLocation(item.Contig + ":" + item.Start + "-" + item.End) }} className="bg-gray-400 hover:bg-gray-500 text-white py-2 px-4 rounded">
                                                            <i className="fas fa-hand-point-right mr-2"></i> Jump
                                                        </button>
                                                    </td>
                                                    <td className="py-2 px-4">{item.Contig}</td>
                                                    <td className="py-2 px-4">{item.Start}</td>
                                                    <td className="py-2 px-4">{item.End}</td>
                                                    <td className="py-2 px-4">{item.ID}</td>
                                                    <td className="py-2 px-4"><small>{item.Note}</small></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                            <div className="flex justify-between mt-4 items-center">
                                <button
                                    onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)}
                                    className="bg-gray-400 hover:bg-gray-500 text-white py-2 px-4 rounded"
                                    disabled={currentPage === 1}
                                >
                                    Prev
                                </button>
                                <span>Page {currentPage} of {totalPages}</span>
                                <button
                                    onClick={() => setCurrentPage(currentPage < totalPages ? currentPage + 1 : totalPages)}
                                    className="bg-gray-400 hover:bg-gray-500 text-white py-2 px-4 rounded"
                                    disabled={currentPage === totalPages}
                                >
                                    Next
                                </button>
                            </div>

                            <div className="flex justify-between mt-4">
                                <button onClick={onGoBackResultsFeature} className="bg-gray-400 hover:bg-gray-500 text-white py-2 px-4 rounded">
                                    <i className="fas fa-hand-point-left mr-2"></i> Go Back
                                </button>
                                <button className="bg-gray-600 hover:bg-gray-700 text-white py-2 px-4 rounded" onClick={closeModal}>
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>

                </>
            )}
        </>
    );
};

export default FeatureTableModal;
