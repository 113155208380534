import React, { useState } from "react";
import { Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import JBrowseService from "../services/jbrowse.service";

const Profile = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  console.log("current user: ", currentUser );
  const [ loading, setLoading ] = useState( false );
  const [ notification, setNotification ] = useState(null);

  // User details state
  const profile_keys = [
    "email",
    "company"  
  ];

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  if (!currentUser) {
    return <Navigate to="/" />;
  }

  const handlePasswordChange = () => {
    // Logic to handle password change
    setLoading(true);
    console.log("Password changed to:", newPassword);

    // Check if current password or new password is empty
    if (!currentPassword.trim() || !newPassword.trim()) {
      alert("Both current and new passwords are required.");
      setLoading(false);
      return;
    }

    // Check if the new password is the same as the current password
    if (currentPassword === newPassword) {
      alert("New password cannot be the same as the current password.");
      setLoading(false);
      return;
    }

    // Additional password strength checks can be added here
    // Example: Check if the new password length is at least 8 characters
    if (newPassword.length < 8) {
      alert("New password must be at least 8 characters long.");
      setLoading(false);
      return;
    }

    var obj = {
      user: currentUser,
      oldPassword: currentPassword,
      newPassword: newPassword
    };

    console.log("obj: ", obj );
    // return;
    // Send Request
    JBrowseService.changePassword( obj ).then(
      ( resposne ) => {
        console.log("password response: ", resposne );
        setNotification('Password changed successfully');
        setLoading(false);
        setTimeout(() => {
          setNotification('');
        },3000);

      },
      ( error ) => {
        console.log('password error: ', error );
        setNotification('Something went wrong, try again.');
        setLoading(false);
        setTimeout(() => {
          setNotification('');
        },3000);
      }
    )


  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md max-w-3xl mx-auto">
      
      {loading && (
        <div className='loading'></div>
      )}

      {/* User Header */}
      <div className="flex items-center mb-8">
        <img src={currentUser.gravatar} alt="User Gravatar" className="w-24 h-24 rounded-full border-2 border-indigo-500 mr-4"/>
        <h2 className="text-2xl font-semibold text-gray-700">{currentUser.username}'s Profile</h2>
      </div>
            
      {/* User Information */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        {profile_keys.map((value, index) => (
          <div key={index} className="text-gray-600">
            <strong className="block text-xs font-medium mb-1 text-indigo-600">{value.replace(/_/g, ' ')}</strong>
            <span className="text-xs">{currentUser[value]}</span>
          </div>
        ))}
      </div>


      {/* Change Password Section */}
      <div className="mt-8 border-t pt-6">
        <h4 className="text-lg font-semibold text-gray-700 mb-4">Change Password</h4>
        
        {/* Current Password */}
        <div className="mb-4">
          <label htmlFor="current-password" className="block text-base font-medium text-gray-700">
            Current Password
          </label>
          <input 
            type="password" 
            name="current-password" 
            id="current-password"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full p-3 rounded-md border border-gray-300"
            placeholder="Enter current password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </div>

        {/* New Password */}
        <div className="mb-4">

          <label htmlFor="new-password" className="block text-base font-medium text-gray-700">
            New Password
          </label>
          <input
            type="password"
            name="new-password"
            id="new-password"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full p-3 rounded-md border border-gray-300"
            placeholder="Enter new password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            />
        </div>

        <button
          onClick={handlePasswordChange}
          className="px-6 py-2 bg-indigo-600 text-white rounded-full hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
          >
          Update Password
        </button>

        {notification && (
          <div className="alert bg-gray-500 text-center text-white mb-4 p-2 rounded">
            <strong className="mr-2"><i className="fas fa-exclamation-triangle"></i></strong> {notification}
          </div>
          
        )}


      </div>
    </div>
  );
};

export default Profile;