import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
} from "./types";

import AuthService from "../services/auth.service";
import JBrowseService from "../services/jbrowse.service";

export const register = (username, email, password, authyId, company) => (dispatch) => {
  return AuthService.register(username, email, password, authyId, company).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const login = (username, password, otp) => (dispatch) => {
  return AuthService.login(username, password, otp).then(
    (data) => {
      // console.log("data: ", data );
      /** Get Company & User data here for the current user */
      JBrowseService.getUser( data.email ).then(
        ( response ) => {
          // console.log("found user data: ", response );

          const combinedObj = { ...data, ...response[0] };
          // console.log("login token: ", combinedObj );

          // Local Storage
          // localStorage.setItem('token', combinedObj.accessToken);

          dispatch({
            type: LOGIN_SUCCESS,
            payload: { user: combinedObj },
          });
    
          return Promise.resolve();

        },
        ( error ) => {
          console.log("database error fetching user.");

          dispatch({
            type: LOGIN_FAIL,
          });
    
          dispatch({
            type: SET_MESSAGE,
            payload: "Database error while fetching user information.",
          });

          return Promise.reject();

        }
      )

    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};
