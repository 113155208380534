import React, { useState, useEffect } from 'react';
import JBrowseService from '../services/jbrowse.service';
import { Widget, addResponseMessage, toggleWidget } from 'react-chat-widget';
import _ from 'underscore';

const LiteratureModal = (props) => {
    // console.log("props: ", props );
    const { literatureSearch,
        setLiteratureSearch,
        literatureModalIsOpen,
        setLiteratureModalIsOpen,
        setLoading,
        currentUser,
        literaturePrompt,
        setLiteraturePrompt,
        selectedCrop,
        conversation,
        isWidgetOpen,
        setLiteratureSearchError,
        setShowToolsModal,
    } = props;

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeModal();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const [filter, setFilter] = useState('');
    const [filteredResults, setFilteredResults] = useState(literatureSearch);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeModal();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        const updatedResults = literatureSearch.map(item => ({
            ...item,
            title: item.title.replace(/\[(HTML|PDF)\]/g, '') // Removes all instances of [HTML] and [PDF]
        }));

        setFilteredResults(updatedResults.filter(item =>
            item.title.toLowerCase().includes(filter.toLowerCase())
        ));
    }, [filter, literatureSearch]);

    const closeModal = () => {
        setLiteratureModalIsOpen(false);
    };

    const handleGoBack = () => {
        setLiteratureModalIsOpen(false);
        setShowToolsModal( true );
    }

    const handleNewSearch = () => {
        console.log("literaturePrompt: ", literaturePrompt );
        setLoading(true);
        let obj = {
            user: currentUser.email,
            prompt: literaturePrompt,
            crop: selectedCrop
        };
        console.log("obj: ", obj );
        // Send Request
        JBrowseService.scholarSearch( obj ).then(
            ( response ) => {
                console.log("scholor response: ", response );
                setLiteratureSearch( response.result );
                setLoading(false);
            }, 
            ( error ) => {
                console.log("google error: ", error );
                setLoading(false);
            }
        )

    };

    const handleAiSearch = () => {
        setLoading(true);
        let message = findLastAssistantContent(conversation);
        let obj = {
            message: message,
            user: currentUser.email,
            crop: selectedCrop
        };
        console.log("obj: ", obj);
        if (obj.message === null) {
            console.warn("this wont work..");
            setLoading(false);
            return;
        }
        // Send Request 
        JBrowseService.literatureSearch(obj).then(
            (response) => {
                console.log("literature serach response: ", response);
                if (response.result.articles.length === 0) {
                    setLiteraturePrompt(response.result.prompt);
                    setLiteratureSearchError(true);
                    setTimeout(() => {
                        setLiteratureSearchError(false);
                    }, 5000);
                    setLoading(false);
                } else {
                    setLiteraturePrompt(response.result.prompt);
                    setLiteratureSearch(response.result.articles);
                    setLoading(false);
                }
            },
            (error) => {
                console.log("error: ", error);
                setLiteratureSearchError(true);
                setTimeout(() => {
                    setLiteratureSearchError(false);
                }, 3000);
                setLoading(false);
            }
        )
    }

    return (
        <div className={`fixed z-10 inset-0 overflow-y-auto flex justify-center`} style={{ zIndex: "1000" }}>
            <div className="modal-dialog max-w-6xl w-full mx-2">
                <div className="modal-content bg-white rounded-lg shadow-lg overflow-hidden">
                    <div className="modal-header p-4 flex justify-between items-center">
                        <h5 className="text-lg font-bold">Literature Search</h5>
                        <button className="text-gray-400 hover:text-gray-600" onClick={closeModal}>&times;</button>
                    </div>
                    <div className="modal-body p-4">
                        {/* New Text Field and Button for literaturePrompt */}
                        <div className="mt-4 flex items-end space-x-2">
                            <textarea
                                className="flex-grow border-2 border-gray-300 bg-white py-2 px-5 rounded-lg text-sm focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                style={{ height: '3rem', fontFamily: 'Arial' }} // Add fontFamily in style
                                value={literaturePrompt}
                                onChange={(e) => setLiteraturePrompt(e.target.value)}
                                placeholder="Enter new literature search prompt"
                                rows="7"
                            />
                            <button
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                onClick={handleNewSearch}
                            >
                                Search
                            </button>
                            { conversation.length != 0 && (
                                <button
                                    className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded"
                                    onClick={handleAiSearch}
                                >
                                    Search AI Conversation
                                </button>
                            )}
                        </div>

                        {
                            filteredResults.length > 0 ? (
                                <>
                                    <input
                                        type="text"
                                        className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
                                        value={filter}
                                        onChange={(e) => setFilter(e.target.value)}
                                        placeholder="Search Papers..."
                                    />
                                    <ul className="list-disc pl-5 mt-2">
                                        {filteredResults.map((item, index) => (
                                            <li key={index} className="mt-4 first:mt-0">
                                                <a href={item.title_link} target='_blank' rel="noopener noreferrer" className="text-blue-500 hover:text-blue-600 font-semibold">
                                                    {item.title}
                                                </a>
                                                <p className="text-gray-600 mt-1">{item.publication_info}</p>
                                                <small className="text-gray-500">{item.snippet}</small>
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            ) : (
                                <div className="text-gray-600 mt-2">
                                    No results were found for this search.
                                </div>
                            )
                        }


                    </div>
                    <div className="modal-footer p-4 flex justify-between items-center">
                        <button className="bg-gray-400 hover:bg-gray-500 text-white py-2 px-4 rounded" onClick={handleGoBack}>
                            Go Back
                        </button>
                        <button className="bg-gray-400 hover:bg-gray-500 text-white py-2 px-4 rounded" onClick={closeModal}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LiteratureModal;


function findLastAssistantContent(array) {
    for (let i = array.length - 1; i >= 0; i--) {
        if (array[i].role === "assistant") {
            return array[i].content;
        }
    }
    return null; // Return null or any other value if not found
}
