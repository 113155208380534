import React, { useEffect, useState } from 'react';
import JBrowseService from '../services/jbrowse.service';
import EventBus from '../common/EventBus';
import { toast } from 'react-toastify';

const SaveSessionModal = (props) => {
    const { saveModalIsOpen,
            setSaveModalIsOpen,
            currentSession,
            setCurrentSession,
            setLoading,
            currentUser,
            currentAssembly,
            currentTracks,
            location,
            setSaveAlert,
            selectedCrop,
         } = props;
    const [name, setName] = useState('');

    useEffect( ()=> {
        if( currentSession ) {
            setName( currentSession.name );
        }
    }, [currentSession]);

    const handleSaveSession = () => {
        setLoading( true );
        // let newSession = {...currentSession}
        // newSession.name = name;
        // const filteredSessionTracks = newSession.view.tracks.filter(item => !item.configuration.includes("BlastJob"));
        // newSession.view.tracks = filteredSessionTracks;
        let newSession = { ...currentSession };
        newSession.name = name;
        
        // Create a copy of the view object and then update its tracks property
        let newView = { ...newSession.view };
        newView.tracks = newView.tracks.filter(item => !item.configuration.includes("BlastJob"));
        
        // Update the view property of the newSession
        newSession.view = newView;
        // console.log("newSession: ", newSession );
        
        // Now you can safely set the state with newSession
        
        // Filter out Blast Tracks
        const filteredTracks = currentTracks.filter(item => !item.name.includes("BlastJob"));

        setCurrentSession( newSession );
        let session = {
            user: currentUser,
            assembly: currentAssembly,
            tracks: filteredTracks,
            session: newSession,
            location: location,
            crop: selectedCrop,
        };
        console.log("session: ", session );
        // return;
        // Send Request
        JBrowseService.saveSession(session).then(
            (response) => {
                console.log("response: ", response);
                setTimeout( ()=> {
                    setLoading( false );
                    toast.success("Session saved successfully!");
                    // setSaveAlert({
                    //     visible: true,
                    //     type: 'success',
                    //     message: 'Session saved successfully!' // Customize this message as needed
                    // });
                    // setTimeout( ()=> {
                    //     setSaveAlert({
                    //         visible: false,
                    //         type: '', // 'success' or 'error'
                    //         message: ''
                    //     });
                    // },3000);
                },500);
            },
            (error) => {
                console.log("error: ", error);
                setLoading( false );
                toast.error("Error saving the session. Please refresh your browser and try again.")
                // setSaveAlert({
                //     visible: true,
                //     type: 'error',
                //     message: 'Error saving the session. Please try again.' // Customize this message based on the error if needed
                // });
                // setTimeout( ()=> {
                //     setSaveAlert({
                //         visible: false,
                //         type: '', // 'success' or 'error'
                //         message: ''
                //     });
                // },3000);
            }
        );
      
    };

    const toggleModal = () => {
        setSaveModalIsOpen(!saveModalIsOpen);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                setSaveModalIsOpen(false);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div className={`fixed top-0 left-0 w-full h-full flex items-center justify-center z-50`}>
            <div className="bg-white shadow-lg rounded-lg overflow-hidden shadow-xl w-4/5 md:w-2/3">
                <div className="border-b px-4 py-2 flex justify-between items-center">
                    <h5 className="font-semibold">Save Session</h5>
                    <button className="text-gray-500 hover:text-gray-600" onClick={toggleModal}>&times;</button>
                </div>

                <div className="p-4">
                    <form>
                        <div className="mb-6">
                            <label
                                className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="text"
                            >
                                Session Name:
                            </label>

                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="text"
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>

                    </form>
                    <div className="mt-4 flex justify-between items-center">
                        <div>
                            <button className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-4 rounded mr-2" onClick={handleSaveSession}>
                                <i className="fas fa-save mr-1"></i> Save Session
                            </button>
                            <button className="bg-gray-300 hover:bg-gray-400 text-black py-1 px-4 rounded" onClick={toggleModal}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SaveSessionModal;
