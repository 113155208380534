import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import { register } from "../actions/auth";

import JBrowseService from "../services/jbrowse.service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vusername = (value) => {
  if (value.length < 3 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

const validAuthyId = (value) => {
  // Check if the value is a number and between 6 and 10 digits long
  if (!/^\d{6,10}$/.test(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Authy ID must be a number between 6 and 10 digits.
      </div>
    );
  }
};


const Register = () => {
  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [authyId, setAuthyId] = useState(""); // Add state for Authy ID
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({});

  const { message } = useSelector(state => state.message);
  const dispatch = useDispatch();

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangeAuthyId = (e) => { // Add handler for Authy ID input
    const authyId = e.target.value;
    setAuthyId(authyId);
  };

  const handleRegister = (e) => {
    e.preventDefault();

    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(register(username, email, password, authyId, selectedCompany)) // Pass Authy ID to register action
        .then(() => {
          setSuccessful(true);
        })
        .catch(() => {
          setSuccessful(false);
        });
    }
  };

  const handleCompanyChange = (event) => {
    console.log("event: ", event.target.value);
    setSelectedCompany(event.target.value);
  }

  useEffect(() => {
    // Get Companies
    JBrowseService.getCompanies().then(
      (response) => {
        console.log("company response: ", response);
        setCompanies(response);
      },
      (error) => {
        console.log("company error: ", error);
      }
    )
  }, []);

  return (
    <div className="w-full max-w-md mx-auto">
      <div className="bg-white p-6 rounded shadow-lg">
        <img
          src="img/avatar_2x.png"
          alt="profile-img"
          className="mx-auto w-24 h-24 rounded-full"
        />

        <Form onSubmit={handleRegister} ref={form}>
          {!successful && (
            <div className="w-full space-y-4">
              <div>
                <label htmlFor="username" className="block text-sm font-bold mb-1">Username</label>
                <Input
                  type="text"
                  className="mt-1 p-2 w-full border rounded-md"
                  name="username"
                  value={username}
                  onChange={onChangeUsername}
                  validations={[required, vusername]}
                />
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-bold mb-1">Email</label>
                <Input
                  type="text"
                  className="mt-1 p-2 w-full border rounded-md"
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                  validations={[required, validEmail]}
                />
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-bold mb-1">Password</label>
                <Input
                  type="password"
                  className="mt-1 p-2 w-full border rounded-md"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  validations={[required, vpassword]}
                />
              </div>

              <div>
                <label htmlFor="authy" className="block text-sm font-bold mb-1">Authy Id</label>
                <Input
                  type="text"
                  className="mt-1 p-2 w-full border rounded-md mb-2"
                  name="authy"
                  value={authyId}
                  onChange={onChangeAuthyId}
                  validations={[required, validAuthyId]}
                />
              </div>

              <div>
                <label htmlFor="company" className="block text-sm font-bold mb-1">Company</label>
                <select
                  name="company"
                  value={selectedCompany}
                  onChange={handleCompanyChange}
                  className="mt-1 p-2 w-full border rounded-md mb-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                >
                  <option value="">Select Company</option>
                  {companies.map((company, index) => (
                    <option key={index} value={company.DirectoryName}>
                      {company.Name}
                    </option>
                  ))}
                </select>
              </div>


              <div>
                <button className="w-full py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:ring focus:ring-blue-200">
                  Sign Up
                </button>
              </div>
            </div>
          )}

          {message && (
            <div className="mt-4 w-full">
              <div className={successful ? "p-4 bg-green-200 border border-green-400 text-green-700 rounded-md" : "p-4 bg-red-200 border border-red-400 text-red-700 rounded-md"} role="alert">
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>

  );
};

export default Register;
